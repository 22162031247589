import React, { FC } from 'react'
import { ReactComponent as DiscordIcon } from 'assets/icons/social/discord.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/social/twitter.svg'
import { ReactComponent as TelegramIcon } from 'assets/icons/social/telegram.svg'
import { ReactComponent as MediumIcon } from 'assets/icons/social/medium.svg'
import { ReactComponent as LinktreeIcon } from 'assets/icons/social/linktree.svg'
// import { ReactComponent as LinkedinIcon } from 'assets/icons/social/linkedin.svg'
// import { ReactComponent as WhatsappIcon } from 'assets/icons/social/whatsapp.svg'
import styled from 'styled-components'

type dataType = {
  id: number
  href: string
  component?: JSX.Element
}

type SocialListProps = {
  data?: dataType[]
  setOpen?: (value: React.SetStateAction<boolean>) => void | undefined
  isMobile?: boolean
}

export const ListItem = styled.li`
  overflow: hidden;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #000;
  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    width: 100%;
    height: 100%;
  }
  svg {
    width: 100%;
    height: 100%;
    fill: #fff;
  }
  &:hover {
    svg {
      fill: #1fc7d4;
    }
  }
`
export const ListWrapper = styled.ul`
  display: flex;
  list-style: none;
  * + ${ListItem} {
    margin-left: 10px;
  }
`
const socialList = [
  {
    id: 1,
    href: 'https://twitter.com/pltfinance',
    component: <TwitterIcon />,
  },
  {
    id: 2,
    href: 'https://discord.gg/Pjzb33XcXc',
    component: <DiscordIcon />,
  },
  {
    id: 3,
    href: 'https://t.me/pltlottochat',
    component: <TelegramIcon />,
  },
  {
    id: 4,
    href: 'https://medium.com/@poollotto',
    component: <MediumIcon />,
  },
  {
    id: 5,
    href: 'https://linktr.ee/PoollottoFinance',
    component: <LinktreeIcon />,
  },
  // {
  //   id: 6,
  //   href: 'https://www.linkedin.com/company/poollotto-finance/mycompany/',
  //   component: <LinkedinIcon />,
  // },
  // {
  //   id: 7,
  //   href: 'https://chat.whatsapp.com/DEQls4O89NTGESEdATjU34',
  //   component: <WhatsappIcon />,
  // },
]

export const SocialList: FC<SocialListProps> = ({ data = socialList, setOpen, isMobile }) => {
  const handleClick = (e: Event, href: string) => {
    e.preventDefault()
    window.open(href, '_blank')

    if (setOpen !== undefined) {
      setOpen(false)
    }
  }

  return (
    <ListWrapper>
      {data.map((item) => (
        <ListItem key={item.id}>
          {isMobile ? (
            <a href={item.href} target="_blank" rel="noreferrer" onClick={(e: any) => handleClick(e, item.href)}>
              {item?.component}
            </a>
          ) : (
            <a href={item.href} target="_blank" rel="noreferrer">
              {item?.component}
            </a>
          )}
        </ListItem>
      ))}
    </ListWrapper>
  )
}
