import { useNftBalance } from 'hooks/useTokenBalance'
import React, { createContext } from 'react'
import { NftContextApi } from './types'

export const NFTContext = createContext<NftContextApi>(undefined)

export const NFTProvider: React.FC = ({ children }) => {
  const { balance, loading, getNftBalance } = useNftBalance()

  return <NFTContext.Provider value={{ balance, loading, getNftBalance }}>{children}</NFTContext.Provider>
}
