import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LeaderboardAllTimeGraphEntity, LeaderboardState } from 'state/types'
import getLeaderboardAllTimeData from './getLeaderboardAllTimeData'

const initialState: LeaderboardState = {
  allTime: null,
}

export const fetchLeaderboardAllTime = createAsyncThunk<LeaderboardAllTimeGraphEntity[]>(
  'leaderboard/fetchLeaderboardAllTime',
  async () => {
    const leaderboardAllTime = await getLeaderboardAllTimeData()
    return leaderboardAllTime
  },
)

export const LeaderboardSlice = createSlice({
  name: 'Leaderboard',
  initialState,
  reducers: {
    setLeaderboardPublicData: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchLeaderboardAllTime.fulfilled,
      (state, action: PayloadAction<LeaderboardAllTimeGraphEntity[]>) => {
        state.allTime = action.payload
      },
    )
  },
})

// Actions
export const { setLeaderboardPublicData } = LeaderboardSlice.actions
export default LeaderboardSlice.reducer
