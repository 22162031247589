import styled from 'styled-components'
import { Card, ExpandableLabel } from '@pancakeswap/uikit'

import Balance from 'components/Balance'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-column-gap: 32px;
    grid-template-columns: auto 1fr;
  }
`

export const StyledCard = styled(Card)`
  width: 100%;
  background: none !important;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 756px;
  }
`

export const NextDrawWrapper = styled.div`
  background: #40455c;
  padding: 24px;
  border-radius: 26px;
`

export const CardHeader = styled.div`
  background: #40455c;
  border-radius: 24px 24px 0 0;
  height: 75px;
  padding: 24px 150px;
`

export const MobileCardHeader = styled.div`
  background: #40455c;
  border-radius: 24px 24px 0 0;
  height: 75px;

  padding: 24px 20px;
  display: flex;
  align-items: center;
`

export const CardBody = styled.div`
  background: #292d3f;
  border-top: 1px solid #575a6c;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 24px 20px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 24px 150px;
  }
`

export const CardFooter = styled.div`
  background: #292d3f;
  padding: 24px;
  border-top: 1px solid #575a6c;
`

export const Divider = styled.div`
  background-color: #a5accf;
  height: 1px;
  margin: 40px 0;
  width: 100%;
`
export const StyledBalance = styled(Balance)`
  align-self: end;
  margin-left: 20px;
`

export const StyledExpandableLabel = styled(ExpandableLabel)`
  background: #40455c;
  border-radius: 26px;
`
