import React from 'react'
import styled from 'styled-components'
import { Modal, Text, Flex, Button, ArrowBackIcon, AutoRenewIcon } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'
import TicketInput from './TicketInput'
import { UpdateTicketAction, Ticket } from './useTicketsReducer'

const StyledModal = styled(Modal)`
  min-width: 280px;
  max-width: 320px;
  max-height: 552px;
  border: none;
  background: #292d3f;
  & div:nth-child(2) {
    padding: 0;
  }
  .sc-biJonm,
  .sc-gIvpjk {
    color: #fff;
    h2 {
      color: currentColor;
    }
    button {
      color: currentColor;
    }
    svg {
      fill: currentColor;
    }
  }
`
const StyledBorderButton = styled(Button)`
  border-color: #b67122 !important;
  border-width: 2px !important;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  -webkit-letter-spacing: 0.03em;
  -moz-letter-spacing: 0.03em;
  -ms-letter-spacing: 0.03em;
  letter-spacing: 0.03em;
  padding: 18px 0px;
  color: white !important;
`
const StyledButton = styled(Button)`
  background-image: linear-gradient(265.56deg, #922c32 -0.27%, #922c32 -0.26%, #b67122 98.59%);
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  border-radius: 100px;
  box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  -webkit-letter-spacing: 0.03em;
  -moz-letter-spacing: 0.03em;
  -ms-letter-spacing: 0.03em;
  letter-spacing: 0.03em;
  padding: 18px 0px;
  color: white !important;
`
const ScrollableContainer = styled.div`
  height: 310px;
  overflow-y: scroll;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.cardBorder}`};
  padding: 24px;
`

const EditNumbersModal: React.FC<{
  totalCost: string
  updateTicket: UpdateTicketAction
  randomize: () => void
  tickets: Ticket[]
  allComplete: boolean
  onConfirm: () => void
  isConfirming: boolean
  withFreeTickets: boolean
  onDismiss?: () => void
}> = ({
  totalCost,
  updateTicket,
  randomize,
  tickets,
  allComplete,
  onConfirm,
  isConfirming,
  withFreeTickets,
  onDismiss,
}) => {
  const { t } = useTranslation()
  return (
    <StyledModal
      title={t('views.lottery.components.buyTicketsModal.editNumbers.title')}
      onDismiss={onDismiss}
      headerBackground="#40455c"
      onBack={onDismiss}
    >
      <ScrollableContainer>
        <Flex justifyContent="space-between" mb="16px">
          <Text color="#fff">{t('views.lottery.components.buyTicketsModal.editNumbers.totalCost')}:</Text>
          <Text color="#fff">~{withFreeTickets ? 0 : totalCost} PLT</Text>
        </Flex>
        <Text fontSize="12px" color="#fff" mb="16px">
          {t('views.lottery.components.buyTicketsModal.editNumbers.text')}
        </Text>
        <StyledBorderButton
          disabled={isConfirming}
          mb="16px"
          variant="secondary"
          width="100%"
          height="32px"
          onClick={randomize}
        >
          {t('views.lottery.components.buyTicketsModal.editNumbers.randomize')}
        </StyledBorderButton>
        {tickets.map((ticket) => (
          <TicketInput
            key={ticket.id}
            ticket={ticket}
            duplicateWith={ticket.duplicateWith}
            updateTicket={updateTicket}
            disabled={isConfirming}
          />
        ))}
      </ScrollableContainer>
      <Flex flexDirection="column" justifyContent="center" m="24px">
        <StyledButton
          id="lotteryBuyEdited"
          disabled={!allComplete || isConfirming}
          endIcon={isConfirming ? <AutoRenewIcon spin color="currentColor" /> : undefined}
          onClick={() => {
            onConfirm()
          }}
        >
          {isConfirming
            ? t('views.lottery.components.buyTicketsModal.editNumbers.confirming')
            : t('views.lottery.components.buyTicketsModal.editNumbers.confirmAndBuy')}
        </StyledButton>
        <Button
          mt="8px"
          variant={isConfirming ? 'secondary' : 'text'}
          disabled={isConfirming}
          onClick={onDismiss}
          style={{ color: '#fff' }}
        >
          <ArrowBackIcon color={isConfirming ? 'disabled' : '#fff'} height="24px" width="24px" /> {t('shared.goBack')}
        </Button>
      </Flex>
    </StyledModal>
  )
}

export default EditNumbersModal
