import styled from 'styled-components'
import { HeaderDropdownButton } from './itemContent.styled'

export const StepLabelDesc = styled.div`
  font-size: 18px;
  line-height: 26px;

  ${({ theme }) => theme.mediaQueries.xxl} {
    font-size: 24px;
    line-height: 26px;
  }
`
export const StepLabelTitle = styled.div`
  color: #a5accf;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
`
export const StepLabel = styled.div`
  display: inline-flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.xxl} {
    * + ${StepLabelDesc} {
      margin-top: 8px;
    }
  }
`

export const OpenTrigger = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.3s ease all;
  svg {
    fill: #fff;
  }
  margin-left: auto;
  ${({ theme }) => theme.mediaQueries.xxl} {
    margin-left: initial;
  }
`
export const StepStatus = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border: 1px solid #ffffff80;
  border-radius: 50%;
  svg {
    visibility: hidden;
    opacity: 0;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    width: 62px;
    height: 62px;
  }
`
export const StepBtnContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;

  ${HeaderDropdownButton} {
    margin: 8px;
  }
  ${({ theme }) => theme.mediaQueries.xxl} {
    ${HeaderDropdownButton} {
      margin: 8px 16px;
    }
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: initial;
  }
`
export const StepLabelContainer = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;

  * + ${StepLabel} {
    margin-left: 16px;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    width: initial;

    * + ${StepLabel} {
      margin-left: 32px;
    }
  }
`
export const ItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${StepStatus}.is-done {
    border-color: #fff;
    background-color: #fff;
    svg {
      visibility: visible;
      opacity: 1;
    }
  }
  ${OpenTrigger}.is-open {
    svg {
      transform: rotate(180deg);
    }
  }
  ${({ theme }) => theme.mediaQueries.xxl} {
    flex-direction: row;
  }
`
