import { useEffect, useReducer, useRef } from 'react'
import { noop } from 'lodash'

import { ethers } from 'ethers'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { useAuth } from './useAuth'

type LoadingState = 'idle' | 'loading' | 'success' | 'fail'

type Action =
  | { type: 'requires_approval' }
  | { type: 'requires_nft_approval' }
  | { type: 'approve_sending' }
  | { type: 'approve_nft_sending' }
  | { type: 'approve_receipt' }
  | { type: 'approve_nft_receipt' }
  | { type: 'approve_error' }
  | { type: 'approve_nft_error' }
  | { type: 'confirm_sending' }
  | { type: 'confirm_receipt' }
  | { type: 'confirm_error' }

interface State {
  approvalState: LoadingState
  approvalNftState: LoadingState
  confirmState: LoadingState
}

const initialState: State = {
  approvalState: 'idle',
  approvalNftState: 'idle',
  confirmState: 'idle',
}

const reducer = (state: State, actions: Action): State => {
  switch (actions.type) {
    case 'requires_approval':
      return {
        ...state,
        approvalState: 'success',
      }
    case 'requires_nft_approval':
      return {
        ...state,
        approvalNftState: 'success',
      }
    case 'approve_sending':
      return {
        ...state,
        approvalState: 'loading',
      }
    case 'approve_nft_sending':
      return {
        ...state,
        approvalNftState: 'loading',
      }
    case 'approve_receipt':
      return {
        ...state,
        approvalState: 'success',
      }
    case 'approve_nft_receipt':
      return {
        ...state,
        approvalNftState: 'success',
      }
    case 'approve_error':
      return {
        ...state,
        approvalState: 'fail',
      }
    case 'approve_nft_error':
      return {
        ...state,
        approvalNftState: 'fail',
      }
    case 'confirm_sending':
      return {
        ...state,
        confirmState: 'loading',
      }
    case 'confirm_receipt':
      return {
        ...state,
        confirmState: 'success',
      }
    case 'confirm_error':
      return {
        ...state,
        confirmState: 'fail',
      }
    default:
      return state
  }
}

interface OnSuccessProps {
  state: State
  receipt: ethers.providers.TransactionReceipt
}

interface ApproveConfirmTransaction {
  onApprove: () => Promise<ethers.providers.TransactionResponse>
  onNftApprove: () => Promise<ethers.providers.TransactionResponse>
  onConfirm: () => Promise<ethers.providers.TransactionResponse>
  onRequiresApproval?: () => Promise<boolean>
  onRequiresNftApproval?: () => Promise<boolean>
  onSuccess: ({ state, receipt }: OnSuccessProps) => void
  onApproveSuccess?: ({ state, receipt }: OnSuccessProps) => void
  onNftApproveSuccess?: ({ receipt }: OnSuccessProps) => void
}

const useApproveConfirmTransaction = ({
  onApprove,
  onNftApprove,
  onNftApproveSuccess,
  onConfirm,
  onRequiresApproval,
  onRequiresNftApproval,
  onSuccess = noop,
  onApproveSuccess = noop,
}: ApproveConfirmTransaction) => {
  const { t } = useTranslation()
  const { account } = useAuth()
  const [state, dispatch] = useReducer(reducer, initialState)
  const handlePreApprove = useRef(onRequiresApproval)
  const handlePreApproveNft = useRef(onRequiresNftApproval)
  const { toastError } = useToast()

  // Check if approval is necessary, re-check if account changes
  useEffect(() => {
    if (account && handlePreApprove.current) {
      handlePreApprove.current().then((result) => {
        if (result) {
          dispatch({ type: 'requires_approval' })
        }
      })
    }

    if (account && handlePreApproveNft.current) {
      handlePreApproveNft.current().then((result) => {
        if (result) {
          dispatch({ type: 'requires_nft_approval' })
        }
      })
    }
  }, [account, handlePreApprove, dispatch])

  return {
    isApproving: state.approvalState === 'loading',
    isApproved: state.approvalState === 'success',
    isApprovingNft: state.approvalNftState === 'loading',
    isApprovedNft: state.approvalNftState === 'success',
    isConfirming: state.confirmState === 'loading',
    isConfirmed: state.confirmState === 'success',
    handleApprove: async () => {
      try {
        const tx = await onApprove()
        dispatch({ type: 'approve_sending' })
        const receipt = await tx.wait()
        if (receipt.status) {
          dispatch({ type: 'approve_receipt' })
          onApproveSuccess({ state, receipt })
        }
      } catch (error) {
        dispatch({ type: 'approve_error' })
        toastError(
          t('Error'),
          t('Please check BNB balance, confirm transaction and make sure you are paying enough gas'),
        )
      }
    },
    handleNftApprove: async () => {
      try {
        const tx = await onNftApprove()
        dispatch({ type: 'approve_nft_sending' })
        const receipt = await tx.wait()
        if (receipt.status) {
          dispatch({ type: 'approve_nft_receipt' })
          onNftApproveSuccess({ state, receipt })
        }
      } catch (error) {
        dispatch({ type: 'approve_nft_error' })
        toastError(
          t('Error'),
          t('Please check BNB balance, confirm transaction and make sure you are paying enough gas'),
        )
      }
    },
    handleConfirm: async () => {
      dispatch({ type: 'confirm_sending' })

      try {
        const tx = await onConfirm()
        const receipt = await tx.wait()
        if (receipt.status) {
          dispatch({ type: 'confirm_receipt' })
          onSuccess({ state, receipt })
        }
      } catch (error: any) {
        dispatch({ type: 'confirm_error' })
        if (error.data) {
          toastError(t('Error'), error?.data.message.charAt(0).toUpperCase() + error.data.message.slice(1))
        } else {
          toastError(t('Error'), error?.message.charAt(0).toUpperCase() + error.message.slice(1))
        }
      }
    },
  }
}

export default useApproveConfirmTransaction
