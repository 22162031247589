import BigNumber from 'bignumber.js'
import React, { useEffect, useState } from 'react'
import { Box, Text, Flex, Button, Skeleton, TooltipText, InfoIcon, useTooltip, useModal } from '@pancakeswap/uikit'
import styled from 'styled-components'

import { LotteryTicket, LotteryTicketClaimData } from 'config/constants/types'
import { fetchLottery } from 'state/lottery/helpers'
import { getWinningTickets } from 'state/lottery/fetchUnclaimedUserRewards'
import { fetchUserTicketsForOneRound } from 'state/lottery/getUserTicketsData'
import { LotteryRound } from 'state/types'
import { useGetUserLotteryGraphRoundById, useLottery } from 'state/lottery/hooks'
import { useTranslation } from 'react-i18next'
import useTheme from 'hooks/useTheme'
import useFreeNftAmount from 'hooks/useFreeNFTAmount'
import WinningNumbers from '../WinningNumbers'
import { processLotteryResponse } from '../../helpers'
import TicketNumber from '../TicketNumber'
import ClaimPrizesModal from '../ClaimPrizesModal'
import { useAuth } from 'hooks/useAuth'

const TopBox = styled(Flex)`
  flex-direction: column;
  margin: -24px;
  padding: 24px;
  background: #292d3f;
  border: none;
`

const ScrollBox = styled(Box)`
  margin-right: -20px;
  padding-right: 24px;
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 24px;
`
const StyledButton = styled(Button)`
  background-image: linear-gradient(265.56deg, #922c32 -0.27%, #922c32 -0.26%, #b67122 98.59%);
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  border-radius: 100px;
  box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
  cursor: pointer;
  font-size: 17px;
  font-size: 20px;
  font-weight: bold;
  -webkit-letter-spacing: 0.03em;
  -moz-letter-spacing: 0.03em;
  -ms-letter-spacing: 0.03em;
  -webkit-letter-spacing: 0.03em;
  -moz-letter-spacing: 0.03em;
  -ms-letter-spacing: 0.03em;
  letter-spacing: 0.03em;
  margin: 50px auto 0px auto;
  padding: 30px 85px;
  color: white;
  background-image: linear-gradient(265.56deg, #922c32 -0.27%, #922c32 -0.26%, #b67122 98.59%);
  border-width: 2px !important;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.03em;
  padding: 18px 0px;
  color: white !important;
  &:hover {
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`
const TicketSkeleton = () => {
  return (
    <>
      <Skeleton width="32px" height="12px" mt="2px" mb="4px" />
      <Skeleton width="100%" height="34px" mb="12px" />
    </>
  )
}

const PreviousRoundTicketsInner: React.FC<{ roundId: string }> = ({ roundId }) => {
  const [lotteryInfo, setLotteryInfo] = useState<LotteryRound>(null)
  const [allUserTickets, setAllUserTickets] = useState<LotteryTicket[]>(null)
  const [userWinningTickets, setUserWinningTickets] = useState<{
    allWinningTickets: LotteryTicket[]
    nftTotal: BigNumber
    ticketsWithUnclaimedRewards: LotteryTicket[]
    isFetched: boolean
    claimData: LotteryTicketClaimData
  }>({
    allWinningTickets: null,
    nftTotal: null,
    ticketsWithUnclaimedRewards: null,
    isFetched: false,
    claimData: null,
  })
  const { t } = useTranslation()
  const { currentRound } = useLottery()
  const { theme } = useTheme()
  const { account } = useAuth()
  const { totalTickets } = useGetUserLotteryGraphRoundById(roundId)
  const freeNftReward = useFreeNftAmount(roundId)
  const hasOnlyNftTokens = [userWinningTickets.claimData].length === 0 && freeNftReward > 0

  const [onPresentClaimModal] = useModal(
    <ClaimPrizesModal
      roundsToClaim={[userWinningTickets.claimData]}
      onlyNft={hasOnlyNftTokens}
      freeNftReward={freeNftReward}
    />,
    false,
  )

  const TooltipComponent = () => (
    <>
      <Text mb="16px">{t('views.lottery.components.viewTicketsModal.tooltip.text1')}</Text>
      <Text mb="16px">{t('views.lottery.components.viewTicketsModal.tooltip.text2')}</Text>
      <Text mb="4px">{t('views.lottery.components.viewTicketsModal.tooltip.text3')}</Text>
      <Text>{t('views.lottery.components.viewTicketsModal.tooltip.text4')}</Text>
    </>
  )

  const { targetRef, tooltip, tooltipVisible } = useTooltip(<TooltipComponent />, {
    placement: 'bottom-end',
    tooltipOffset: [20, 10],
  })

  useEffect(() => {
    const addWinningTicketInfoToAllTickets = (
      _allTickets: LotteryTicket[],
      _allWinningTickets: LotteryTicket[],
    ): LotteryTicket[] => {
      const allTicketsWithWinningTickets = _allTickets.map((ticket) => {
        const winningTicketEquivalent = _allWinningTickets.find((winningTicket) => winningTicket.id === ticket.id)
        if (winningTicketEquivalent) {
          return winningTicketEquivalent
        }
        return ticket
      })
      return allTicketsWithWinningTickets
    }

    const sortTicketsByWinningBracket = (tickets) => {
      return tickets.sort((ticketA, ticketB) => {
        const rewardBracket1 = ticketA.rewardBracket === undefined ? 0 : ticketA.rewardBracket + 1
        const rewardBracket2 = ticketB.rewardBracket === undefined ? 0 : ticketB.rewardBracket + 1
        return rewardBracket2 - rewardBracket1
      })
    }

    const fetchData = async () => {
      const userTickets = await fetchUserTicketsForOneRound(account, roundId)
      const lotteryData = await fetchLottery(roundId)
      const processedLotteryData = processLotteryResponse(lotteryData)
      const winningTickets = await getWinningTickets({
        roundId,
        userTickets,
        finalNumber: processedLotteryData.finalNumber.toString(),
      })

      setUserWinningTickets({
        isFetched: true,
        allWinningTickets: winningTickets?.allWinningTickets,
        nftTotal: winningTickets?.nftTotal,
        ticketsWithUnclaimedRewards: winningTickets?.ticketsWithUnclaimedRewards,
        claimData: winningTickets,
      })
      setLotteryInfo(processedLotteryData)

      // If the user has some winning tickets - modify the userTickets response to include that data
      if (winningTickets?.allWinningTickets) {
        const allTicketsWithWinningTicketInfo = addWinningTicketInfoToAllTickets(
          userTickets,
          winningTickets.allWinningTickets,
        )
        const ticketsSortedByWinners = sortTicketsByWinningBracket(allTicketsWithWinningTicketInfo)
        setAllUserTickets(ticketsSortedByWinners)
      } else {
        setAllUserTickets(userTickets)
      }
    }

    fetchData()
  }, [roundId, account, totalTickets, currentRound])

  const getFooter = () => {
    if (userWinningTickets?.ticketsWithUnclaimedRewards?.length > 0) {
      return (
        <StyledButton onClick={onPresentClaimModal} mt="24px" width="100%">
          {t('views.lottery.components.viewTicketsModal.btn')}
        </StyledButton>
      )
    }
    if (!userWinningTickets.allWinningTickets) {
      return (
        <div ref={targetRef}>
          <Flex alignItems="center" justifyContent="center" mt="20px">
            <InfoIcon height="20px" width="20px" color="textSubtle" mr="8px" />
            <TooltipText color="textSubtle">{t('views.lottery.components.viewTicketsModal.tooltipText')}</TooltipText>
          </Flex>
        </div>
      )
    }
    return null
  }

  return (
    <>
      {tooltipVisible && tooltip}
      <TopBox>
        <Text bold textTransform="uppercase" color="#11CABE" fontSize="12px" mb="4px">
          {t('views.lottery.components.viewTicketsModal.winningNumber')}
        </Text>
        {lotteryInfo?.finalNumber ? (
          <WinningNumbers number={lotteryInfo.finalNumber.toString()} />
        ) : (
          <Skeleton width="230px" height="34px" />
        )}
      </TopBox>
      <ScrollBox>
        <Text bold textTransform="uppercase" color="#11CABE" fontSize="12px" my="16px">
          {t('views.lottery.components.viewTicketsModal.yourTickets')}
        </Text>
        <Flex mb="8px" justifyContent="space-between">
          <Flex>
            <Text bold color="#fff">
              {t('views.lottery.components.viewTicketsModal.totalTickets')}:
            </Text>
          </Flex>
          <Text bold color="#fff">
            {allUserTickets ? allUserTickets.length : <Skeleton width="56px" height="24px" />}
          </Text>
        </Flex>
        <Flex mb="24px" justifyContent="space-between">
          <Flex>
            <Text bold color="#fff">
              {t('views.lottery.components.viewTicketsModal.winningTickets')}:
            </Text>
          </Flex>
          <Text bold color="#fff">
            {userWinningTickets.isFetched ? (
              userWinningTickets?.allWinningTickets?.length || '0'
            ) : (
              <Skeleton width="40px" height="24px" />
            )}
          </Text>
        </Flex>
        <Flex mb="24px" justifyContent="space-between">
          <Flex>
            <Text bold color="#EC9A44">
              {t('NFT tickets')}:
            </Text>
          </Flex>
          <Text bold color="#EC9A44">
            {userWinningTickets.isFetched ? (
              ` + ${userWinningTickets?.nftTotal ? userWinningTickets?.nftTotal.toString() : '0'}` || '0'
            ) : (
              <Skeleton width="40px" height="24px" />
            )}
          </Text>
        </Flex>
        {allUserTickets ? (
          allUserTickets.map((ticket) => {
            return (
              <TicketNumber
                key={ticket.id}
                id={ticket.id}
                number={ticket.number}
                rewardBracket={ticket.rewardBracket}
                status={ticket.status}
              />
            )
          })
        ) : (
          <>
            <TicketSkeleton />
            <TicketSkeleton />
            <TicketSkeleton />
            <TicketSkeleton />
          </>
        )}
      </ScrollBox>
      <Flex borderTop={`1px solid ${theme.colors.cardBorder}`} alignItems="center" justifyContent="center">
        {userWinningTickets.isFetched && getFooter()}
      </Flex>
    </>
  )
}

export default PreviousRoundTicketsInner
