import React, { FC } from 'react'
import styled from 'styled-components'

type LeaderboardTitleProps = {
  title: string
}

export const LeaderboardTitle: FC<LeaderboardTitleProps> = ({ title }) => <Title>{title}</Title>

export const Title = styled.div`
  display: inline-flex;
  color: white;
  font-weight: 700;
  font-size: 61px;
  line-height: 67px;
`
