import React from 'react'
import { Flex } from '@pancakeswap/uikit'
import tokens from '../../../config/constants/tokens'

export const addToMetamask = () => {
  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokens.plt.address, // The address that the token is at.
          symbol: tokens.plt.symbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokens.plt.decimals, // The number of decimals in the token
          image: 'https://www.pltlotto.com/images/logo.png', // A string url of the token logo
        },
      },
    })
  } catch (error) {
    // error;
  }
}

const AddMetamask = () => {
  return (
    <Flex
      onClick={addToMetamask}
      style={{
        padding: '5px',
      }}
    >
      <img src="/images/lottery/metamask.svg" width="24px" height="24px" alt="Add PLT to Metamask" />
    </Flex>
  )
}

export default AddMetamask
