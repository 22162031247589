import React from 'react'
import styled from 'styled-components'
import { Text, Box, Flex, Button } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'
import { useGetUserLotteriesGraphData } from 'state/lottery/hooks'

import FinishedRoundRow from './FinishedRoundRow'

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;
`

const StyledGrid = styled(Grid)`
  background: #292d3f;
  padding-bottom: 8px;
  margin-bottom: 0px;
`

const StyledFlex = styled(Flex)`
  background: #292d3f;
`

interface FinishedRoundTableProps {
  handleHistoryRowClick: (string) => void
  handleShowMoreClick: () => void
  numUserRoundsRequested: number
}

const FinishedRoundTable: React.FC<FinishedRoundTableProps> = ({
  handleShowMoreClick,
  numUserRoundsRequested,
  handleHistoryRowClick,
}) => {
  const { t } = useTranslation()
  const userLotteryData = useGetUserLotteriesGraphData()

  const filteredForClaimable = userLotteryData?.rounds.filter(() => true)

  const sortedByRoundId = filteredForClaimable?.sort((roundA, roundB) => {
    return parseInt(roundB.lotteryId, 10) - parseInt(roundA.lotteryId, 10)
  })
  return (
    <>
      <StyledGrid px="24px" pt="24px" mb="8px">
        <Text bold fontSize="12px" color="#11CABE">
          #
        </Text>
        <Text bold fontSize="12px" color="#11CABE" textTransform="uppercase">
          {t('views.lottery.components.yourHistoryCard.date')}
        </Text>
        <Text bold fontSize="12px" color="#11CABE" textTransform="uppercase">
          {t('views.lottery.components.yourHistoryCard.yourTickets')}
        </Text>
        <Box width="20px" />
      </StyledGrid>
      <StyledFlex px="24px" pb="24px" flexDirection="column" overflowY="scroll" height="240px">
        {userLotteryData &&
          sortedByRoundId.map((finishedRound) => (
            <FinishedRoundRow
              key={finishedRound.lotteryId}
              roundId={finishedRound.lotteryId}
              hasWon={finishedRound.claimed}
              numberTickets={finishedRound.totalTickets}
              endTime={finishedRound.endTime}
              onClick={handleHistoryRowClick}
            />
          ))}
        {userLotteryData?.rounds?.length === numUserRoundsRequested && (
          <Flex justifyContent="center">
            <Button mt="12px" variant="text" width="fit-content" onClick={handleShowMoreClick}>
              {t('views.lottery.components.yourHistoryCard.showMore')}
            </Button>
          </Flex>
        )}
      </StyledFlex>
    </>
  )
}

export default FinishedRoundTable
