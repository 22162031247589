import { request, gql } from 'graphql-request'
import { GRAPH_API_LOTTERY } from 'config/constants/endpoints'
import { LeaderboardAllTimeGraphEntity } from 'state/types'

export const MAX_LEADERBOARD_REQUEST_SIZE = 20

export const getGraphLeaderboardAllTime = async (
  first = MAX_LEADERBOARD_REQUEST_SIZE,
  skip = 0,
): Promise<LeaderboardAllTimeGraphEntity[]> => {
  try {
    const response = await request(
      GRAPH_API_LOTTERY,
      gql`
        query getLeaderboardAllTime($first: Int!, $skip: Int!) {
          users(first: $first, skip: $skip, orderBy: totalPLTClaimed, orderDirection: desc) {
            id
            totalRounds
            totalTickets
            totalPLTClaimed
          }
        }
      `,
      { skip, first },
    )
    return response.users
  } catch (error) {
    console.error(error)
    return []
  }
}

const getLeaderboardAllTimeData = async (): Promise<LeaderboardAllTimeGraphEntity[]> => {
  const graphResponse = await getGraphLeaderboardAllTime()
  return graphResponse
}

export default getLeaderboardAllTimeData
