import React, { useState, useEffect } from 'react'
import { Box, Flex, Heading, Skeleton, Link } from '@pancakeswap/uikit'
import { LotteryStatus } from 'config/constants/types'
import PageSection from 'components/PageSection'
import { useTranslation } from 'react-i18next'
import useTheme from 'hooks/useTheme'
import { useFetchLottery, useLottery } from 'state/lottery/hooks'
import { Footer } from 'components/Footer'
import useGetNextLotteryEvent from './hooks/useGetNextLotteryEvent'
import useStatusTransitions from './hooks/useStatusTransitions'
import Hero from './components/Hero'
import NextDrawCard from './components/NextDrawCard'
import Countdown from './components/Countdown'
import HistoryTabMenu from './components/HistoryTabMenu'
import YourHistoryCard from './components/YourHistoryCard'
import AllHistoryCard from './components/AllHistoryCard'
import CheckPrizesSection from './components/CheckPrizesSection'
import HowToPlay from './components/HowToPlay'
import useShowMoreUserHistory from './hooks/useShowMoreUserRounds'

import { TITLE_BG } from './pageSectionStyles'

import { ReactComponent as PlCard1 } from '../../assets/main/pl-card-1.svg'
import { ReactComponent as PlCard2 } from '../../assets/main/pl-card-2.svg'
import { ReactComponent as PlCard3 } from '../../assets/main/pl-card-3.svg'
import { ReactComponent as ExternalLink } from '../../assets/main/external-link.svg'

import '../../style/views/Main/style.css'

import { Leaderboard } from 'components/Leaderboard'
import {
  BagDecorations,
  FreeTicketSection,
  HeroSection,
  LotteryPage,
  PoollotoCardList,
  PoollotoCardListItem,
  PoweredBlock,
  StarsDecorations0,
  StarsDecorations1,
  StarsDecorations2,
  StarsDecorations3,
  StyledHeading,
  StyledHeadingSubtitle,
  WrapperContainer,
} from './styled'

const Lottery = () => {
  const [width, setWidth] = useState<number>(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile: boolean = width <= 768
  useFetchLottery()
  useStatusTransitions()
  const { t } = useTranslation()
  const { theme } = useTheme()
  const {
    currentRound: { status, endTime },
  } = useLottery()
  const [historyTabMenuIndex, setHistoryTabMenuIndex] = useState(0)
  const endTimeAsInt = parseInt(endTime, 10)
  const { nextEventTime, postCountdownText, preCountdownText } = useGetNextLotteryEvent(endTimeAsInt, status)
  const { numUserRoundsRequested, handleShowMoreUserRounds } = useShowMoreUserHistory()

  return (
    <LotteryPage>
      <PoweredBlock className="powered">
        Powered by &nbsp;
        <Link href="https://www.poollotto.finance/" target="_blank">
          Poollotto.finance
        </Link>
      </PoweredBlock>
      {/* {isMobile ? (
        <div className="mobile-banner-wrapper">
          <a
            target="_blank"
            rel="noreferrer"
            className="banner is-mobile"
            href="https://medium.com/@poollotto/staking-bnb-plt-lp-tokens-is-now-live-%EF%B8%8F-get-lp-rewards-in-plt-and-dodo-282d681f9819"
          >
            <div className="banner__text">
              Stake PLT token on DODO, earn&nbsp;up&nbsp;to&nbsp;<span>300% APR</span>
            </div>
          </a>
        </div>
      ) : (
        <div className="banner-wrapper">
          <a
            target="_blank"
            rel="noreferrer"
            className="banner"
            href="https://medium.com/@poollotto/staking-bnb-plt-lp-tokens-is-now-live-%EF%B8%8F-get-lp-rewards-in-plt-and-dodo-282d681f9819"
          >
            <div className="banner__text">
              Stake PLT token on DODO, earn&nbsp;up&nbsp;to&nbsp;<span>300% APR</span>
            </div>
          </a>
        </div>
      )} */}
      <HeroSection>
        {!isMobile ? (
          <StarsDecorations0>
            <img src="/images/lottery/coins.gif" style={{ paddingTop: '30px' }} width="121px" height="72px" alt="" />
          </StarsDecorations0>
        ) : null}
        <Hero />
      </HeroSection>
      <FreeTicketSection>
        <Flex alignItems="center" justifyContent="center" flexDirection="column">
          {!isMobile ? (
            <StarsDecorations1>
              <img src="/images/lottery/coins.gif" width="121px" height="72px" alt="" />
            </StarsDecorations1>
          ) : null}
          {status === LotteryStatus.OPEN && (
            <Flex alignItems="center" justifyContent="center" flex="2" flexDirection="column">
              <StyledHeading>{t('views.lottery.title')}</StyledHeading>
              <StyledHeadingSubtitle>{t('views.lottery.subTitle')}</StyledHeadingSubtitle>
            </Flex>
          )}
          <Flex alignItems="center" justifyContent="center" mt="50px" mb="120px">
            {nextEventTime && (postCountdownText || preCountdownText) ? (
              <Countdown
                nextEventTime={nextEventTime}
                postCountdownText={postCountdownText}
                preCountdownText={preCountdownText}
              />
            ) : (
              <Skeleton height="41px" width="250px" />
            )}
          </Flex>
          {/* <GetNftSection /> */}
          <CheckPrizesSection />
          <NextDrawCard />
          {!isMobile ? (
            <StarsDecorations2>
              <img src="/images/lottery/coins.gif" width="120px" height="80px" alt="" />
            </StarsDecorations2>
          ) : null}
        </Flex>
      </FreeTicketSection>
      <PageSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        background={TITLE_BG}
        hasCurvedDivider={false}
        index={2}
      >
        <Flex width="100%" flexDirection="column" alignItems="center" justifyContent="center">
          <Heading color="#fff" mb="24px" scale="xxl" textAlign="center">
            {t('views.lottery.finishedRoundsTitle')}
          </Heading>
          <Box mb="24px">
            <HistoryTabMenu
              activeIndex={historyTabMenuIndex}
              setActiveIndex={(index) => setHistoryTabMenuIndex(index)}
            />
            {!isMobile ? (
              <BagDecorations src="/images/lottery/coins_bag.gif" width="120px" height="70px" alt="" />
            ) : null}
          </Box>
          {historyTabMenuIndex === 0 ? (
            <AllHistoryCard />
          ) : (
            <YourHistoryCard
              handleShowMoreClick={handleShowMoreUserRounds}
              numUserRoundsRequested={numUserRoundsRequested}
            />
          )}
        </Flex>
      </PageSection>
      <Leaderboard />
      <PageSection
        background={TITLE_BG}
        dividerPosition="top"
        dividerFill={{ light: theme.colors.background }}
        clipFill={{ light: '#9A9FD0', dark: '#66578D' }}
        index={2}
      >
        {!isMobile ? (
          <StarsDecorations3>
            <img src="/images/lottery/coins.gif" width="140px" height="80px" alt="" />
          </StarsDecorations3>
        ) : null}
        <HowToPlay />
      </PageSection>
      <WrapperContainer>
        <PoollotoCardList>
          <PoollotoCardListItem>
            <div className="poollotto-card-block">
              <div className="poollotto-card-block__round">
                <PlCard1 data-block="1" />
              </div>
              <div className="poollotto-card-block__content">
                <Link href="https://www.poollotto.finance/" target="_blank">
                  Poollotto.finance
                  <ExternalLink />
                </Link>
              </div>
            </div>
          </PoollotoCardListItem>
          <PoollotoCardListItem>
            <div className="poollotto-card-block">
              <div className="poollotto-card-block__round">
                <PlCard2 data-block="2" />
              </div>
              <div className="poollotto-card-block__content">
                <Link
                  href="https://poollotto-finance.gitbook.io/pltlotto_q_and_a/about-plt-lotto/what-is-the-plt-lotto-nft-ticket"
                  target="_blank"
                >
                  NFT tickets
                  <ExternalLink />
                </Link>
              </div>
            </div>
          </PoollotoCardListItem>
          <PoollotoCardListItem>
            <div className="poollotto-card-block">
              <div className="poollotto-card-block__round">
                <PlCard3 />
              </div>
              <div className="poollotto-card-block__content">
                Poollotto
                <span>{t('poollotto.comingSoon')}</span>
              </div>
            </div>
          </PoollotoCardListItem>
        </PoollotoCardList>
      </WrapperContainer>
      <Footer />
    </LotteryPage>
  )
}

export default Lottery
