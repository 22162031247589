import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import history from 'routerHistory'
import { Box, Flex, Heading, Text, Link } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'
import { PrizeFunds } from 'components/PrizeFunds'

const Divider = styled.div`
  background-color: #575a6c;
  height: 1px;
  margin: 40px 0;
  width: 100%;
`

const BulletList = styled.ul`
  list-style-type: none;
  margin-left: 8px;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
  }
  li::before {
    content: '•';
    margin-right: 4px;
    color: ${({ theme }) => theme.colors.textSubtle};
  }
  li::marker {
    font-size: 12px;
  }
`

export const InlineButton = styled(Link)`
  background-image: linear-gradient(265.56deg, #922c32 -0.27%, #922c32 -0.26%, #b67122 98.59%);
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  border-radius: 25px;
  box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
  -webkit-letter-spacing: 0.03em;
  -moz-letter-spacing: 0.03em;
  -ms-letter-spacing: 0.03em;
  letter-spacing: 0.03em;
  margin: 50px auto 0px auto;
  padding: 11px 32px;
  color: white;
`

const StepContainer = styled(Flex)`
  gap: 33px;
  width: 100%;
  flex-direction: column;
  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    flex-wrap: wrap;
  }
  justify-content: center;
`

const StyledStepCard = styled(Box)`
  display: flex;
  position: relative;
  padding: 1px 1px 3px 1px;
  align-self: center;
  flex-shrink: 0;
  width: 285px;
  border-radius: ${({ theme }) => theme.radii.card};
  ${({ theme }) => theme.mediaQueries.sm} {
    align-self: center;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    align-self: center;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    align-self: initial;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    align-self: baseline;
  }
`

const StepCardInner = styled(Box)`
  width: 100%;
  min-height: 330px;
  padding: 40px;
  background: #292d3f;
  border-radius: 12px;
`

const StepText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 91px;
  height: 91px;
  background: #34384c;
  border-radius: 50px;
  margin: auto;
  text-align: center;
  color: white;
  font-weight: bold;
  font-variant: all-small-caps;
  font-size: 18px;
  white-space: pre-line;
  padding: 30px;
  margin-bottom: 40px;
`

const GappedFlex = styled(Flex)`
  gap: 24px;
`

const Space = styled(Flex)`
  height: 40px;
`

const QuestionsFlex = styled(Flex)`
  border-radius: 12px;
  background-color: #292d3f;
  padding: 40px;
  margin: 0px 180px;
`

const QuestionsFlexMobile = styled(Flex)`
  border-radius: 12px;
  background-color: #292d3f;
  padding: 40px;
`

type Step = { title: string; subtitle: string; label: string }
const HowToPlay: React.FC = () => {
  const [width, setWidth] = useState<number>(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile: boolean = width <= 768

  const { t } = useTranslation()

  const steps: Step[] = [
    {
      label: t('views.lottery.components.howToPlay.steps.label', { stepNumber: 1 }),
      title: t('views.lottery.components.howToPlay.steps.title_context', { context: 1 }),
      subtitle: t('views.lottery.components.howToPlay.steps.subtitle_context', { context: 1 }),
    },
    {
      label: t('views.lottery.components.howToPlay.steps.label', { stepNumber: 2 }),
      title: t('views.lottery.components.howToPlay.steps.title_context', { context: 2 }),
      subtitle: t('views.lottery.components.howToPlay.steps.subtitle_context', { context: 2 }),
    },
    {
      label: t('views.lottery.components.howToPlay.steps.label', { stepNumber: 3 }),
      title: t('views.lottery.components.howToPlay.steps.title_context', { context: 3 }),
      subtitle: t('views.lottery.components.howToPlay.steps.subtitle_context', { context: 3 }),
    },
  ]

  const routeChange = () => {
    history.push('/onboarding')
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Box width="100%" id="how_to_play">
      <Flex mb="40px" alignItems="center" flexDirection="column">
        <Heading mb="24px" mt="40px" scale="xxl" color="white">
          {t('views.lottery.components.howToPlay.title')}
        </Heading>
        <Text color="white" textAlign="center">
          {t('views.lottery.components.howToPlay.subTitle')}
        </Text>
      </Flex>
      <StepContainer>
        <StyledStepCard>
          <StepCardInner>
            <StepText>{steps[0].label}</StepText>
            <Heading textAlign="center" mb="16px" scale="lg" color="white">
              {steps[0].title}
            </Heading>
            <Text textAlign="center" fontWeight="normal" color="white">
              {steps[0].subtitle}
            </Text>
          </StepCardInner>
        </StyledStepCard>
        <StyledStepCard>
          <Space />
          <StepCardInner>
            <StepText>{steps[1].label}</StepText>
            <Heading textAlign="center" mb="16px" scale="lg" color="white">
              {steps[1].title}
            </Heading>
            <Text textAlign="center" fontWeight="normal" color="white">
              {steps[1].subtitle}
            </Text>
          </StepCardInner>
        </StyledStepCard>
        <StyledStepCard>
          <StepCardInner>
            <StepText>{steps[2].label}</StepText>
            <Heading textAlign="center" mb="16px" scale="lg" color="white">
              {steps[2].title}
            </Heading>
            <Text textAlign="center" fontWeight="normal" color="white">
              {steps[2].subtitle}
            </Text>
          </StepCardInner>
        </StyledStepCard>
      </StepContainer>
      <Divider />
      <GappedFlex flexDirection={['column', 'column', 'column', 'row']}>
        <Flex flex="2" flexDirection="column">
          <Heading mb="24px" scale="lg" color="white">
            {t('views.lottery.components.howToPlay.textBlock1.title')}
          </Heading>
          <Heading mb="24px" scale="md" color="white">
            {t('views.lottery.components.howToPlay.textBlock1.subTitle')}
          </Heading>
          <Text fontWeight="100" mb="16px" color="white">
            {t('views.lottery.components.howToPlay.textBlock1.text1')}
          </Text>
          <BulletList>
            <li>
              <Text display="inline" color="white">
                {t('views.lottery.components.howToPlay.textBlock1.list.item1')}
              </Text>
            </li>
            <li>
              <Text display="inline" color="white">
                {t('views.lottery.components.howToPlay.textBlock1.list.item2')}
              </Text>
            </li>
          </BulletList>
          <Text mt="16px" color="white">
            {t('views.lottery.components.howToPlay.textBlock1.text2')}
          </Text>
        </Flex>
        <Flex flex="1" justifyContent="center">
          <WinningCriteria>
            <img src="/images/lottery/WinningCriteria.png" alt="" />
          </WinningCriteria>
        </Flex>
      </GappedFlex>
      <Divider />
      <GappedFlex flexDirection={['column', 'column', 'column', 'row']}>
        <Flex flex="2" flexDirection="column">
          <Heading mb="24px" scale="lg" color="white">
            {t('views.lottery.components.howToPlay.textBlock2.title')}
          </Heading>
          <Text color="white">{t('views.lottery.components.howToPlay.textBlock2.text1')}</Text>
          <Heading color="white" my="16px" scale="md">
            {t('views.lottery.components.howToPlay.textBlock2.subTitle_context', { context: 'ticket' })}
          </Heading>
          <BulletList>
            <li>
              <Text display="inline" color="white">
                {t('views.lottery.components.howToPlay.textBlock2.listItem_context', { context: 'ticket' })}
              </Text>
            </li>
          </BulletList>
          <Heading color="white" my="16px" scale="md">
            {t('views.lottery.components.howToPlay.textBlock2.subTitle_context', { context: 'rolloverPrizes' })}
          </Heading>
          <BulletList>
            <li>
              <Text display="inline" color="white">
                {t('views.lottery.components.howToPlay.textBlock2.listItem_context', { context: 'rolloverPrizes' })}
              </Text>
            </li>
          </BulletList>
          <Heading color="white" my="16px" scale="md">
            {t('views.lottery.components.howToPlay.textBlock2.subTitle_context', { context: 'pltInjections' })}
          </Heading>
          <BulletList>
            <li>
              <Text display="inline" color="white">
                {t('views.lottery.components.howToPlay.textBlock2.listItem_context', { context: 'pltInjections' })}
              </Text>
            </li>
          </BulletList>
        </Flex>
        <Flex flex="1" justifyContent="center">
          <PrizeFunds />
        </Flex>
      </GappedFlex>
      <Divider />
      {isMobile ? (
        <QuestionsFlexMobile justifyContent="center" alignItems="center" flexDirection={['column', 'column', 'row']}>
          <Flex flexDirection="column">
            <Heading textAlign="center" color="white" mb="16px" scale="md">
              {t('views.lottery.components.howToPlay.questionBlock.title')}
            </Heading>
            <Text textAlign="center" color="white">
              {t('views.lottery.components.howToPlay.questionBlock.text')}{' '}
              <InlineButton onClick={routeChange} target="_blank">
                {t('views.lottery.components.howToPlay.questionBlock.btn')}
              </InlineButton>
            </Text>
          </Flex>
        </QuestionsFlexMobile>
      ) : (
        <QuestionsFlex justifyContent="center" alignItems="center" flexDirection={['column', 'column', 'row']}>
          <Flex flexDirection="column">
            <Heading textAlign="center" color="white" mb="16px" scale="md">
              {t('views.lottery.components.howToPlay.questionBlock.title')}
            </Heading>
            <Text textAlign="center" color="white">
              {t('views.lottery.components.howToPlay.questionBlock.text')}{' '}
              <InlineButton onClick={routeChange} target="_blank">
                {t('views.lottery.components.howToPlay.questionBlock.btn')}
              </InlineButton>
            </Text>
          </Flex>
        </QuestionsFlex>
      )}
    </Box>
  )
}
export default HowToPlay

const WinningCriteria = styled.div`
  width: 100%;
  max-width: 495px;
  ${({ theme }) => theme.mediaQueries.md} {
    min-width: 400px;
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    min-width: 495px;
  }
`
