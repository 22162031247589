import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, Skeleton, Heading, Box, Text } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'
import { LotteryRound, LotteryRoundGraphEntity } from 'state/types'
import { useGetLotteryGraphDataById } from 'state/lottery/hooks'
import { getGraphLotteries } from 'state/lottery/getLotteriesData'
import { formatNumber, getBalanceNumber } from 'utils/formatBalance'
import Balance from 'components/Balance'
import { usePricePLT } from '../../hooks/usePricePLT'
import RewardBrackets from '../RewardBrackets'

const NextDrawWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.background};
  padding: 24px;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`

const StyledNextDrawWrapper = styled(NextDrawWrapper)`
  background: #40455c;
  border-radius: 26px;
  margin: 30px;
`

const StyledBalance = styled(Balance)`
  text-align: center;
`

const PreviousRoundCardFooter: React.FC<{ lotteryNodeData: LotteryRound; lotteryId: string }> = ({
  lotteryNodeData,
  lotteryId,
}) => {
  const [width, setWidth] = useState<number>(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile: boolean = width <= 768

  const { t } = useTranslation()
  const [fetchedLotteryGraphData, setFetchedLotteryGraphData] = useState<LotteryRoundGraphEntity>()
  const lotteryGraphDataFromState = useGetLotteryGraphDataById(lotteryId)
  const pltPrice = usePricePLT()

  useEffect(() => {
    const getGraphData = async () => {
      const fetchedGraphData = await getGraphLotteries(undefined, undefined, { id_in: [lotteryId] })
      setFetchedLotteryGraphData(fetchedGraphData[0])
    }
    if (!lotteryGraphDataFromState) {
      getGraphData()
    }
  }, [lotteryGraphDataFromState, lotteryId])

  let prizeInBusd
  if (lotteryNodeData) {
    const { amountCollectedInCake } = lotteryNodeData
    prizeInBusd = amountCollectedInCake.times(pltPrice)
  }

  // const getTotalUsers = (): string => {
  //   if (!lotteryGraphDataFromState && fetchedLotteryGraphData) {
  //     return fetchedLotteryGraphData?.totalUsers?.toLocaleString()
  //   }

  //   if (lotteryGraphDataFromState) {
  //     return lotteryGraphDataFromState?.totalUsers?.toLocaleString()
  //   }

  //   return null
  // }

  const getTotalTicket = (): string =>
    !lotteryGraphDataFromState && fetchedLotteryGraphData
      ? fetchedLotteryGraphData?.totalTickets?.toLocaleString()
      : lotteryGraphDataFromState
      ? lotteryGraphDataFromState?.totalTickets?.toLocaleString()
      : null

  const getPrizeBalances = () => {
    return (
      <>
        {prizeInBusd === undefined || prizeInBusd.isNaN() ? (
          <Skeleton my="7px" height={40} width={100} />
        ) : (
          <Heading scale="xl" lineHeight="1" color="#11CABE !important">
            ~${formatNumber(getBalanceNumber(prizeInBusd), 0, 0)}
          </Heading>
        )}
        {prizeInBusd === undefined || prizeInBusd.isNaN() ? (
          <Skeleton my="2px" height={14} width={90} />
        ) : (
          <StyledBalance
            fontSize="14px"
            color="#fff"
            unit=" PLT"
            value={getBalanceNumber(lotteryNodeData?.amountCollectedInCake)}
            decimals={0}
          />
        )}
      </>
    )
  }
  return (
    <StyledNextDrawWrapper>
      {!isMobile ? (
        <Flex mr="70px" flexDirection="column" justifyContent="space-between">
          <Box>
            <Heading color="white">{t('views.lottery.components.previousRoundCard.prizePot')}</Heading>
            {getPrizeBalances()}
          </Box>
          <Box mb="24px">
            <Flex>
              <Text color="#ffffff" fontSize="14px" display="inline">
                {t('views.lottery.components.previousRoundCard.ticketsInRound')}:{' '}
                {lotteryNodeData && (lotteryGraphDataFromState || fetchedLotteryGraphData) ? (
                  getTotalTicket()
                ) : (
                  <Skeleton height={14} width={31} />
                )}
              </Text>
            </Flex>
          </Box>
        </Flex>
      ) : (
        <Flex flexDirection="column" alignItems="center">
          <Box>
            <Heading color="white">{t('views.lottery.components.previousRoundCard.prizePot')}</Heading>
            {getPrizeBalances()}
          </Box>
          <Box mb="24px">
            <Flex>
              <Text color="#ffffff" fontSize="14px" display="inline">
                {t('views.lottery.components.previousRoundCard.ticketsInRound')}:{' '}
                {lotteryNodeData && (lotteryGraphDataFromState || fetchedLotteryGraphData) ? (
                  getTotalTicket()
                ) : (
                  <Skeleton height={14} width={31} />
                )}
              </Text>
            </Flex>
          </Box>
        </Flex>
      )}
      <RewardBrackets lotteryNodeData={lotteryNodeData} isHistoricRound />
    </StyledNextDrawWrapper>
  )
}

export default PreviousRoundCardFooter
