import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Text, Flex, ChevronRightIcon, Box, PrizeIcon } from '@pancakeswap/uikit'
import { fetchLottery } from 'state/lottery/helpers'
import { processLotteryResponse } from 'views/Lottery/helpers'
import { getWinningTickets } from 'state/lottery/fetchUnclaimedUserRewards'
import { fetchUserTicketsForOneRound } from 'state/lottery/getUserTicketsData'
import { ReactComponent as NFTIcon } from 'assets/icons/NFTTransparent.svg'
import { dateOptions, timeOptions } from '../../helpers'
import CircleLoader from '../../../../components/Loader/CircleLoader'
import { useAuth } from 'hooks/useAuth'

interface FinishedRoundRowProps {
  roundId: string
  numberTickets: string
  endTime: string
  onClick: (string) => void
  hasWon?: boolean
}

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;
  margin-bottom: 8px;
  cursor: pointer;
`

const FinishedRoundRow: React.FC<FinishedRoundRowProps> = ({
  roundId,
  numberTickets,
  endTime,
  onClick,
  hasWon = false,
}) => {
  const [isLoading, setLoading] = useState(true)
  const [hasNft, setHasNft] = useState(false)
  const endTimeInMs = parseInt(endTime, 10) * 1000
  const endTimeAsDate = new Date(endTimeInMs)
  const { account } = useAuth()

  const getHasNft = async () => {
    try {
      setLoading(true)
      const userTickets = await fetchUserTicketsForOneRound(account, roundId)
      const lotteryData = await fetchLottery(roundId)
      const processedLotteryData = processLotteryResponse(lotteryData)
      const winningTickets = await getWinningTickets({
        roundId,
        userTickets,
        finalNumber: processedLotteryData.finalNumber.toString(),
      })
      setHasNft(winningTickets?.nftTotal?.isPositive())
      setLoading(false)
    } catch {
      setHasNft(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    getHasNft()
  }, [])

  return (
    <Grid onClick={() => onClick(roundId)}>
      <Flex alignItems="center">
        <Text fontSize="16px" color="#fff">
          {roundId}
        </Text>
      </Flex>
      <Flex
        mx="6px"
        alignItems={['flex-start', null, 'center']}
        justifyContent={['center', null, 'flex-start']}
        flexDirection={['column', null, 'row']}
      >
        <Text color="#fff" fontSize="12px" mr={['0', null, '6px']}>
          {endTimeAsDate.toLocaleDateString(undefined, dateOptions)}
        </Text>
        <Text fontSize="12px" color="#fff">
          {endTimeAsDate.toLocaleTimeString(undefined, timeOptions)}
        </Text>
      </Flex>
      <Flex mx="6px" alignItems="center" justifyContent="space-between">
        <Text color="#fff">{numberTickets}</Text>
        <Flex alignItems="center">
          {isLoading ? (
            <CircleLoader />
          ) : (
            <>
              {hasNft && <NFTIcon />}
              {hasWon ? <PrizeIcon color="warning" marginLeft="5px" /> : null}
            </>
          )}
        </Flex>
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <ChevronRightIcon color="#11CABE" />
      </Flex>
    </Grid>
  )
}

export default FinishedRoundRow
