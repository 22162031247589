import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { event, initialize } from 'react-ga'
import LotteryPage from 'views/Lottery'
import { ResetCSS } from '@pancakeswap/uikit'
import { Router, Route, Switch } from 'react-router-dom'
import { isDevelopment } from 'utils/isDevelopment'
import { useNotificatios } from 'hooks/useNotificatios'
import { usePollBlockNumber } from 'state/block/hooks'
import { NFTBalance } from 'components'
import UserMenu from 'components/Menu/UserMenu'
import { LangSwitcher } from 'components/Menu/LangSwitcher'
import { Burger } from 'components/Burger'
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg'
import {
  Header,
  HeaderLogo,
  HeaderLeftPart,
  HeaderRightPart,
  HeaderMobileRight,
  MenuList,
  MenuListItem,
  BurgerMenu,
  PlayLotteryBtn,
} from 'app.styled'
import { SocialList } from 'components/SocialList'
import { LangSupportedList } from 'utils/types'
import { ToastListener } from './contexts/ToastsContext'
import GlobalStyle from './style/Global'
import history from './routerHistory'
import { Onboarding } from './views/Onboarding'
import useOutsideClick from './hooks/useOutsideClick'
import { withWagmi } from 'hocs/with-wagmi'
import { withAuth } from 'hocs/with-auth'
import { useAuth } from 'hooks/useAuth'

enum EListMenu {
  getPLT = 'header-get-plt',
  howToPlay = 'header-how-to-play',
  qa = 'header-qa',
}

const App: React.FC = () => {
  const ref = useRef()
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [isOpen, setOpen] = useState<boolean>(false)

  const { account } = useAuth()
  const { t, i18n } = useTranslation()
  useNotificatios()

  const customLinkHandler = (e: Event, href: string) => {
    e.preventDefault()
    window.open(href, '_blank')
    setOpen(false)
  }

  const isCurrentLanguage = (lang: LangSupportedList) => i18n.language.includes(lang)
  const getLinkMenu = (id: EListMenu): string => {
    let link = ''

    switch (id) {
      case EListMenu.getPLT:
        link = 'https://pancakeswap.finance/swap?outputCurrency=0x631C2f0EdABaC799f07550aEE4fF0Bf7fd35212B'
        break
      case EListMenu.howToPlay:
        link = '/onboarding'
        break
      case EListMenu.qa:
        if (isCurrentLanguage(LangSupportedList.en)) {
          link = 'https://poollotto-finance.gitbook.io/pltlotto_q_and_a/'
        }
        if (isCurrentLanguage(LangSupportedList.es)) {
          link =
            'https://poollotto-finance.gitbook.io/pltlotto_q_and_a/v/espanol-1/acerca-de-plt-lotto/que-es-plt-lotto'
        }
        if (
          isCurrentLanguage(LangSupportedList.ja) ||
          isCurrentLanguage(LangSupportedList.de) ||
          isCurrentLanguage(LangSupportedList.fr)
        ) {
          // TODO
          link = 'https://poollotto-finance.gitbook.io/pltlotto_q_and_a/'
        }
        break
      default:
        break
    }

    return link
  }
  const listMenu = [
    {
      id: EListMenu.getPLT,
      link: () => getLinkMenu(EListMenu.getPLT),
      isBlank: true,
      text: () => t('app.menu.getPLT'),
    },
    {
      id: EListMenu.howToPlay,
      link: () => getLinkMenu(EListMenu.howToPlay),
      text: () => t('app.menu.howToPlay'),
      customLinkHandler: (e) => {
        e.preventDefault()
        setOpen(false)
        history.push('/onboarding')
      },
    },
    {
      id: EListMenu.qa,
      link: () => getLinkMenu(EListMenu.qa),
      isBlank: true,
      text: () => t('app.menu.qa'),
    },
  ]

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useOutsideClick(ref, () => {
    setOpen(false)
  })

  useEffect(() => {
    if (!isDevelopment) initialize(process.env.REACT_APP_GA)

    event({
      category: 'Custom: GA init',
      action: 'Custom: GA init',
    })

    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile: boolean = width <= 967

  usePollBlockNumber()

  return (
    <Router history={history}>
      <div>
        <ResetCSS />
        <GlobalStyle />
        {isMobile ? (
          <Header data-mobile={isMobile} ref={ref}>
            <Burger isOpen={isOpen} setOpen={setOpen} />
            <HeaderMobileRight>
              <UserMenu />
              {account ? <NFTBalance isOpen={isOpen} setOpen={setOpen} isMobile={isMobile} /> : null}
            </HeaderMobileRight>
            <BurgerMenu isMenuOpen={isOpen}>
              {!account ? <NFTBalance isOpen={isOpen} setOpen={setOpen} isMobile={isMobile} /> : null}
              <MenuList data-mobile={isMobile}>
                {listMenu.map((item) => {
                  return (
                    <MenuListItem key={item.id}>
                      {item?.isBlank ? (
                        <a
                          href={item.link()}
                          target={item?.isBlank ? '_blank' : ''}
                          rel="noreferrer"
                          id={item.id}
                          onClick={(e: any) => customLinkHandler(e, item.link())}
                        >
                          {item.text()}
                        </a>
                      ) : (
                        <a
                          href={item.link()}
                          rel="noreferrer"
                          id={item.id}
                          onClick={(e: any) => item.customLinkHandler(e)}
                        >
                          {item.text()}
                        </a>
                      )}
                    </MenuListItem>
                  )
                })}
              </MenuList>
              <LangSwitcher isMobile={isMobile} isOpen={isOpen} />
              <SocialList setOpen={setOpen} isMobile={isMobile} />
              <PlayLotteryBtn
                onClick={() => {
                  setOpen(false)
                  history.push('/')
                }}
              >
                {t('shared.playLottery')}
              </PlayLotteryBtn>
            </BurgerMenu>
          </Header>
        ) : (
          <Header>
            {/* Left */}
            <HeaderLeftPart>
              <HeaderLogo>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault()
                    setOpen(false)
                    history.push('/')
                  }}
                >
                  <LogoIcon width="40px" height="40px" />
                </a>
              </HeaderLogo>
              <MenuList>
                {listMenu.map((item) => (
                  <MenuListItem key={item.id}>
                    {item?.isBlank ? (
                      <a
                        href={item.link()}
                        target={item?.isBlank ? '_blank' : ''}
                        rel="noreferrer"
                        id={item.id}
                        onClick={(e: any) => customLinkHandler(e, item.link())}
                      >
                        {item.text()}
                      </a>
                    ) : (
                      <a
                        href={item.link()}
                        rel="noreferrer"
                        id={item.id}
                        onClick={(e: any) => item.customLinkHandler(e)}
                      >
                        {item.text()}
                      </a>
                    )}
                  </MenuListItem>
                ))}
              </MenuList>
            </HeaderLeftPart>
            {/* Right */}
            <HeaderRightPart>
              <SocialList setOpen={setOpen} isMobile={isMobile} />
              <style
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: `
                  .sc-gIvpjk {
                    color: #280D5F
                  }
                  .sc-gIvpjk h2 {
                    color: currentColor;
                  }
                  .sc-gIvpjk button {
                    color: currentColor;
                  }
                  .sc-gIvpjk svg {
                    fill: currentColor;
                  }
                  `,
                }}
              />
              <UserMenu />
              <NFTBalance isOpen={isOpen} setOpen={setOpen} isMobile={isMobile} />
              <LangSwitcher />
            </HeaderRightPart>
          </Header>
        )}

        <Switch>
          <Route exact path="/">
            <LotteryPage />
          </Route>
          <Route exact path="/onboarding">
            <Onboarding />
          </Route>
        </Switch>
        <ToastListener />
      </div>
    </Router>
  )
}

export default React.memo(withWagmi(withAuth(App)))
