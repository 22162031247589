import styled from 'styled-components'

export const Button = styled.button`
  background-image: linear-gradient(265.56deg, #922c32 -0.27%, #922c32 -0.26%, #b67122 98.59%);
  align-items: center;
  border: 0;
  border-radius: 100px;
  box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 50px !important;
  letter-spacing: 0.03em;
  color: white;

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    padding: 18px 85px;
    font-size: 20px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 62px 40px 32px;
  max-width: 90%;
  margin: 0 auto;
  background: #292d3f;
  border-radius: 26px;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 32px 40px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('/images/flags_bg_1.svg');
    background-repeat: no-repeat;
    background-position: 0 0;
  }

  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('/images/flags_bg_2.svg');
    background-repeat: no-repeat;
    background-position: 100% 0;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 40px 20px;
  }
`
