import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import ISO6391 from 'iso-639-1'
import { ReactComponent as LangArrow } from 'assets/icons/LangArrow.svg'
import { LangSupportedList } from 'utils/types'
import { capitalizeFirstLetter } from 'utils'
import { LangWrapper, LangButton, LangDropdown } from './index.styled'

type LangSwitcherProps = {
  isMobile?: boolean
  isOpen?: boolean
}

export const LangSwitcher: FC<LangSwitcherProps> = ({ isMobile, isOpen }) => {
  const { i18n } = useTranslation()
  const [curLang, setLang] = useState<string>(i18n.language)
  const [isOpenDropdown, setOpenDropdown] = useState<boolean>(false)

  const isCurrentLanguage = (lang: LangSupportedList) => {
    if (!lang) return null
    return i18n.language.includes(lang)
  }

  const langListSupport = { ...LangSupportedList }
  // const langListFuture = { ...LangFutureList }

  useEffect(() => {
    setOpenDropdown(false)
  }, [curLang])

  useEffect(() => {
    if (!isOpen) {
      setOpenDropdown(false)
    }
  }, [isOpen])

  return (
    <LangWrapper>
      <LangButton
        isMobile={isMobile}
        isOpenDropdown={isOpenDropdown}
        onClick={() => {
          setOpenDropdown(!isOpenDropdown)
        }}
      >
        <span>
          {isMobile ? 'Language: ' : null}
          {capitalizeFirstLetter(curLang?.split('-')[0])}
        </span>
        <LangArrow />
      </LangButton>
      {isOpenDropdown && (
        <LangDropdown>
          {Object.keys(langListSupport).map((item) => (
            <button
              type="button"
              key={langListSupport[item]}
              className={cn({ 'is-active': isCurrentLanguage(item as LangSupportedList) })}
              onClick={() => {
                i18n.changeLanguage(item as LangSupportedList)
                setLang(item as LangSupportedList)
              }}
            >
              {ISO6391.getNativeName(item)}
            </button>
          ))}
          {/* {Object.keys(langListFuture).map((item) => (
            <span key={langListFuture[item]}>{ISO6391.getNativeName(item)}</span>
          ))} */}
        </LangDropdown>
      )}
    </LangWrapper>
  )
}
