import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Heading, useModal, AutoRenewIcon } from '@pancakeswap/uikit'

import { LotteryStatus } from 'config/constants/types'
import { useTranslation } from 'react-i18next'
import { useGetUserLotteriesGraphData, useLottery } from 'state/lottery/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import useFreeNftAmount from 'hooks/useFreeNFTAmount'
import ClaimPrizesModal from './ClaimPrizesModal'
import useGetUnclaimedRewards, { FetchStatus } from '../hooks/useGetUnclaimedRewards'
import { useAuth } from 'hooks/useAuth'

const StyledButton = styled(Button)``

const Container = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 162px;
  margin-bottom: 60px;
  width: 100%;
  max-width: 432px;
  ${({ theme }) => theme.mediaQueries.xl} {
    margin-bottom: 120px;
  }
`
const Img = styled.img`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`
const BlockContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-top: 100px;
  padding-bottom: 32px;
  background: #292d3f;
  border-radius: 26px;

  ${Heading} {
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
  }

  * + button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0;
    width: 250px;
    height: 55px;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    background: linear-gradient(265.56deg, #922c32 -0.27%, #922c32 -0.26%, #b67122 98.59%);
    border-radius: 100px;
    margin-top: 45px;
  }
`

const CheckPrizesSection = () => {
  const { t } = useTranslation()
  const { account } = useAuth()
  const {
    isTransitioning,
    currentRound: { status, lotteryId },
  } = useLottery()
  const { fetchAllRewards, unclaimedRewards, fetchStatus } = useGetUnclaimedRewards()
  const userLotteryData = useGetUserLotteriesGraphData()
  const [hasCheckedForRewards, setHasCheckedForRewards] = useState(false)
  const [hasRewardsToClaim, setHasRewardsToClaim] = useState(false)
  const freeNftReward = useFreeNftAmount(lotteryId)
  const isFetchingRewards = fetchStatus === FetchStatus.IN_PROGRESS
  const lotteryIsNotClaimable = status === LotteryStatus.CLOSE
  const isCheckNowDisabled = !userLotteryData.account || lotteryIsNotClaimable
  const hasOnlyNftTokens = unclaimedRewards.length === 0 && freeNftReward > 0

  const [onPresentClaimModal] = useModal(
    <ClaimPrizesModal roundsToClaim={unclaimedRewards} onlyNft={hasOnlyNftTokens} freeNftReward={freeNftReward} />,
    false,
  )

  useEffect(() => {
    if (fetchStatus === FetchStatus.SUCCESS) {
      // Manage showing unclaimed rewards modal once per page load / once per lottery state change
      if ((unclaimedRewards.length > 0 || hasOnlyNftTokens) && !hasCheckedForRewards) {
        setHasRewardsToClaim(true)
        setHasCheckedForRewards(true)
        onPresentClaimModal()
      }

      if (unclaimedRewards.length === 0 && !hasCheckedForRewards) {
        setHasRewardsToClaim(false)
        setHasCheckedForRewards(true)
      }
    }
  }, [unclaimedRewards, hasCheckedForRewards, fetchStatus, onPresentClaimModal, hasOnlyNftTokens])

  useEffect(() => {
    // Clear local state on account change, or when lottery isTransitioning state changes
    setHasRewardsToClaim(false)
    setHasCheckedForRewards(false)
  }, [account, isTransitioning])

  const getBody = () => {
    if (!account) {
      return (
        <>
          <Heading textAlign="center" color="#F4EEFF">
            {t('views.lottery.components.checkPrizes.text_context', { context: 'connect' })}
          </Heading>
          <Heading textAlign="center" color="#F4EEFF">
            {t('views.lottery.components.checkPrizes.text_context', { context: 'connect-subtext' })}
          </Heading>
          <ConnectWalletButton />
        </>
      )
    }
    if (hasCheckedForRewards && !hasRewardsToClaim) {
      return (
        <>
          <Heading textAlign="center" color="#F4EEFF">
            {t('views.lottery.components.checkPrizes.text_context', { context: 'checkedForRewards' })}...
          </Heading>
          <Heading textAlign="center" color="#F4EEFF">
            {t('views.lottery.components.checkPrizes.text_context', { context: 'checkedForRewards-subtext' })}
          </Heading>
        </>
      )
    }
    if (hasCheckedForRewards && hasRewardsToClaim) {
      return (
        <>
          <Heading textAlign="center" color="#F4EEFF">
            {t('views.lottery.components.checkPrizes.text_context', { context: 'rewardsToClaim' })}
          </Heading>
          <Heading textAlign="center" color="#F4EEFF">
            {t('views.lottery.components.checkPrizes.text_context', { context: 'rewardsToClaim-subtext' })}
          </Heading>
        </>
      )
    }
    const checkNowText = () => {
      if (lotteryIsNotClaimable) {
        return `${t('views.lottery.components.checkPrizes.checkNow_context', { context: 'lotteryIsNotClaimable' })}...`
      }
      if (isFetchingRewards) {
        return t('views.lottery.components.checkPrizes.checkNow_context', { context: 'isFetchingRewards' })
      }
      return t('views.lottery.components.checkPrizes.checkNow_context')
    }
    return (
      <>
        <Heading textAlign="center" color="#F4EEFF" mb="24px" scale="xl">
          {t('views.lottery.components.checkPrizes.isWinner')}
        </Heading>
        <StyledButton
          disabled={isCheckNowDisabled}
          onClick={fetchAllRewards}
          isLoading={isFetchingRewards}
          endIcon={isFetchingRewards ? <AutoRenewIcon color="currentColor" spin /> : null}
        >
          {checkNowText()}
        </StyledButton>
      </>
    )
  }

  return (
    <Container>
      <Img src="/images/prize.png" width="216px" alt="" />
      <BlockContainer>{getBody()}</BlockContainer>
    </Container>
  )
}

export default CheckPrizesSection
