import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, ExpandableLabel, CardFooter } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'
import { LotteryRound } from 'state/types'
import FooterExpanded from './FooterExpanded'

interface PreviousRoundCardFooterProps {
  lotteryNodeData: LotteryRound
  lotteryId: string
}

const StyledCardFooter = styled(CardFooter)`
  background: #292d3f;
`

const StyledExpandableLabel = styled(ExpandableLabel)`
  background: #40455c;
  border-radius: 26px;
`

const PreviousRoundCardFooter: React.FC<PreviousRoundCardFooterProps> = ({ lotteryNodeData, lotteryId }) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <StyledCardFooter p="0">
      {isExpanded && <FooterExpanded lotteryNodeData={lotteryNodeData} lotteryId={lotteryId} />}
      <Flex p="8px 24px" alignItems="center" justifyContent="center">
        <StyledExpandableLabel expanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded
            ? t('views.lottery.components.previousRoundCard.label_context', { context: 'hide' })
            : t('views.lottery.components.previousRoundCard.label_context', { context: 'details' })}
        </StyledExpandableLabel>
      </Flex>
    </StyledCardFooter>
  )
}

export default PreviousRoundCardFooter
