import { LotteryStatus } from 'config/constants/types'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import i18n from 'languages/i18n'

interface LotteryEvent {
  nextEventTime: number
  postCountdownText?: string
  preCountdownText?: string
}

const useGetNextLotteryEvent = (endTime: number, status: LotteryStatus): LotteryEvent => {
  const { t } = useTranslation()
  const vrfRequestTime = 180 // 3 mins
  const secondsBetweenRounds = 300 // 5 mins
  const transactionResolvingBuffer = 30 // Delay countdown by 30s to ensure contract transactions have been calculated and broadcast
  const [nextEvent, setNextEvent] = useState({ nextEventTime: null, preCountdownText: null, postCountdownText: null })
  const isEnded = +new Date() / 1000 > Number(endTime)

  useEffect(() => {
    // Current lottery is active
    if (status === LotteryStatus.OPEN && !isEnded) {
      setNextEvent({
        nextEventTime: endTime + transactionResolvingBuffer,
        preCountdownText: null,
        postCountdownText: i18n.t('views.lottery.hooks.getNextLotteryEvent.lottery_context', { context: status }),
      })
    }
    // Current lottery has finished but not yet claimable
    if (status === LotteryStatus.CLOSE && isEnded) {
      setNextEvent({
        nextEventTime: endTime + transactionResolvingBuffer + vrfRequestTime,
        preCountdownText: i18n.t('views.lottery.hooks.getNextLotteryEvent.lottery_context', { context: status }),
        postCountdownText: null,
      })
    }
    // Current lottery claimable. Next lottery has not yet started
    if ((status === LotteryStatus.CLAIMABLE && isEnded) || (status === LotteryStatus.OPEN && isEnded)) {
      setNextEvent({
        nextEventTime: endTime + transactionResolvingBuffer + secondsBetweenRounds,
        preCountdownText: i18n.t('views.lottery.hooks.getNextLotteryEvent.lottery_context', { context: status }),
        postCountdownText: null,
      })
    }
  }, [status, endTime, t, isEnded])

  return nextEvent
}

export default useGetNextLotteryEvent
