import styled from 'styled-components'
import { Link } from '@pancakeswap/uikit'

export const DecorateUnderline = styled.div`
  display: flex;
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #a5accf;
  }
`

export const CLink = styled(Link)`
  display: inline-flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1fc7d4;
  &:hover {
    text-decoration: none;
  }
`

export const SubTitle = styled.div`
  font-size: 20px;
  line-height: 22px;
`

export const SectionTitle = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 40px;
  line-height: 44px;
`

export const Block = styled.div`
  p + p {
    margin-top: 16px;
  }
`

export const HowToPlaySection = styled.section`
  padding-top: 80px;
  color: currentColor;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  img {
    display: inline-flex;
    margin: 16px auto;
  }

  h3 {
    font-weight: 400;
    font-size: 30px;
    line-height: 46px;
  }
  h3 + p {
    margin-top: 16px;
  }

  * + ${SubTitle} {
    margin-top: 40px;
  }
  * + ${CLink} {
    margin-top: 50px;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    padding-top: 80px;

    * + ${CLink} {
      margin-top: 28px;
    }
  }
`
