import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { fetchLeaderboardAllTime } from '.'
import { State } from '../types'

export const useGetLeaderboardAllTimeGraphData = () => {
  const { allTime: leaderboardAllTimeData } = useSelector((state: State) => state.leaderboard, shallowEqual)
  return { leaderboardAllTimeData }
}

export const useFetchLeaderboard = () => {
  const { lotteryId } = useSelector((state: State) => state.lottery.currentRound)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchLeaderboardAllTime())
  }, [dispatch, lotteryId])
}
