import { useEffect, useState } from 'react'

export const usePricePLT = (): number => {
  const [price, setPrice] = useState(1.6)

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch('https://5rzudl2i38.execute-api.eu-central-1.amazonaws.com/default/PriceProxy')
        // const json = await res.json()

        // setPrice(json.data.price)
      } catch (e) {
        // console.log(e)
      }
    }

    fetchData()
  }, [])

  return price
}
