import React, { useState, useEffect, FC } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CheckIcon } from 'assets/icons/help/check.svg'
import { ReactComponent as ArrowDownIcon } from 'assets/icons/help/arrow-down.svg'
import { ReactComponent as PLTIcon } from 'assets/icons/help/pltIcon.svg'
import { ReactComponent as NFTIcon } from 'assets/icons/help/nftIcon.svg'
import { Link } from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'
import { addToMetamask } from 'components/Menu/AddMetamask'
import tokens from 'config/constants/tokens'
import { getFullDisplayBalance, formatBigNumber } from 'utils/formatBalance'
import useTokenBalance, { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import { LOW_BNB_BALANCE } from 'components/Menu/UserMenu/WalletModal'
import useNft from 'hooks/useNft'
import { LangSupportedList } from 'utils/types'
import { getFreeNftAddress, getPltNftAddress } from 'utils/addressHelpers'
import { nFormatter } from 'utils/nFormatter.helper'
import YouTube, { YouTubeProps } from 'react-youtube'
import { Footer } from 'components/Footer'
import history from '../../routerHistory'
import {
  AssideBlock,
  ContentBlock,
  InlineButton,
  ItemContent,
  ItemHeader,
  OpenTrigger,
  PageLayout,
  StepBtnContainer,
  StepLabel,
  StepLabelContainer,
  StepLabelDesc,
  StepLabelTitle,
  StepList,
  StepListItem,
  StepStatus,
  Title,
  CustomBtnFlexContainer,
  HeaderDropdownButton,
  ItemContentText,
  BasicInlineButton,
  ConnectWalletButton,
  NoteText,
} from './styled'
import { HowToPlay } from './howToPlay'
import CircleLoader from '../../components/Loader/CircleLoader'
import { useAuth } from 'hooks/useAuth'

export const Onboarding: FC = () => {
  const { account, chainId, ethersProvider: library } = useAuth()
  const { balance: bnbBalance, fetchStatus: bnbLoadingStatus } = useGetBnbBalance()
  const { balance: pltBalance, fetchStatus: pltLoadingStatus } = useTokenBalance(tokens.plt.address)
  const { balance: nftBalance } = useNft()
  const { t, i18n } = useTranslation()
  const { toastCopied } = useToast()
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [activeTab, setActiveTab] = useState(1)
  const [supportChainId, setSupportChainId] = useState<boolean>(false)
  const [step1, setStep1] = useState(true)
  const [step2, setStep2] = useState(true)
  const [step3, setStep3] = useState(true)
  const [balanceLoadingcounter, setBalanceLoadingcounter] = useState<number>(0)
  const hasLowBnbBalance = bnbLoadingStatus === FetchStatus.SUCCESS && bnbBalance.lte(LOW_BNB_BALANCE)
  const isMobile: boolean = width <= 967
  const isTablet: boolean = width <= 1199

  const isCurrentLanguage = (lang: LangSupportedList) => i18n.language.includes(lang)
  const docsLink = (): string => {
    let link = ''
    if (isCurrentLanguage(LangSupportedList.en)) {
      link = 'https://forms.gle/AYiHsL86oD3BbrTt9'
    }
    if (isCurrentLanguage(LangSupportedList.es)) {
      link = 'https://forms.gle/AYiHsL86oD3BbrTt9'
    }
    if (isCurrentLanguage(LangSupportedList.ja)) {
      // TODO
      link = ''
    }
    return link
  }
  const isSupportChainId = () =>
    chainId === parseInt(process.env.REACT_APP_CHAIN_ID, 10) ? setSupportChainId(true) : setSupportChainId(false)

  const toggleTab = (index) => {
    setActiveTab(index)
  }
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  const routeChange = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    history.push('/')
  }
  const connectWalletHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const walletBtnOriginal = document.getElementById('connect-wallet')
    walletBtnOriginal.click()
  }

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    event.target.pauseVideo()
  }

  const opts: YouTubeProps['opts'] = {
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    isSupportChainId()
    // if (library?.provider?.isMetaMask || !!library?.provider?.bnbSign) {
    // if (library?.provider?.isMetaMask) {
    //   toggleTab(3)
    // } else if (library?.provider?.wc) {
    //   const name = library?.provider?.wc._peerMeta.name

    //   if (name.includes('MetaMask')) {
    //     toggleTab(2)
    //   } else if (name.includes('Trust Wallet')) {
    //     toggleTab(1)
    //   }
    // }
  }, [chainId])

  useEffect(() => {
    if (balanceLoadingcounter === 0 && account) {
      setStep1(Boolean(account))

      const isCheck =
        Boolean(account) &&
        ((pltLoadingStatus === FetchStatus.SUCCESS && getFullDisplayBalance(pltBalance, 18, 3) !== '0.000') ||
          nftBalance > 0) &&
        bnbLoadingStatus === FetchStatus.SUCCESS &&
        !hasLowBnbBalance

      setStep2(isCheck)
      if (isCheck) {
        setStep3(!isCheck)
      }
    }

    if (
      ((pltLoadingStatus === FetchStatus.SUCCESS && getFullDisplayBalance(pltBalance, 18, 3) !== '0.000') ||
        nftBalance > 0) &&
      bnbLoadingStatus === FetchStatus.SUCCESS &&
      !hasLowBnbBalance
    ) {
      setBalanceLoadingcounter(balanceLoadingcounter + 1)
    }

    if (!account) {
      setStep1(false)
      setStep2(!account)
      setStep3(!account)
    }
  }, [account, pltBalance, nftBalance, hasLowBnbBalance])

  return (
    <>
      <PageLayout>
        <Title>{t('views.onboarding.title')}</Title>
        <StepList>
          {/* step1 */}
          <StepListItem className={cn({ 'is-open': !step1 })}>
            <ItemHeader>
              <StepLabelContainer>
                <StepStatus className={cn({ 'is-done': account && supportChainId })}>
                  <CheckIcon />
                </StepStatus>
                <StepLabel>
                  <StepLabelTitle>{t('views.onboarding.step')} 1</StepLabelTitle>
                  <StepLabelDesc>{t('views.onboarding.stepTitle_context', { context: 1 })}</StepLabelDesc>
                </StepLabel>
                {isTablet && (
                  <OpenTrigger className={cn({ 'is-open': !step1 })} onClick={() => setStep1(!step1)}>
                    <ArrowDownIcon />
                  </OpenTrigger>
                )}
              </StepLabelContainer>
              <StepBtnContainer>
                {!account && (
                  <CustomBtnFlexContainer>
                    <ConnectWalletButton onClick={connectWalletHandler}>
                      {t('shared.connectWallet')}
                    </ConnectWalletButton>
                  </CustomBtnFlexContainer>
                )}
                {account && (
                  <CustomBtnFlexContainer>
                    <HeaderDropdownButton
                      disabled={!account}
                      color={account ? '#01867F' : '#A24949'}
                      width="211"
                      target="_blank"
                    >
                      {t('views.onboarding.statusBtn_context', { context: 'walletConnected' })}
                    </HeaderDropdownButton>
                    <HeaderDropdownButton
                      disabled={!supportChainId}
                      color={supportChainId ? '#01867F' : '#A24949'}
                      width="211"
                      target="_blank"
                    >
                      {t('views.onboarding.statusBtn_context', { context: 'bscNetwork' })}
                    </HeaderDropdownButton>
                  </CustomBtnFlexContainer>
                )}
                {!isTablet && (
                  <OpenTrigger className={cn({ 'is-open': !step1 })} onClick={() => setStep1(!step1)}>
                    <ArrowDownIcon />
                  </OpenTrigger>
                )}
              </StepBtnContainer>
            </ItemHeader>
            <ItemContent>
              <ContentBlock>
                <ItemContentText>{t('views.onboarding.step1.text1')}</ItemContentText>
                <div className="tab-container">
                  <button
                    type="button"
                    onClick={() => {
                      toggleTab(1)
                    }}
                    className={activeTab === 1 ? 'tab-item-active' : 'tab-item'}
                  >
                    <span className={activeTab === 1 ? 'tab-item-text-active' : 'tab-item-text'}>
                      {t('views.onboarding.step1.tabs.title1')}
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      toggleTab(2)
                    }}
                    className={activeTab === 2 ? 'tab-item-active' : 'tab-item'}
                  >
                    <span className={activeTab === 2 ? 'tab-item-text-active' : 'tab-item-text'}>
                      {t('views.onboarding.step1.tabs.title2')}
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      toggleTab(3)
                    }}
                    className={activeTab === 3 ? 'tab-item-active' : 'tab-item'}
                  >
                    <span className={activeTab === 3 ? 'tab-item-text-active' : 'tab-item-text'}>
                      {t('views.onboarding.step1.tabs.title3')}
                    </span>
                  </button>
                </div>

                <div className="tab-content-container">
                  {/* tab1 */}
                  <div className={activeTab === 1 ? 'tab-active-content' : 'tab-content'}>
                    <div className="create-wallet-list-wrapper">
                      <ol className="create-wallet-list">
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab1.list.item_context', { context: 1 })}{' '}
                            <Link
                              href="https://trustwallet.com/"
                              className="help-sup-link"
                              target="_blank"
                              rel="noreferrer"
                            >
                              trustwallet.com.
                            </Link>
                          </span>
                          <Link href="https://trustwallet.com/" target="_blank">
                            <InlineButton width="220" fontSize="14" target="_blank">
                              {t('views.onboarding.step1.tabs.tab1.btn')}
                            </InlineButton>
                          </Link>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab1.list.item_context', { context: 2 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab1.list.item_context', { context: 3 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab1.list.item_context', { context: 4 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab1.list.item_context', { context: 51 })}{' '}
                            <Link href="https://pltlotto.com" className="help-sup-link" target="_blank">
                              pltlotto.com
                            </Link>{' '}
                            {t('views.onboarding.step1.tabs.tab1.list.item_context', { context: 52 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab1.list.item_context', { context: 6 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab1.list.item_context', { context: 7 })}
                          </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  {/* tab2 */}
                  <div className={activeTab === 2 ? 'tab-active-content' : 'tab-content'}>
                    <div className="create-wallet-list-wrapper">
                      <ol className="create-wallet-list">
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab2.list.item_context', { context: 1 })}{' '}
                            <Link href="https://metamask.io" className="help-sup-link" target="_blank">
                              metamask.io.
                            </Link>
                          </span>
                          <Link href="https://metamask.io/" target="_blank">
                            <InlineButton width="220" fontSize="14" target="_blank">
                              {t('views.onboarding.step1.tabs.tab2.btn2')}
                            </InlineButton>
                          </Link>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab2.list.item_context', { context: 2 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab2.list.item_context', { context: 3 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab2.list.item_context', { context: 4 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab2.list.item_context', { context: 5 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab2.list.item_context', { context: 6 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab2.list.item_context', { context: 7 })}
                          </span>
                        </li>
                        <div className="marginDiv">
                          <div className="section-split-help">
                            <div className="auto">
                              <div className="translateY">
                                <Link href="https://metamask.io" target="_blank">
                                  <InlineButton fontSize="14" target="_blank">
                                    {t('views.onboarding.step1.tabs.tab2.btn')}
                                  </InlineButton>
                                </Link>
                              </div>
                              {!isMobile && (
                                <div className="cardlabelAuto">
                                  {t('views.onboarding.step1.tabs.tab2.manually.auto')}
                                </div>
                              )}
                            </div>
                            <div className="manually">
                              <div className="card-label">
                                {t('views.onboarding.step1.tabs.tab2.manually.manually')}
                              </div>
                              <p className="section-text-help">
                                {t('views.onboarding.step1.tabs.tab2.manually.networkName')}
                                <span className="section-text-help-orange"> Binance Smart Chain</span>
                              </p>
                              <p className="section-text-help">
                                {t('views.onboarding.step1.tabs.tab2.manually.newRpcUrl')}
                                <span className="section-text-help-orange">https://bsc-dataseed.binance.org</span>
                              </p>
                              <p className="section-text-help">
                                {t('views.onboarding.step1.tabs.tab2.manually.chainId')}
                                <span className="section-text-help-orange"> 56</span>
                              </p>
                              <p className="section-text-help">
                                {t('views.onboarding.step1.tabs.tab2.manually.currencySymbol')}
                                <span className="section-text-help-orange"> BNB</span>
                              </p>
                              <p className="section-text-help">
                                {t('views.onboarding.step1.tabs.tab2.manually.explorerUrl')}
                                <span className="section-text-help-orange"> http://bscscan.com</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <li className="create-wallet-numeric-item marginDiv">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab2.list.item_context', { context: 8 })}
                          </span>
                          <button
                            type="button"
                            className="help-page-copytext-button"
                            onClick={() => {
                              if (!isMobile) {
                                toastCopied('', 'CONTRACT ADDRESS copied!')
                              }
                              if (!isMobile) {
                                toastCopied(
                                  '',
                                  `${t('shared.contractAddress', {
                                    value: `${t('components.menu.userMenu.copyAddress.copied').toLowerCase()}!`,
                                  })}`,
                                )
                              }
                              navigator.clipboard.writeText(getPltNftAddress())
                            }}
                          >
                            <div className="flexRow">
                              <p className="section-text-help-crypt">
                                {t('shared.contractAddress', { value: `${getPltNftAddress()}` })}
                              </p>
                              <img
                                className="help-page-icon-image"
                                src="images/help/copyIcon.svg"
                                height="16px"
                                width="16px"
                                alt="copy"
                              />
                            </div>
                          </button>
                          <InlineButton width="220" fontSize="14" onClick={addToMetamask} target="_blank">
                            {t('views.onboarding.step1.tabs.tab2.btn3')}
                          </InlineButton>
                        </li>

                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab2.list.item_context', { context: 9 })}
                          </span>
                          <div>
                            <button
                              type="button"
                              className="help-page-copytext-button"
                              onClick={() => {
                                if (!isMobile) {
                                  toastCopied(
                                    '',
                                    `${t('shared.contractAddress', {
                                      value: `${t('components.menu.userMenu.copyAddress.copied').toLowerCase()}!`,
                                    })}`,
                                  )
                                }
                                navigator.clipboard.writeText(getFreeNftAddress())
                              }}
                            >
                              <div className="flexRow">
                                <p className="section-text-help-crypt">
                                  {t('shared.contractAddress', { value: `${getFreeNftAddress()}` })}
                                </p>
                                <img
                                  className="help-page-icon-image"
                                  src="images/help/copyIcon.svg"
                                  height="16px"
                                  width="16px"
                                  alt="copy"
                                />
                              </div>
                            </button>
                          </div>

                          <div>
                            <button
                              type="button"
                              className="help-page-copytext-button"
                              onClick={() => {
                                if (!isMobile) {
                                  toastCopied('', 'COLLECTIBLE ID copied!')
                                }
                                if (!isMobile) {
                                  toastCopied(
                                    '',
                                    `${t('shared.contractAddress', {
                                      value: `${t('components.menu.userMenu.copyAddress.copied').toLowerCase()}!`,
                                    })}`,
                                  )
                                }
                                navigator.clipboard.writeText('1')
                              }}
                            >
                              <div className="flexRow">
                                <p className="section-text-help-crypt">{t('shared.collectibleID')}</p>
                                <img
                                  className="help-page-icon-image"
                                  src="images/help/copyIcon.svg"
                                  height="16px"
                                  width="16px"
                                  alt="copy"
                                />
                              </div>
                            </button>
                          </div>
                        </li>

                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab2.list.item_context', { context: 10 })}
                          </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  {/* tab3 */}
                  <div className={activeTab === 3 ? 'tab-active-content' : 'tab-content'}>
                    <div className="create-wallet-list-wrapper">
                      <ol className="create-wallet-list">
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab3.list.item_context', { context: 1 })}{' '}
                            <Link href="https://metamask.io" className="help-sup-link" target="_blank">
                              metamask.io.
                            </Link>
                          </span>
                          <Link href="https://metamask.io" target="_blank">
                            <InlineButton width="220" fontSize="14" target="_blank">
                              {t('views.onboarding.step1.tabs.tab3.btn2')}
                            </InlineButton>
                          </Link>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab3.list.item_context', { context: 2 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab3.list.item_context', { context: 3 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab3.list.item_context', { context: 4 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab3.list.item_context', { context: 5 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab3.list.item_context', { context: 6 })}
                          </span>
                        </li>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab3.list.item_context', { context: 7 })}
                          </span>
                        </li>
                        <div className="marginDiv">
                          <div className="section-split-help">
                            <div className="auto">
                              <div className="translateY">
                                <Link href="https://metamask.io" target="_blank">
                                  <InlineButton fontSize="14" target="_blank">
                                    {t('views.onboarding.step1.tabs.tab3.btn')}
                                  </InlineButton>
                                </Link>
                              </div>
                              {!isMobile && (
                                <div className="cardlabelAuto">
                                  {t('views.onboarding.step1.tabs.tab3.manually.auto')}
                                </div>
                              )}
                            </div>
                            <div className="manually">
                              <div className="card-label">
                                {t('views.onboarding.step1.tabs.tab3.manually.manually')}
                              </div>
                              <p className="section-text-help">
                                {t('views.onboarding.step1.tabs.tab3.manually.networkName')}
                                <span className="section-text-help-orange"> Binance Smart Chain</span>
                              </p>
                              <p className="section-text-help">
                                {t('views.onboarding.step1.tabs.tab3.manually.newRpcUrl')}
                                <span className="section-text-help-orange">https://bsc-dataseed.binance.org</span>
                              </p>
                              <p className="section-text-help">
                                {t('views.onboarding.step1.tabs.tab3.manually.chainId')}
                                <span className="section-text-help-orange"> 56</span>
                              </p>
                              <p className="section-text-help">
                                {t('views.onboarding.step1.tabs.tab3.manually.currencySymbol')}
                                <span className="section-text-help-orange"> BNB</span>
                              </p>
                              <p className="section-text-help">
                                {t('views.onboarding.step1.tabs.tab3.manually.explorerUrl')}
                                <span className="section-text-help-orange"> http://bscscan.com</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab3.list.item_context', { context: 8 })}
                          </span>
                          <div>
                            <button
                              type="button"
                              className="help-page-copytext-button"
                              onClick={() => {
                                if (!isMobile) {
                                  toastCopied(
                                    '',
                                    `${t('shared.contractAddress', {
                                      value: `${t('components.menu.userMenu.copyAddress.copied').toLowerCase()}!`,
                                    })}`,
                                  )
                                }
                                navigator.clipboard.writeText(getPltNftAddress())
                              }}
                            >
                              <div className="flexRow">
                                <p className="section-text-help-crypt">
                                  {t('shared.contractAddress', { value: `${getPltNftAddress()}` })}
                                </p>
                                <img
                                  className="help-page-icon-image"
                                  src="images/help/copyIcon.svg"
                                  height="16px"
                                  width="16px"
                                  alt="copy"
                                />
                              </div>
                            </button>
                          </div>
                          <InlineButton width="220" fontSize="14" onClick={addToMetamask} target="_blank">
                            {t('views.onboarding.step1.tabs.tab3.btn3')}
                          </InlineButton>
                        </li>

                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab3.list.item_context', { context: 91 })}{' '}
                            <Link href="https://pltlotto.com" className="help-sup-link" target="_blank">
                              pltlotto.com
                            </Link>{' '}
                            {t('views.onboarding.step1.tabs.tab3.list.item_context', { context: 92 })}
                          </span>
                        </li>

                        <li className="create-wallet-numeric-item">
                          <span className="section-text-help">
                            {t('views.onboarding.step1.tabs.tab3.list.item_context', { context: 10 })}
                          </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </ContentBlock>
              <AssideBlock>
                {activeTab === 1 && <YouTube videoId="HGbO4cx-7KA" opts={opts} onReady={onPlayerReady} />}
                {activeTab === 2 && <YouTube videoId="_5ZXb0Ll_XI" opts={opts} onReady={onPlayerReady} />}
                {activeTab === 3 && <YouTube videoId="5FfkqIG1YEc" opts={opts} onReady={onPlayerReady} />}
              </AssideBlock>
            </ItemContent>
          </StepListItem>
          {/* step2 */}
          <StepListItem className={cn({ 'is-open': !step2 })}>
            <ItemHeader>
              <StepLabelContainer>
                <StepStatus
                  className={cn({
                    'is-done':
                      account &&
                      ((pltLoadingStatus === FetchStatus.SUCCESS &&
                        getFullDisplayBalance(pltBalance, 18, 3) !== '0.000') ||
                        nftBalance > 0) &&
                      bnbLoadingStatus === FetchStatus.SUCCESS &&
                      !hasLowBnbBalance,
                  })}
                >
                  <CheckIcon />
                </StepStatus>
                <StepLabel>
                  <StepLabelTitle>{t('views.onboarding.step')} 2</StepLabelTitle>
                  <StepLabelDesc>{t('views.onboarding.stepTitle_context', { context: 2 })}</StepLabelDesc>
                </StepLabel>
                {isTablet && (
                  <OpenTrigger className={cn({ 'is-open': !step2 })} onClick={() => setStep2(!step2)}>
                    <ArrowDownIcon />
                  </OpenTrigger>
                )}
              </StepLabelContainer>
              <StepBtnContainer>
                {account && (
                  <CustomBtnFlexContainer>
                    <HeaderDropdownButton
                      disabled={pltLoadingStatus !== FetchStatus.SUCCESS}
                      color={
                        account &&
                        pltLoadingStatus === FetchStatus.SUCCESS &&
                        getFullDisplayBalance(pltBalance, 18, 3) !== '0.000'
                          ? '#01867F'
                          : '#A24949'
                      }
                      target="_blank"
                    >
                      {pltLoadingStatus === FetchStatus.SUCCESS ? (
                        <>
                          <PLTIcon />

                          {t('views.onboarding.statusBtn_context', {
                            context: 'PLTBalance',
                            amount:
                              account && pltLoadingStatus === FetchStatus.SUCCESS
                                ? nFormatter(getFullDisplayBalance(pltBalance, 18, 3), 2)
                                : 0,
                          })}
                        </>
                      ) : (
                        <CircleLoader />
                      )}
                    </HeaderDropdownButton>
                    <HeaderDropdownButton
                      disabled={bnbLoadingStatus !== FetchStatus.SUCCESS}
                      color={
                        account && bnbLoadingStatus === FetchStatus.SUCCESS && !hasLowBnbBalance ? '#01867F' : '#A24949'
                      }
                      target="_blank"
                    >
                      {bnbLoadingStatus === FetchStatus.SUCCESS ? (
                        t('views.onboarding.statusBtn_context', {
                          context: 'BNBBalance',
                          amount: account ? formatBigNumber(bnbBalance, 2) : 0,
                        })
                      ) : (
                        <CircleLoader />
                      )}
                    </HeaderDropdownButton>
                    <HeaderDropdownButton
                      disabled={typeof nftBalance !== 'string'}
                      color={account && nftBalance > 0 ? '#01867F' : '#A24949'}
                      target="_blank"
                    >
                      {typeof nftBalance === 'string' ? (
                        <>
                          <NFTIcon />
                          {t('views.onboarding.statusBtn_context', {
                            context: 'NFTTickets',
                            amount: account ? nftBalance : 0,
                          })}
                        </>
                      ) : (
                        <CircleLoader />
                      )}
                    </HeaderDropdownButton>

                    {!isTablet && (
                      <HeaderDropdownButton disabled style={{ visibility: 'hidden', opacity: 0 }}>
                        -
                      </HeaderDropdownButton>
                    )}
                  </CustomBtnFlexContainer>
                )}
                {!isTablet && (
                  <OpenTrigger className={cn({ 'is-open': !step2 })} onClick={() => setStep2(!step2)}>
                    <ArrowDownIcon />
                  </OpenTrigger>
                )}
              </StepBtnContainer>
            </ItemHeader>
            <ItemContent>
              <ContentBlock>
                <p className="section-text-help">{t('views.onboarding.step2.text1')}</p>
                <br />
                <p className="section-text-help">{t('views.onboarding.step2.text2')}</p>
                <br />
                <ol className="create-wallet-list">
                  <li className="create-wallet-numeric-item">
                    <span className="section-text-help">
                      {t('views.onboarding.step2.list.item_context', { context: 1 })}
                    </span>
                  </li>
                  <li className="create-wallet-numeric-item">
                    <span className="section-text-help">
                      {t('views.onboarding.step2.list.item_context', { context: 2 })}
                    </span>
                  </li>
                </ol>
                <br />
                <p>
                  {t('views.onboarding.step2.text3')}{' '}
                  <Link href="https://t.me/pltlottochat" className="help-sup-link" target="_blank" rel="noreferrer">
                    {t('views.onboarding.step2.supportTeam')}
                  </Link>
                  . {t('views.onboarding.step2.text4')}{' '}
                  <Link
                    href="https://poollotto-finance.gitbook.io/pltlotto_q_and_a/about-plt-token/where-can-i-buy-plt-token"
                    className="help-sup-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('views.onboarding.step2.getPltToken')}
                  </Link>{' '}
                  .
                </p>
                <div className="center">
                  <Link href={docsLink()} target="_blank">
                    <InlineButton fontSize="14" target="_blank">
                      {t('views.onboarding.step2.btn')}
                    </InlineButton>
                  </Link>
                </div>
              </ContentBlock>
              <AssideBlock>
                <img src="images/help/bnb.png" alt="yt" />
              </AssideBlock>
            </ItemContent>
          </StepListItem>
          {!(
            account &&
            ((pltLoadingStatus === FetchStatus.SUCCESS && getFullDisplayBalance(pltBalance, 18, 3) !== '0.000') ||
              nftBalance > 0) &&
            bnbLoadingStatus === FetchStatus.SUCCESS &&
            !hasLowBnbBalance
          ) && (
            <NoteText>
              <span>NOTE: You don’t have NFT tickets or PLT tokens. To participate the lottery</span>{' '}
              <span style={{ color: '#e5b074' }}>Get&nbsp;PLT&nbsp;token</span>
            </NoteText>
          )}

          {/* step3 */}
          <StepListItem className={cn({ 'is-open': (!step2 && !step1) || !step3 })}>
            <ItemHeader>
              <StepLabelContainer>
                <StepStatus
                  className={cn({
                    'is-done':
                      account &&
                      ((pltLoadingStatus === FetchStatus.SUCCESS &&
                        getFullDisplayBalance(pltBalance, 18, 3) !== '0.000') ||
                        nftBalance > 0) &&
                      !hasLowBnbBalance,
                  })}
                >
                  <CheckIcon />
                </StepStatus>
                <StepLabel>
                  <StepLabelTitle>{t('views.onboarding.step')} 3</StepLabelTitle>
                  <StepLabelDesc>{t('views.onboarding.stepTitle_context', { context: 3 })}</StepLabelDesc>
                </StepLabel>
                {isTablet && (
                  <OpenTrigger className={cn({ 'is-open': !step3 })} onClick={() => setStep3(!step3)}>
                    <ArrowDownIcon />
                  </OpenTrigger>
                )}
              </StepLabelContainer>
              <StepBtnContainer>
                {account && (
                  <CustomBtnFlexContainer>
                    <BasicInlineButton width="220" fontSize="14" onClick={routeChange} target="_blank" id="buy-tickets">
                      {t('shared.buyTickets')}
                    </BasicInlineButton>
                  </CustomBtnFlexContainer>
                )}
                {!isTablet && (
                  <OpenTrigger className={cn({ 'is-open': !step3 })} onClick={() => setStep3(!step3)}>
                    <ArrowDownIcon />
                  </OpenTrigger>
                )}
              </StepBtnContainer>
            </ItemHeader>
            <ItemContent>
              <ContentBlock>
                <div className="dropdown-content-show-center">
                  <p className="section-text-help">{t('views.onboarding.step3.text1')}</p>
                  <br />
                  <ol className="create-wallet-list">
                    <li className="create-wallet-numeric-item">
                      <span className="section-text-help">
                        {t('views.onboarding.step3.list.item_context', { context: 11 })}{' '}
                        <span className="section-text-help-orange">
                          {t('views.onboarding.step3.list.item_context', { context: 12 })}
                        </span>
                        .
                      </span>
                    </li>
                    <li className="create-wallet-numeric-item">
                      <span className="section-text-help">
                        {t('views.onboarding.step3.list.item_context', { context: 2 })}
                      </span>
                    </li>
                    <li className="create-wallet-numeric-item">
                      <span className="section-text-help">
                        {t('views.onboarding.step3.list.item_context', { context: 3 })}{' '}
                        <a
                          href="https://poollotto-finance.gitbook.io/pltlotto_q_and_a/about-plt-lotto/what-is-the-plt-lotto-nft-ticket"
                          className="help-sup-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('views.onboarding.step3.whatNftTicket')}
                        </a>
                      </span>
                    </li>
                    <li className="create-wallet-numeric-item">
                      <span className="section-text-help">
                        {t('views.onboarding.step3.list.item_context', { context: 41 })}{' '}
                        <span className="section-text-help-orange"> {t('shared.buyInstantly')}</span>{' '}
                        {t('views.onboarding.step3.list.item_context', { context: 42 })}{' '}
                        <span className="section-text-help-orange">{t('shared.viewEditNumbers')}</span>{' '}
                        {t('views.onboarding.step3.list.item_context', { context: 43 })}
                      </span>
                    </li>
                    <li className="create-wallet-numeric-item">
                      <span className="section-text-help">
                        {t('views.onboarding.step3.list.item_context', { context: 5 })}
                      </span>
                    </li>
                  </ol>
                </div>
              </ContentBlock>
              <AssideBlock>
                <YouTube videoId="jj0vCFr6mYE" opts={opts} onReady={onPlayerReady} />
              </AssideBlock>
            </ItemContent>
          </StepListItem>
        </StepList>
        <HowToPlay />

        <Footer />
      </PageLayout>
    </>
  )
}
