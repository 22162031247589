import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LeaderboardAllTimeGraphEntity } from 'state/types'
import { usePricePLT } from 'views/Lottery/hooks/usePricePLT'
import { publicKeyShortenerHelper } from 'utils/publicKeyShortener.helper'
import { formatNumber, getBalanceAmount } from 'utils/formatBalance'
import { ReactComponent as NFTIcon } from 'assets/icons/NFT.svg'
import cn from 'classnames'
import { BigNumber } from 'bignumber.js'
import styled from 'styled-components'
import CircleLoader from '../Loader/CircleLoader'
import { useAuth } from 'hooks/useAuth'

export type DataType = {
  id: number
  walletAddress: {
    address: string
    isGold?: boolean
    isSivler?: boolean
    isBronze?: boolean
    isMe?: boolean
  }
  gamePlayed: number
  totalTickets: number
  totalWin: {
    usd?: string
    nft?: number
    plt: string
  }
}
type LeaderboardTableProps = {
  data: LeaderboardAllTimeGraphEntity[]
  isMobile?: boolean
}

export const LeaderboardTable: FC<LeaderboardTableProps> = ({ data, isMobile }) => {
  const { t } = useTranslation()
  const [userData, setUserDate] = useState<DataType[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)

  const headTitles = [
    t('components.leaderboard.tableCol.col1'),
    t('components.leaderboard.tableCol.col2'),
    t('components.leaderboard.tableCol.col3'),
    t('components.leaderboard.tableCol.col4'),
    t('components.leaderboard.tableCol.col5'),
  ]
  const headTitlesMobile = [
    t('components.leaderboard.tableCol.col1'),
    t('components.leaderboard.tableCol.col2'),
    t('components.leaderboard.tableCol.col5'),
  ]
  const { account } = useAuth()
  const pltPrice = usePricePLT()

  useMemo(() => {
    if (!data) return []
    if (pltPrice !== 0) {
      setLoading(false)
    }

    const _data = data?.map((item: LeaderboardAllTimeGraphEntity, index: number) => {
      const pltValue = new BigNumber(item.totalPLTClaimed)
      const usdValue = pltValue.times(pltPrice)

      return {
        id: index + 1,
        walletAddress: {
          isGold: index === 0,
          isSivler: index === 1,
          isBronze: index === 2,
          isMe: item.id === account?.toLocaleLowerCase(),
          address: item.id,
        },
        gamePlayed: parseInt(item.totalRounds, 10),
        totalTickets: parseInt(item.totalTickets, 10),
        totalWin: {
          usd: formatNumber(getBalanceAmount(usdValue, 0).toNumber()),
          plt: formatNumber(getBalanceAmount(pltValue, 0).toNumber()),
        },
      }
    })

    setUserDate(_data)
    return _data
  }, [data, account, pltPrice])

  return (
    <Table>
      <thead>
        {!isMobile ? (
          <tr>
            {headTitles.map((head: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <th key={index}>{head}</th>
            ))}
          </tr>
        ) : (
          <tr>
            {headTitlesMobile.map((head: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <th key={index}>{head}</th>
            ))}
          </tr>
        )}
      </thead>
      <tbody>
        {userData.map((userInfo) => (
          <tr
            key={userInfo.id}
            className={cn({
              'is-Me': userInfo.walletAddress?.isMe,
            })}
          >
            <td
              className={cn({
                'is-gold': userInfo.walletAddress?.isGold,
                'is-silver': userInfo.walletAddress?.isSivler,
                'is-bronze': userInfo.walletAddress?.isBronze,
              })}
            >
              {userInfo.id}
            </td>
            <td>
              <WalletAddress>
                <span
                  className={cn('medal', {
                    'is-gold': userInfo.walletAddress?.isGold,
                    'is-silver': userInfo.walletAddress?.isSivler,
                    'is-bronze': userInfo.walletAddress?.isBronze,
                  })}
                />
                <span>{publicKeyShortenerHelper(userInfo?.walletAddress.address, 2, 4)}</span>
                {userInfo?.walletAddress?.isMe && <span className="isMe">(your wallet)</span>}
              </WalletAddress>
            </td>
            {!isMobile ? (
              <>
                <td>
                  <GamePlayed>{userInfo?.gamePlayed}</GamePlayed>
                </td>
                <td>
                  <TotalTickets>{userInfo?.totalTickets}</TotalTickets>
                </td>
              </>
            ) : null}
            <td>
              <TotalMaxWin>
                <TotalMaxWinTop>
                  {isLoading ? <CircleLoader /> : <span>${userInfo.totalWin.usd}</span>}
                  {userInfo.totalWin?.nft ? (
                    <>
                      <span>+ {userInfo.totalWin.nft}</span>
                      <NFTIcon />
                    </>
                  ) : null}
                </TotalMaxWinTop>

                <TotalMaxWinBottom>{userInfo.totalWin.plt} PLT</TotalMaxWinBottom>
              </TotalMaxWin>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const TotalMaxWinBottom = styled.div`
  display: inline-flex;
  margin-top: 2px;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
`

const TotalMaxWinTop = styled.div`
  align-items: center;
  display: inline-flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  span:nth-child(2) {
    color: #e29a48;
  }
  svg {
    width: 14px;
  }
  > * + * {
    margin-left: 4px;
  }
`
const TotalMaxWin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 16px;
  padding-right: 16px;
`
const TotalTickets = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`
const GamePlayed = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`
const WalletAddress = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  .medal {
    margin-right: 8px;
  }
  span.isMe {
    color: rgba(255, 255, 255, 0.5);
  }
`

export const Table = styled.table`
  margin-top: 60px;
  border-radius: 8px;
  background: #292d3f;
  overflow: hidden;
  thead {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    background-color: #40455c;
    color: rgba(255, 255, 255, 0.5);
    th {
      text-align: start;
      padding: 16px 16px 5px 16px;
    }
  }
  tbody {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    tr:not(:last-child) {
      border-bottom: 1px solid #575a6c;
    }
    tr:hover {
      background: rgba(255, 255, 255, 0.1);
    }
    tr.is-Me {
      ${WalletAddress} {
        flex-direction: column;
        align-items: baseline;
      }
      background: rgba(17, 202, 190, 0.2);
      ${({ theme }) => theme.mediaQueries.sm} {
        ${WalletAddress} {
          flex-direction: initial;
          span.isMe {
            margin-left: 4px;
          }
        }
      }
    }
    td {
      height: 48px;
      text-align: center;
      vertical-align: middle;
      &.is-gold {
        background: linear-gradient(90deg, rgba(253, 207, 80, 0.4) 0%, rgba(253, 207, 80, 0) 100%);
      }
      &.is-silver {
        background: linear-gradient(90deg, rgba(223, 227, 227, 0.4) 0%, rgba(223, 227, 227, 0) 100%);
      }
      &.is-bronze {
        background: linear-gradient(90deg, rgba(205, 127, 50, 0.4) 0%, rgba(205, 127, 50, 0) 100%);
      }
    }
    .medal {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: none;
      &.is-gold {
        display: inline-flex;
        background: url('/images/leaderboard/Gold.svg') no-repeat center center;
      }
      &.is-silver {
        display: inline-flex;
        background: url('/images/leaderboard/Silver.svg') no-repeat center center;
      }
      &.is-bronze {
        display: inline-flex;
        background: url('/images/leaderboard/Bronze.svg') no-repeat center center;
      }
    }
  }
`
