import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LangSupportedList } from 'utils/types'

import en from './translations/en.json'
import es from './translations/es.json'
import ja from './translations/ja.json'
import de from './translations/de.json'
import fr from './translations/fr.json'

export const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  ja: {
    translation: ja,
  },
  de: {
    translation: de,
  },
  fr: {
    translation: fr,
  },
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: () => {
    const supportLang = navigator?.languages.filter(
      (item) =>
        item === LangSupportedList.es ||
        item === 'es-AR' ||
        item === LangSupportedList.en ||
        item === 'en-US' ||
        item === LangSupportedList.ja ||
        item === LangSupportedList.de ||
        item === LangSupportedList.fr,
    )[0]
    if (!supportLang) return LangSupportedList.en
    return supportLang
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

export default i18n
