import React from 'react'
import styled from 'styled-components'
import { Box, Button, Flex, InjectedModalProps, LinkExternal, Message, Skeleton, Text } from '@pancakeswap/uikit'
import useTokenBalance, { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { getBscScanLink } from 'utils'
import { getFullDisplayBalance, formatBigNumber } from 'utils/formatBalance'
import tokens from 'config/constants/tokens'
import CopyAddress from './CopyAddress'

interface WalletInfoProps {
  hasLowBnbBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const StyledButton = styled(Button)`
  border-color: #b67122 !important;
  border-width: 2px !important;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.03em;
  padding: 18px 0px;
  color: white !important;
  &:hover {
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowBnbBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useAuth()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useTokenBalance(tokens.plt.address)
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss()
    logout()
  }

  return (
    <>
      <Text color="#fff" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('components.menu.userMenu.walletInfo.yourAddress')}
      </Text>
      <CopyAddress account={account} mb="24px" />
      {hasLowBnbBalance && (
        <Message variant="warning" mb="24px">
          <Box>
            <Text color="#fff" fontWeight="bold">
              {t('components.menu.userMenu.walletInfo.messages.text1')}
            </Text>
            <Text color="#fff" as="p">
              {t('components.menu.userMenu.walletInfo.messages.text2')}
            </Text>
          </Box>
        </Message>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        <Text color="#fff">{t('components.menu.userMenu.walletInfo.bnbBalance')}</Text>
        {fetchStatus !== FetchStatus.SUCCESS ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text color="#fff">{formatBigNumber(balance, 6)}</Text>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mb="24px">
        <Text color="#fff">{t('components.menu.userMenu.walletInfo.pltBalance')}</Text>
        {cakeFetchStatus !== FetchStatus.SUCCESS ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text color="#fff">{getFullDisplayBalance(cakeBalance, 18, 3)}</Text>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="end" mb="24px">
        <LinkExternal href={getBscScanLink(account, 'address')}>
          {t('components.menu.userMenu.walletInfo.bscScanLink')}
        </LinkExternal>
      </Flex>
      <StyledButton variant="secondary" width="100%" onClick={handleLogout}>
        {t('components.menu.userMenu.walletInfo.disconnectWallet')}
      </StyledButton>
    </>
  )
}

export default WalletInfo
