/* eslint-disable no-console */
import React, { useRef, useState } from 'react'

import { Flex, Text, AutoRenewIcon } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'
import { LotteryTicketClaimData } from 'config/constants/types'
import { useLottery } from 'state/lottery/hooks'
import useToast from 'hooks/useToast'
import useNft from 'hooks/useNft'
import Balance from 'components/Balance'
import { ReactComponent as FacebookIcon } from 'assets/icons/shared/Facebook.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/shared/Twitter.svg'
import { ReactComponent as NFTIcon } from 'assets/icons/NFT.svg'
import { SocialLinkAdresses, SocialLink } from 'config/constants/socialLinks'
import html2canvas from 'html2canvas'
import { SharedButton, SharedList, SharedListItem, StyledButton } from './styled'
import { useAuth } from 'hooks/useAuth'

interface ClaimInnerProps {
  roundsToClaim: LotteryTicketClaimData[]
  onSuccess?: () => void
  nftTotal: number
}

const ClaimNftsInnerContainer: React.FC<ClaimInnerProps> = ({ onSuccess, nftTotal }) => {
  const { account } = useAuth()
  const { t } = useTranslation()
  const { toastSuccess } = useToast()
  const { getNftBalance } = useNft()
  const printRef = useRef()
  const [pendingTx, setPendingTx] = useState(false)
  const { currentLotteryId } = useLottery()

  const handleClaim = async () => {
    setPendingTx(true)
    try {
      await fetch(`${process.env.REACT_APP_NFT_FAUCET_API}/api/nft/reward`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ account }),
      })
      toastSuccess(t('views.lottery.components.claimPrizesModal.toastSuccess.title'))
      onSuccess()
    } catch (error) {
      console.error(error)
    } finally {
      setPendingTx(false)
    }
    getNftBalance()
  }

  async function sharefbimage(text: string) {
    // @ts-ignore
    if (window.FB as any) {
      // @ts-ignore
      window.FB.init({
        appId: `${window.location.href.includes('localhost') ? '739972980354398' : process.env.REACT_APP_FB_ID}`,
        appSecret: `${
          window.location.href.includes('localhost')
            ? '4a6c92e3fe5a5c25bc5d782e53dc7364'
            : process.env.REACT_APP_FB_SECRET
        }`,
        status: true,
        cookie: true,
        version: 'v13.0',
      })
      // @ts-ignore
      window.FB.ui({
        method: 'share',
        display: 'popup',
        appId: `${window.location.href.includes('localhost') ? '739972980354398' : process.env.REACT_APP_FB_ID}`,
        href: 'https://pltlotto.com/',
        quote: text,
      })
    }
  }

  const dataURLtoFile = (dataurl: string, filename: string): File | null => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)![1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  const shareLink = async (e, key: string): Promise<void> => {
    e.preventDefault()
    const findAddress = SocialLinkAdresses.find((item) => Object.is(item.key, key))

    const element = printRef.current
    const contentType = 'image/jpg'

    const canvas = await html2canvas(element)

    const data = canvas.toDataURL(contentType) // .replace(/^data:image\/[a-z]+;base64,/, '')
    const file = dataURLtoFile(data, 'image.png')
    let text

    if (findAddress?.via) {
      text = `${t('shared.twitter.twitterText_context', {
        context: 'nft',
        amountNFT: nftTotal.toString(),
      })}`
      console.log(text)
      const link = `${findAddress.url}${window.location.protocol}//${window.location.host}&text=${text}`
      window.open(link, 'CNN_WindowName')
    } else {
      text = `${t('shared.facebook.facebookText_context', {
        context: 'nft',
        amountNFT: nftTotal.toString(),
      })}`
      await sharefbimage(text)
    }
  }

  return (
    <>
      <div ref={printRef} style={{ background: '#292D3F', border: 'none' }}>
        <Flex flexDirection="column">
          <Text mb="4px" textAlign={['center', null, 'left']} color="#fff">
            {t('views.lottery.components.claimPrizesModal.youWon')}
          </Text>
          <Flex alignItems="center">
            <Text fontSize="44px" bold color="#EC9A44" marginRight="8px">
              {nftTotal}
            </Text>
            <NFTIcon height={30} width={30} />
          </Flex>
          <Balance
            mt={['12px', null, '0']}
            textAlign={['center', null, 'left']}
            decimals={2}
            value={0}
            fontSize="12px"
            color="#fff"
            unit=" USD"
            prefix="~"
          />
        </Flex>

        <Flex alignItems="center" justifyContent="center">
          <Text mt="8px" fontSize="12px" color="#fff">
            {t('views.lottery.components.claimPrizesModal.round')} #{currentLotteryId}
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="center">
          <StyledButton
            isLoading={pendingTx}
            endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
            mt="20px"
            width="100%"
            id="claim-reward"
            onClick={handleClaim}
          >
            {pendingTx
              ? t('views.lottery.components.claimPrizesModal.claiming')
              : t('views.lottery.components.claimPrizesModal.claim')}{' '}
          </StyledButton>
        </Flex>
      </div>

      <SharedList>
        {SocialLinkAdresses.map((item: SocialLink) => (
          <SharedListItem key={item.key}>
            <SharedButton onClick={(e) => shareLink(e, item.key)} type="button">
              {item.key === 'twitter' ? <TwitterIcon /> : null}
              {item.key === 'facebook' ? <FacebookIcon /> : null}
              <span>
                {t('shared.sharedOn')} {item.name}
              </span>
            </SharedButton>
          </SharedListItem>
        ))}
      </SharedList>
    </>
  )
}

export default ClaimNftsInnerContainer
