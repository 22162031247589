import styled from 'styled-components'
import { Button } from '@pancakeswap/uikit'

export const StyledButton = styled(Button)`
  background-image: linear-gradient(265.56deg, #922c32 -0.27%, #922c32 -0.26%, #b67122 98.59%);
  align-items: center;
  border: 0;
  border-radius: 30px;
  box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.03em;
  padding: 5px 16px;
  color: white;
  &:hover {
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`

export const SharedListItem = styled.li`
  list-style: none;
  margin-top: 10px;
`
export const SharedList = styled.ul`
  display: flex;
  flex-direction: column;
`
export const SharedButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid #1d9bf0;
  background-color: transparent;
  width: 100%;
  height: 48px;
  border-radius: 48px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
  svg + * {
    margin-left: 8px;
  }
`
