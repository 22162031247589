import styled from 'styled-components'

export const Dropdown = styled.div`
  display: none;
  position: absolute;
  top: 33px;
  right: 10px;
  ${({ theme }) => theme.mediaQueries.lg} {
    left: 20px;
    right: initial;
    transform: translateX(-50%);
  }
  z-index: 12;
`

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin-top: 12px;
  min-width: 330px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.6);
  border-radius: 16px;
`

export const Icon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 2px solid #1fc7d4;
  border-radius: 50%;
  background: #faf9fa;
  z-index: 2;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  min-width: 78px;
  background: #eff4f5;
  box-shadow: 0px -2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding-left: 48px;
  padding-bottom: 2px;
  padding-right: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #280d5f;
`

export const Container = styled.div<{ hasBalance?: boolean; walletConnected?: boolean }>`
  position: relative;
  display: ${({ walletConnected }) => (walletConnected ? 'flex' : 'none')};
  align-items: center;
  user-select: none;

  /* ${({ theme }) => theme.mediaQueries.sm} { */
  display: flex;
  /* } */

  &:hover ${Dropdown} {
    display: ${({ hasBalance }) => (hasBalance ? 'block' : 'none')};
  }

  &:hover > ${Icon} {
    cursor: pointer;
    opacity: 0.7;
  }

  &:hover ${Wrapper} {
    cursor: pointer;
    opacity: 0.7;
  }
`
