import React from 'react'
import { FlexProps } from '@pancakeswap/uikit'
import uniqueId from 'lodash/uniqueId'
import styled from 'styled-components'
import { parseRetrievedNumber } from '../helpers'
import { BallWithNumber } from '../svgs'

interface WinningNumbersProps extends FlexProps {
  number: string
  size?: string
  fontSize?: string
  isLargerScreen?: boolean
}

const BallContainer: React.FC = (props) => {
  return <BallContainerEl {...props} />
}

const BallContainerEl = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 570px;

  @media (max-width: 852px) {
    width: 100%;
    max-width: 340px;
  }
`

const WinningNumbers: React.FC<WinningNumbersProps> = ({ number, size = '32px', fontSize = '16px' }) => {
  const reversedNumber = parseRetrievedNumber(number)
  const numAsArray = reversedNumber.split('')
  return (
    <BallContainer>
      {numAsArray.map((num) => {
        return <BallWithNumber key={uniqueId()} size={size} fontSize={fontSize} number={num} />
      })}
    </BallContainer>
  )
}

export default WinningNumbers
