import React from 'react'
import { ModalProvider, light, dark } from '@pancakeswap/uikit'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { useThemeManager } from 'state/user/hooks'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { ToastsProvider } from 'contexts/ToastsContext'
import { NFTProvider } from 'contexts'
import store from 'state'
import { withWagmi } from 'hocs/with-wagmi'
import { withAuth } from 'hocs/with-auth'

const ThemeProviderWrapper = (props) => {
  const [isDark] = useThemeManager()
  return <ThemeProvider theme={isDark ? dark : light} {...props} />
}

const Providers: React.FC = withWagmi(
  withAuth(({ children }) => {
    return (
      <Provider store={store}>
        <ToastsProvider>
          <NFTProvider>
            <HelmetProvider>
              <ThemeProviderWrapper>
                <RefreshContextProvider>
                  <ModalProvider>{children}</ModalProvider>
                </RefreshContextProvider>
              </ThemeProviderWrapper>
            </HelmetProvider>
          </NFTProvider>
        </ToastsProvider>
      </Provider>
    )
  }),
)

export default Providers
