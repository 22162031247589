import React, { useState, useEffect } from 'react'
import { Flex, Heading, Text, Skeleton, Button, useModal } from '@pancakeswap/uikit'

import { LotteryStatus } from 'config/constants/types'
import { useTranslation } from 'react-i18next'
import { useLottery } from 'state/lottery/hooks'
import { getBalanceNumber } from 'utils/formatBalance'
import Balance from 'components/Balance'
import { ReactComponent as NFTIcon } from 'assets/icons/NFT.svg'
import ViewTicketsModal from '../ViewTicketsModal'
import { BuyTicketsButton } from '../BuyTicketsButton'
import { dateTimeOptions } from '../../helpers'
import { usePricePLT } from '../../hooks/usePricePLT'
import RewardBrackets from '../RewardBrackets'
import {
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  MobileCardHeader,
  NextDrawWrapper,
  StyledBalance,
  StyledCard,
  StyledExpandableLabel,
} from './styled'
import { useAuth } from 'hooks/useAuth'

const NextDrawCard = () => {
  const [width, setWidth] = useState<number>(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile: boolean = width <= 768
  const { t } = useTranslation()
  const { account } = useAuth()
  const { currentLotteryId, isTransitioning, currentRound } = useLottery()
  const { endTime, amountCollectedInCake, userTickets, status } = currentRound

  const [onPresentViewTicketsModal] = useModal(<ViewTicketsModal roundId={currentLotteryId} roundStatus={status} />)
  const [isExpanded, setIsExpanded] = useState(true)
  const ticketBuyIsDisabled = status !== LotteryStatus.OPEN || isTransitioning

  const pltPrice = usePricePLT()
  const prizeInBusd = amountCollectedInCake.times(pltPrice)

  const endTimeMs = parseInt(endTime, 10) * 1000
  const endDate = new Date(endTimeMs)
  const isLotteryOpen = status === LotteryStatus.OPEN
  const userTicketCount = userTickets?.tickets?.length || 0

  const getPrizeBalances = () => {
    if (status === LotteryStatus.CLOSE || status === LotteryStatus.CLAIMABLE) {
      return (
        <Heading scale="xl" color="#11CABE" textAlign={['center', null, null, 'left']}>
          {t('views.lottery.components.nextDrawCard.calculating')}...
        </Heading>
      )
    }
    return (
      <>
        <Flex justifyContent="space-between">
          <Balance
            fontSize="40px"
            color="#11CABE"
            lineHeight="1"
            bold
            prefix="~$"
            value={getBalanceNumber(prizeInBusd)}
            decimals={0}
          />
          <StyledBalance
            fontSize="14px"
            color="#A5ACCF"
            unit=" PLT"
            value={getBalanceNumber(amountCollectedInCake)}
            decimals={0}
          />
        </Flex>
      </>
    )
  }

  const getNextDrawId = () => {
    if (status === LotteryStatus.OPEN) {
      return `${currentLotteryId} |`
    }
    if (status === LotteryStatus.PENDING) {
      return ''
    }
    return parseInt(currentLotteryId, 10) + 1
  }

  const getNextDrawDateTime = () => {
    if (status === LotteryStatus.OPEN) {
      return `${t('views.lottery.components.nextDrawCard.draw')}: ${endDate.toLocaleString(undefined, dateTimeOptions)}`
    }
    return ''
  }

  const ticketRoundText =
    userTicketCount > 1
      ? t('views.lottery.components.nextDrawCard.tickets', { amount: userTicketCount })
      : t('views.lottery.components.nextDrawCard.ticket', { amount: userTicketCount })
  const [youHaveText, ticketsThisRoundText] = ticketRoundText.split(userTicketCount.toString())

  return (
    <StyledCard background="none">
      {!isMobile ? (
        <CardHeader>
          <Flex justifyContent="space-between">
            <Heading color="white" mr="12px">
              {t('views.lottery.components.nextDrawCard.nextDraw')}
            </Heading>
            <Text color="white">
              {currentLotteryId && `#${getNextDrawId()}`} {Boolean(endTime) && getNextDrawDateTime()}
            </Text>
          </Flex>
        </CardHeader>
      ) : (
        <MobileCardHeader>
          <Flex flexDirection="column" mr="12px">
            <Heading color="white">{t('views.lottery.components.nextDrawCard.nextDraw')}</Heading>
            <Text color="white">
              {currentLotteryId && `#${getNextDrawId()}`} {Boolean(endTime) && getNextDrawDateTime()}
            </Text>
          </Flex>
        </MobileCardHeader>
      )}
      <CardBody>
        {!isMobile ? (
          <div>
            <Flex justifyContent="space-between">
              <Heading color="white">{t('views.lottery.components.nextDrawCard.prizePot')}</Heading>
              {getPrizeBalances()}
            </Flex>
            <Divider />
            <Flex justifyContent="space-between" alignItems="center">
              <Heading color="white">{t('views.lottery.components.nextDrawCard.yourTickets')}</Heading>
              <BuyTicketsButton disabled={ticketBuyIsDisabled} />
            </Flex>
          </div>
        ) : (
          <div>
            <Flex alignItems="center" flexDirection="column" mt="40px">
              <Heading color="white">{t('views.lottery.components.nextDrawCard.prizePot')}</Heading>
              {getPrizeBalances()}
            </Flex>
            <Divider />
            {!isMobile ? (
              <Flex justifyContent="space-between" alignItems="center">
                <Heading color="white">{t('views.lottery.components.nextDrawCard.yourTickets')}</Heading>
                <BuyTicketsButton disabled={ticketBuyIsDisabled} />
              </Flex>
            ) : (
              <Flex alignItems="center" flexDirection="column">
                <Heading mb="20px" color="white">
                  {t('views.lottery.components.nextDrawCard.yourTickets')}
                </Heading>
                <BuyTicketsButton disabled={ticketBuyIsDisabled} />
              </Flex>
            )}
          </div>
        )}
        <Flex mt="30px" flexDirection={['column', null, null, 'row']} alignItems={['center', null, null, 'flex-start']}>
          {isLotteryOpen && (
            <Flex
              flexDirection="column"
              mr={[null, null, null, '24px']}
              alignItems={['center', null, null, 'flex-start']}
            >
              {account && (
                <Flex justifyContent={['center', null, null, 'flex-start']}>
                  <Text color="#fff" display="inline">
                    {youHaveText}{' '}
                  </Text>
                  {!userTickets.isLoading ? (
                    <Balance color="#fff" value={userTicketCount} decimals={0} display="inline" bold mx="4px" />
                  ) : (
                    <Skeleton mx="4px" height={20} width={40} />
                  )}
                  <Text color="#fff" display="inline">
                    {' '}
                    {ticketsThisRoundText}
                  </Text>
                </Flex>
              )}
              {!userTickets.isLoading && userTicketCount > 0 && (
                <Button
                  onClick={onPresentViewTicketsModal}
                  height="auto"
                  width="fit-content"
                  p="0"
                  mb={['32px', null, null, '0']}
                  variant="text"
                  scale="sm"
                >
                  {t('views.lottery.components.nextDrawCard.btn')}
                </Button>
              )}
            </Flex>
          )}
        </Flex>
      </CardBody>
      <CardFooter>
        {isExpanded && (
          <>
            <NextDrawWrapper>
              <RewardBrackets lotteryNodeData={currentRound} />
            </NextDrawWrapper>
            <Flex margin="20px 30px" alignItems="center">
              <NFTIcon />
              <Text color="#A5ACCF" marginLeft="10px">
                {t('views.lottery.components.nextDrawCard.numberOfNftForWinner')}
              </Text>
            </Flex>
          </>
        )}
        {(status === LotteryStatus.OPEN || status === LotteryStatus.CLOSE) && (
          <Flex p="8px 24px" alignItems="center" justifyContent="center">
            <StyledExpandableLabel expanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? t('shared.hide') : t('shared.details')}
            </StyledExpandableLabel>
          </Flex>
        )}
      </CardFooter>
    </StyledCard>
  )
}

export default NextDrawCard
