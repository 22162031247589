import styled from 'styled-components'
import { Link } from '@pancakeswap/uikit'
import { FooterWrapper } from 'components/Footer'
import {
  ItemHeader,
  OpenTrigger,
  StepBtnContainer,
  StepLabel,
  StepLabelContainer,
  StepLabelDesc,
  StepLabelTitle,
  StepStatus,
} from './itemHeader.styled'
import {
  AssideBlock,
  ContentBlock,
  ItemContent,
  ItemContentText,
  QuestionsFlex,
  HeaderDropdownButton,
  CustomBtnFlexContainer,
  BasicInlineButton,
} from './itemContent.styled'

export {
  ItemHeader,
  OpenTrigger,
  StepBtnContainer,
  StepLabel,
  StepLabelContainer,
  StepLabelDesc,
  StepLabelTitle,
  StepStatus,
  ItemContent,
  ItemContentText,
  AssideBlock,
  ContentBlock,
  QuestionsFlex,
  HeaderDropdownButton,
  CustomBtnFlexContainer,
  BasicInlineButton,
}

export const StepListItem = styled.li`
  display: flex;
  flex-direction: column;
  background: #292d3f;
  border-radius: 40px;
  padding: 16px;

  ${({ theme }) => theme.mediaQueries.xxl} {
    padding: 24px;
  }
`
export const StepList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: auto;
  max-width: 1152px;
  width: 100%;

  ${StepListItem}.is-open {
    ${ItemContent} {
      display: flex;
      flex-direction: column;
      padding: 16px 0 calc(40px - 16px) 0;
    }
  }

  * + ${StepListItem} {
    margin-top: 40px;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    ${StepListItem}.is-open {
      ${ItemContent} {
        flex-direction: row;
      }
    }
  }
`
export const Title = styled.div`
  text-align: center;
  font-size: 40px;
  line-height: 44px;
`
export const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  background-image: url(/images/BG.jpg);
  background-size: cover;
  padding: 50px 10px;
  color: #fff;
  font-weight: 400;

  * + ${StepList} {
    margin-top: 40px;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    padding: 66px 66px 0 66px;

    ${FooterWrapper} {
      padding-left: 0;
      padding-right: 0;
    }
    * + ${StepList} {
      margin-top: 80px;
    }
  }
`
export const InlineButton = styled(Link)`
  background-image: linear-gradient(265.56deg, #922c32 -0.27%, #922c32 -0.26%, #b67122 98.59%);
  align-items: center;
  border: 0;
  border-radius: 25px;
  box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.03em;
  margin: 24px 0;
  padding: 11px 16px;
  color: white;
  text-align: center;
  justify-content: center;
  width: ${(props) => props.width}px;
  font-size: ${(props) => props.fontSize}px;
`

export const ConnectWalletButton = styled(InlineButton)`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  max-width: 220px;
  height: 40px;

  color: #fff;
  border-radius: 30px;
  margin: 8px auto;
  transition: 0.4s ease all;
  &:hover {
    border-color: #b67122;
  }
  ${({ theme }) => theme.mediaQueries.xxl} {
    margin: 0 32px;
    font-size: 20px;
    height: 60px;

    width: 276px;
  }
`
export const NoteText = styled.div`
  text-align: center;
  margin-top: 40px;
  padding: 0 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  color: #fff;
  span {
    word-break: initial;
  }
`
