import React from 'react'
import BigNumber from 'bignumber.js'
import { Flex, Skeleton, Text } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'
import { ReactComponent as NFTIcon } from 'assets/icons/NFT.svg'
import Balance from 'components/Balance'
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { usePricePLT } from '../hooks/usePricePLT'

interface RewardBracketDetailProps {
  cakeAmount: BigNumber
  rewardBracket?: number
  rewardNftAmount?: string
  numberWinners?: string
  isBurn?: boolean
  isNFT?: boolean
  nftAmount?: number
  isHistoricRound?: boolean
  isLoading?: boolean
}

export const RewardBracketDetail: React.FC<RewardBracketDetailProps> = ({
  rewardBracket,
  cakeAmount,
  nftAmount,
  rewardNftAmount,
  numberWinners,
  isHistoricRound,
  isBurn,
  isNFT,
  isLoading,
}) => {
  const { t } = useTranslation()
  const pltPrice = usePricePLT()
  const hasNftReward = Number(rewardNftAmount) > 0
  const onlyNftReward = getBalanceNumber(cakeAmount) === 0 && Number(rewardNftAmount) > 0

  const getRewardText = () => {
    const numberMatch = rewardBracket + 1
    if (isBurn) {
      return t('views.lottery.components.rewardBracket.rewardText_context', { context: 'isBurn' })
    }
    if (isNFT) {
      return t('views.lottery.components.rewardBracket.rewardText_context', { context: 'isNFT' })
    }
    if (rewardBracket === 5) {
      return t('views.lottery.components.rewardBracket.rewardText_context', { context: 'rewardBracket', numberMatch })
    }
    return t('views.lottery.components.rewardBracket.rewardText_context', { numberMatch })
  }

  const getRewardColor = () => {
    if (isBurn) {
      return 'failure'
    }
    if (isNFT) {
      return '#EC9A44 !important'
    }
    return '#11CABE !important'
  }

  return (
    <Flex flexDirection="column">
      {isLoading ? (
        <Skeleton mb="4px" mt="8px" height={16} width={80} />
      ) : (
        <Text bold color={getRewardColor()}>
          {getRewardText()}
        </Text>
      )}
      <>
        {isLoading || cakeAmount.isNaN() ? (
          <Skeleton my="4px" mr="10px" height={20} width={50} />
        ) : (
          <Flex alignItems="center" flexWrap="wrap">
            {onlyNftReward ? (
              <>
                <Text fontSize="20px" bold color="#EC9A44" marginRight="4px">
                  {rewardNftAmount}
                </Text>
                <NFTIcon />
              </>
            ) : (
              <>
                <Balance
                  color="#ffffff"
                  fontSize="20px"
                  bold
                  unit={isNFT ? null : ' PLT'}
                  value={isNFT ? nftAmount : getBalanceNumber(cakeAmount)}
                  decimals={0}
                />
                {hasNftReward && (
                  <Flex>
                    <Text display="inline" fontSize="20px" bold color="#ffffff">
                      &nbsp;+&nbsp;
                    </Text>
                    <Text display="inline" fontSize="20px" bold color="#EC9A44">
                      {rewardNftAmount}
                      <NFTIcon style={{ marginLeft: 4 }} />
                    </Text>
                  </Flex>
                )}
              </>
            )}
          </Flex>
        )}
        {!isNFT && (
          <>
            {isLoading || cakeAmount.isNaN() ? (
              <>
                <Skeleton mt="4px" mb="16px" height={12} width={90} />
              </>
            ) : (
              <Balance
                fontSize="12px"
                color="#fff"
                prefix="~$"
                hideZeroPrice
                value={getBalanceNumber(cakeAmount.times(pltPrice))}
                decimals={0}
              />
            )}
          </>
        )}
        {isHistoricRound && !isNFT && cakeAmount && (
          <>
            {numberWinners !== '0' && (
              <Text fontSize="12px" color="#A5ACCF">
                {getFullDisplayBalance(cakeAmount.div(parseInt(numberWinners, 10)), 18, 2)} PLT {t('each')}
              </Text>
            )}
            <Text fontSize="12px" color="#A5ACCF">
              {numberWinners} {t('views.lottery.components.rewardBracket.winners')}
            </Text>
          </>
        )}
      </>
    </Flex>
  )
}
