import { LangWrapper } from 'components/Menu/LangSwitcher/index.styled'
import { ListWrapper } from 'components/SocialList'
import styled from 'styled-components'

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: #fff;
  height: 70px;
  padding: 0 10px;
  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0 66px;
  }

  &[data-mobile='true'] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 98;

    + * {
      scroll-margin-top: 70px;
      margin-top: 70px;
    }
  }
`
export const HeaderLogo = styled.div`
  display: inline-flex;
  flex-shrink: 0;
`
export const MenuListItem = styled.li`
  font-size: 16px;
  line-height: 22px;
  color: #141518;
  &:hover {
    color: #1fc7d4;
  }
  > * {
    color: currentColor;
    &:hover {
      color: currentColor;
    }
  }
`
export const MenuList = styled.ul`
  display: flex;
  list-style: none;
  justify-content: space-between;

  * + ${MenuListItem} {
    margin-left: 20px;
  }

  &[data-mobile='true'] {
    flex-direction: column;
    align-items: center;

    * + ${MenuListItem} {
      margin-top: 48px;
      margin-left: initial;
    }
  }
`
export const HeaderLeftPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 480px;
  min-width: 360px;
  ${({ theme }) => theme.mediaQueries.lg} {
    min-width: initial;
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    min-width: 360px;
  }
  ${HeaderLogo} + ${MenuList} {
    margin-left: 20px;
  }
`
export const HeaderRightPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * + * {
    margin-left: 10px;
  }
`
export const HeaderMobileRight = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 10px;
  }
`
export const PlayLotteryBtn = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  height: 36px;
  width: 150px;
  background: linear-gradient(90deg, #b67122 0%, #922c32 100%);
  border-radius: 24px;
  border: none;
`

export const BurgerMenu = styled.aside<{ isMenuOpen?: boolean }>`
  display: flex;
  visibility: ${({ isMenuOpen }) => (isMenuOpen ? 'visible' : 'hidden')};
  opacity: ${({ isMenuOpen }) => (isMenuOpen ? 1 : 0)};
  flex-direction: column;
  align-items: center;
  position: absolute;
  transition: 0.2s ease all;
  padding-top: 50px;
  padding-bottom: 50px;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 10;
  background: white;
  max-height: calc(100vh - 70px);
  overflow: auto;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: #bdbdbd;
  }

  * + ${MenuList}, * + ${ListWrapper}, * + ${PlayLotteryBtn}, * + ${LangWrapper} {
    margin-top: 48px;
  }
`
