import React from 'react'
import { Flex, Text } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'

import { ReactComponent as NFTIcon } from 'assets/icons/NFT.svg'
import { ReactComponent as QustionIcon } from 'assets/icons/Question.svg'
import useNft from 'hooks/useNft'

import history from '../../routerHistory'

import { Container, Dropdown, DropdownWrapper, Icon, Wrapper } from './styled'
import { useAuth } from 'hooks/useAuth'

const getOffset = (el: HTMLElement) => {
  const rect = el?.getBoundingClientRect()
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  }
}

export const NFTBalance = ({ isOpen, setOpen, isMobile }) => {
  const { account } = useAuth()
  const { balance, loading } = useNft()
  const { t, i18n } = useTranslation()

  return !account ? (
    <button
      type="button"
      style={{ border: 'none', background: 'transparent' }}
      onClick={(e) => {
        e.preventDefault()

        setOpen(!isOpen)
        history.push('/')
        setTimeout(() => {
          const refElement = document.getElementById('freeNFT')
          const yOffset = 70

          if (isMobile) {
            window.scrollTo({
              top: getOffset(refElement).top - yOffset,
              behavior: 'smooth',
            })
            setOpen(false)
          } else {
            refElement?.scrollIntoView({ behavior: 'smooth' })
          }
        }, 500)
      }}
    >
      <Container walletConnected={Boolean(account)}>
        <Icon>
          <NFTIcon />
        </Icon>
        <Wrapper>{account ? balance : `${i18n.t('components.nftBalance.getTickets')}`}</Wrapper>
      </Container>
    </button>
  ) : (
    <Container hasBalance={Boolean(balance)}>
      <Icon>
        <NFTIcon />
      </Icon>
      {loading ? <Wrapper>loading...</Wrapper> : <Wrapper>{account ? balance : 'Get NFT tickets'}</Wrapper>}
      <Dropdown>
        <DropdownWrapper>
          <Text color="#11CABE" fontSize="14px" fontWeight="bold">
            {t('components.nftBalance.youHave')}
          </Text>
          <Flex alignItems="center">
            <Icon style={{ position: 'static' }}>
              <NFTIcon />
            </Icon>
            <Text marginLeft="13px" fontSize="42px" color="#280D5F">
              {loading ? 'loading...' : balance}
            </Text>
          </Flex>
          <Text fontSize="16px" color="#280D5F">
            {t('components.nftBalance.nftTickets')}
          </Text>
          <a
            href="https://poollotto-finance.gitbook.io/pltlotto_q_and_a/what-is-the-plt-lotto-nft-ticket"
            target="_blank"
            rel="noreferrer"
          >
            <Flex marginTop="20px" alignItems="center">
              <Text color="#A5ACCF" fontSize="14px" marginRight="6px">
                {t('components.nftBalance.question')}
              </Text>
              <QustionIcon />
            </Flex>
          </a>
        </DropdownWrapper>
      </Dropdown>
    </Container>
  )
}
