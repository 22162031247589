import React from 'react'
import styled from 'styled-components'
import { Modal } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'
import { LotteryStatus } from 'config/constants/types'
import { useLottery } from 'state/lottery/hooks'
import PreviousRoundTicketsInner from './PreviousRoundTicketsInner'
import CurrentRoundTicketsInner from './CurrentRoundTicketsInner'

const StyledModal = styled(Modal)`
  min-width: 280px;
  max-width: 320px;
  color: white;

  background: #292d3f;
  border: none !important;
  .sc-biJonm,
  .sc-gIvpjk {
    color: #fff;
    h2 {
      color: currentColor;
    }
    button {
      color: currentColor;
    }
    svg {
      fill: currentColor;
    }
  }
`

interface ViewTicketsModalProps {
  roundId: string
  roundStatus?: LotteryStatus
  onDismiss?: () => void
}

const ViewTicketsModal: React.FC<ViewTicketsModalProps> = ({ onDismiss, roundId, roundStatus }) => {
  const { t } = useTranslation()
  const { currentLotteryId } = useLottery()
  const isPreviousRound = roundStatus?.toLowerCase() === LotteryStatus.CLAIMABLE || roundId !== currentLotteryId

  return (
    <StyledModal
      title={`${t('views.lottery.components.viewTicketsModal.round')} ${roundId}`}
      onDismiss={onDismiss}
      headerBackground="#40455C"
    >
      {isPreviousRound ? <PreviousRoundTicketsInner roundId={roundId} /> : <CurrentRoundTicketsInner />}
    </StyledModal>
  )
}

export default ViewTicketsModal
