import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Heading, Skeleton } from '@pancakeswap/uikit'
import { LotteryStatus } from 'config/constants/types'
import { useTranslation } from 'react-i18next'
import { useLottery } from 'state/lottery/hooks'
import { getBalanceNumber } from 'utils/formatBalance'
import Balance from 'components/Balance'
import { usePricePLT } from '../hooks/usePricePLT'
import { BuyTicketsButton } from './BuyTicketsButton'

import '../../../style/views/Help/style.css'

const PrizeTotalBalance = styled(Balance)`
  display: inline-flex;
  justify-content: center;
  font-size: 60px;
  font-weight: 700;
  line-height: 66px;
  color: #fff;
  span {
    display: inline-flex;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`
const StyledHeading = styled(Heading)`
  text-align: center;
  color: #fff;
`

const StyledBuyTicketButton = styled(BuyTicketsButton)<{ disabled: boolean }>`
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.disabled : 'linear-gradient(180deg, #7645d9 0%, #452a7a 100%)'};
  width: 200px;
  ${({ theme }) => theme.mediaQueries.xs} {
    width: 240px;
  }
`

const ButtonWrapper = styled.div`
  position: relative;
  max-width: 830px;
  margin: 0 auto;
  text-align: center;
`
const Loader = styled.div`
  display: inline-flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 60px;
  height: 60px;
  span {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: rotate(225deg);
    animation: loader-rotate-animation 5s infinite;

    &:nth-child(2) {
      animation-delay: 220ms;
    }
    &:nth-child(3) {
      animation-delay: 440ms;
    }
    &:nth-child(4) {
      animation-delay: 660ms;
    }
    &:nth-child(5) {
      animation-delay: 880ms;
    }

    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #b67122;
    }
  }

  @keyframes loader-rotate-animation {
    0% {
      opacity: 0;
      transform: rotate(225deg);
      animation-timing-function: ease-out;
    }
    7% {
      opacity: 1;
      transform: rotate(345deg);
      animation-timing-function: linear;
    }
    30% {
      opacity: 1;
      transform: rotate(455deg);
      animation-timing-function: ease-out;
    }
    39% {
      opacity: 1;
      transform: rotate(690deg);
      animation-timing-function: linear;
    }
    70% {
      opacity: 1;
      transform: rotate(815deg);
      animation-timing-function: ease-out;
    }
    75% {
      opacity: 0;
      transform: rotate(945deg);
    }
    100% {
      opacity: 0;
    }
  }
`
const HerroContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${StyledHeading} + * {
    margin-top: 40px;
  }
  * + ${ButtonWrapper} {
    margin-top: 40px;
  }
`

const Hero = () => {
  const { t } = useTranslation()
  const [noFreeNft, setNoFreeNft] = useState(true)
  const {
    currentRound: { amountCollectedInCake, status },
    isTransitioning,
  } = useLottery()

  const pltPrice = usePricePLT()
  const prizeInBusd = amountCollectedInCake.times(pltPrice)
  const prizeTotal = getBalanceNumber(prizeInBusd)
  const ticketBuyIsDisabled = status !== LotteryStatus.OPEN || isTransitioning

  const getHeroHeading = () => {
    if (status === LotteryStatus.OPEN) {
      return (
        <>
          {prizeInBusd.isNaN() ? (
            <Skeleton my="7px" height={66} width={190} />
          ) : (
            <PrizeTotalBalance prefix="$" value={prizeTotal} decimals={0} />
          )}
        </>
      )
    }
    return (
      <Loader>
        {new Array(5).fill(0).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index} />
        ))}
      </Loader>
    )
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_NFT_FAUCET_API}/api/nft/count`)
        const freeNftBalance = await response.json()
        setNoFreeNft(freeNftBalance === 0)
      } catch (e) {
        // e
      }
    }
    // fetchData()
  }, [])

  return (
    <HerroContainer>
      <StyledHeading id="lottery-hero-title">{t('views.lottery.components.hero.heading')}</StyledHeading>
      {getHeroHeading()}
      <ButtonWrapper>
        <StyledBuyTicketButton disabled={ticketBuyIsDisabled} hasFreeNft={!noFreeNft} />
      </ButtonWrapper>
    </HerroContainer>
  )
}

export default Hero
