import styled, { keyframes } from 'styled-components'
import { Box, Heading } from '@pancakeswap/uikit'
import Container from 'components/Layout/Container'

export const LotteryPage = styled.div`
  position: relative;
  min-height: calc(100vh - 64px);
  background-image: url(/images/BG.jpg);
  background-size: cover;
`

export const floatingTicketRight = keyframes`
  from {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(10px, 15px);
  }
  to {
    transform: translate(0, -0px);
  }
`

export const floatingTicketLeft = keyframes`
  from {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(-10px, 15px);
  }
  to {
    transform: translate(0, -0px);
  }
`

export const StarsDecorations0 = styled(Box)`
  position: absolute;
  width: 15%;
  right: 0px;

  & img {
    position: absolute;
  }

  & :nth-child(1) {
    animation: ${floatingTicketRight} 6s ease-in-out infinite;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & :nth-child(1) {
      right: 8%;
      top: 15%;
    }
  }

  ${({ theme }) => theme.mediaQueries.md} {
    & :nth-child(1) {
      right: 17%;
      top: 15%;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    & :nth-child(1) {
      right: 50%;
      top: 5%;
    }
  }
`

export const StarsDecorations1 = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;

  & img {
    position: absolute;
  }

  & :nth-child(1) {
    animation: ${floatingTicketLeft} 6s ease-in-out infinite;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & :nth-child(1) {
      left: 8%;
      top: 7%;
    }
  }

  ${({ theme }) => theme.mediaQueries.md} {
    & :nth-child(1) {
      left: 10%;
      top: 7%;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    & :nth-child(1) {
      left: 10%;
      top: 7%;
    }
  }
`

export const StarsDecorations2 = styled(Box)`
  position: absolute;
  width: 15%;
  right: 0px;

  & :nth-child(1) {
    animation: ${floatingTicketRight} 2s ease-in-out infinite;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & :nth-child(1) {
    }
  }

  ${({ theme }) => theme.mediaQueries.md} {
    & :nth-child(1) {
      right: 10%;
      top: 41%;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    & :nth-child(1) {
      right: 30%;
      top: 41%;
    }
  }
`

export const StarsDecorations3 = styled(Box)`
  position: absolute;

  & :nth-child(1) {
    animation: ${floatingTicketLeft} 6s ease-in-out infinite;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & :nth-child(1) {
      left: 8%;
      top: 7%;
    }
  }

  ${({ theme }) => theme.mediaQueries.md} {
    & :nth-child(1) {
      left: 10%;
      top: 7%;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    & :nth-child(1) {
      left: -5%;
      top: 15%;
    }
  }
`

export const BagDecorations = styled.img`
  float: right;
  position: absolute;
  top: 48px;
  right: 17%;
  height: 200px;
  width: 180px;

  ${({ theme }) => theme.mediaQueries.sm} {
    & :nth-child(1) {
      right: 8%;
      top: 41%;
    }
  }

  ${({ theme }) => theme.mediaQueries.md} {
    & :nth-child(1) {
      right: 10%;
      top: 41%;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    & :nth-child(1) {
      top: 100px;
      right: 20%;
    }
  }
`

export const WrapperContainer = styled(Container)`
  min-height: auto;
`

export const PoollotoCardList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 80px;
  gap: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
`

export const PoollotoCardListItem = styled.li`
  display: inline-flex;
`

export const PoweredBlock = styled.div`
  position: absolute;
  display: inline-flex;
  align-items: center;
  top: 20px;
  left: 70px;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  z-index: 1;
  a {
    color: #e29a48;
  }
  @media (max-width: 768px) {
    left: 20px;
  }
`
export const StyledHeadingSubtitle = styled(Heading)`
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 36px;
  }
`
export const StyledHeading = styled(Heading)`
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 60px;
    line-height: 68px;
  }
`
export const FreeTicketSection = styled.div`
  position: relative;
  padding-top: 60px;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 1;
  ${({ theme }) => theme.mediaQueries.xl} {
    padding-top: 120px;
  }
`
export const HeroSection = styled.div`
  position: relative;
  padding-top: 110px;
  z-index: 0;
`
