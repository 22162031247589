import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}

export const getPltNftAddress = () => {
  return getAddress(addresses.pltNft)
}

export const getFreeNftAddress = () => {
  return getAddress(addresses.freeNft)
}
