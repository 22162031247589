import React, { FC } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import styled, { css } from 'styled-components'
import { ReactComponent as NFTIcon } from 'assets/icons/NFT.svg'
import { useTranslation } from 'react-i18next'

type ListItem = {
  id: number
  key: () => string
  bg: string
  desc: {
    base: string
    nft?: number
  }
}

ChartJS.register(ArcElement, Tooltip)

export const PrizeFunds: FC = () => {
  const { t } = useTranslation()
  const data = {
    labels: [
      t('components.prizeFunds.mutchFirst', { number: 1 }),
      t('components.prizeFunds.mutchFirst', { number: 2 }),
      t('components.prizeFunds.mutchFirst', { number: 3 }),
      t('components.prizeFunds.mutchFirst', { number: 4 }),
      t('components.prizeFunds.mutchFirst', { number: 5 }),
      t('components.prizeFunds.mutchFirst', { number: 6 }),
      t('components.prizeFunds.mutchFirst', { number: 6 }),
    ],
    datasets: [
      {
        label: '# of Votes',
        data: [0.2, 0.5, 3.3, 26, 50, 20],
        backgroundColor: ['#28CD82', '#507CFF', '#FD50FF', '#A5ACCF', '#B34BF2', '#E7EA4E'],
        borderWidth: 0,
      },
    ],
  }

  const listItem: ListItem[] = [
    {
      id: 1,
      key: () => t('components.prizeFunds.mutchFirst', { number: 1 }),
      bg: '#FF9C5C',
      desc: {
        base: '',
        nft: 1,
      },
    },
    {
      id: 2,
      key: () => t('components.prizeFunds.mutchFirst', { number: 2 }),
      bg: '#28CD82',
      desc: {
        base: '0.2%',
        nft: 2,
      },
    },
    {
      id: 3,
      key: () => t('components.prizeFunds.mutchFirst', { number: 3 }),
      bg: '#507CFF',
      desc: {
        base: '0.5%',
      },
    },
    {
      id: 4,
      key: () => t('components.prizeFunds.mutchFirst', { number: 4 }),
      bg: '#FD50FF',
      desc: {
        base: '3.3%',
      },
    },
    {
      id: 5,
      key: () => t('components.prizeFunds.mutchFirst', { number: 5 }),
      bg: '#A5ACCF',
      desc: {
        base: '26%',
      },
    },
    {
      id: 6,
      key: () => t('components.prizeFunds.mutchFirst', { number: 6 }),
      bg: '#B34BF2',
      desc: {
        base: '50%',
      },
    },
    {
      id: 7,
      key: () => t('components.prizeFunds.nextDrawInjection', { number: 7 }),
      bg: '#E7EA4E',
      desc: {
        base: '20%',
      },
    },
  ]

  return (
    <PrizeFundsContainer>
      <DoughnutContainer>
        <Doughnut data={data} />
      </DoughnutContainer>
      <PrizeFundsList>
        <PrizeFundsListItemHeader>
          <div>{t('components.prizeFunds.digitsMatched')}</div>
          <div>{t('components.prizeFunds.prizePoolAllocation')}</div>
        </PrizeFundsListItemHeader>

        {listItem.map((item: ListItem) => {
          return (
            <PrizeFundsListItem key={item.id}>
              <ItemKey bg={item.bg}>{item.key()}</ItemKey>
              <ItemDesc>
                <span>{item.desc.base}</span>
                {item.desc?.nft ? (
                  <>
                    {item.desc.base ? <>&nbsp;+&nbsp;</> : null}{' '}
                    <span style={{ color: '#EC9A44' }}>{item.desc?.nft}</span>&nbsp;
                    <NFTIcon />
                  </>
                ) : null}
              </ItemDesc>
            </PrizeFundsListItem>
          )
        })}
      </PrizeFundsList>
      <PrizeFundsDesc>
        <NFTIcon />
        <span>{t('components.prizeFunds.description')}</span>
      </PrizeFundsDesc>
    </PrizeFundsContainer>
  )
}

const PrizeFundsDesc = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 20px;
  color: #a5accf;
  font-size: 10px;
  line-height: 1.2;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    background: #575a6c;
    height: 1px;
  }

  * + span {
    margin-left: 8px;
  }
  svg {
    width: 12px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 14px;
    svg {
      width: auto;
    }
  }
`

const ItemDesc = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  svg {
    width: 12px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    svg {
      width: auto;
    }
  }
`
const ItemKey = styled.div<{ bg: string }>`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 32px;
  font-weight: 400;

  &:before {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    left: 0;
    border-radius: 50%;
    ${(props) => {
      return css`
        background-color: ${props.bg};
      `
    }}
  }
`
const PrizeFundsListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 18px;
    line-height: 22px;
  }
`
const PrizeFundsListItemHeader = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  div {
    font-weight: 500;
    &:nth-child(2) {
      text-align: right;
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 18px;
    line-height: 22px;
  }
`

const PrizeFundsList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: initial;
  margin: initial;
  width: 100%;
  color: #fff;
  li + li {
    margin-top: 20px;
  }
`
const DoughnutContainer = styled.div`
  width: 100px;
  height: 100px;
  margin: auto;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 140px;
    height: 140px;
  }
`

const PrizeFundsContainer = styled.div`
  width: 100%;
  max-width: 495px;
  background: #292d3f;
  border-radius: 12px;
  padding: 24px 20px 20px;
  * + ${PrizeFundsList} {
    margin-top: 40px;
  }
  * + ${PrizeFundsDesc} {
    margin-top: 20px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 30px 40px 40px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    min-width: 400px;
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    min-width: 495px;
  }
`
