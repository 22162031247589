import React from 'react'
import styled from 'styled-components'
import { CardBody, Heading, Flex, Skeleton, Text, Box, Button, useModal, useMatchBreakpoints } from '@pancakeswap/uikit'
import { LotteryRound } from 'state/types'
import { useGetUserLotteriesGraphData } from 'state/lottery/hooks'
import { useTranslation } from 'react-i18next'
import { ReactComponent as NFTIcon } from 'assets/icons/NFT.svg'
import WinningNumbers from '../WinningNumbers'
import ViewTicketsModal from '../ViewTicketsModal'

const StyledCardBody = styled(CardBody)`
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background: #292d3f;
  border-top: 1px solid #575a6c;
`

const PreviousRoundCardBody: React.FC<{ lotteryNodeData: LotteryRound; lotteryId: string }> = ({
  lotteryNodeData,
  lotteryId,
}) => {
  const { t } = useTranslation()
  const userLotteryData = useGetUserLotteriesGraphData()
  const userDataForRound = userLotteryData.rounds.find((userLotteryRound) => userLotteryRound.lotteryId === lotteryId)
  const { isLg, isXl, isXxl } = useMatchBreakpoints()
  const isLargerScreen = isLg || isXl || isXxl

  const [onPresentViewTicketsModal] = useModal(
    <ViewTicketsModal roundId={lotteryId} roundStatus={lotteryNodeData?.status} />,
  )

  const totalTicketNumber = userDataForRound ? userDataForRound.totalTickets : 0
  const ticketRoundText =
    totalTicketNumber > 1
      ? t('views.lottery.components.previousRoundCard.tickets', { amount: totalTicketNumber })
      : t('views.lottery.components.previousRoundCard.ticket', { amount: totalTicketNumber })
  const [youHadText, ticketsThisRoundText] = ticketRoundText.split(totalTicketNumber.toString())

  return (
    <StyledCardBody>
      <Heading textAlign="center" color="#fff" mb="24px">
        {t('views.lottery.components.previousRoundCard.winningNumber')}
      </Heading>
      <Flex justifyContent="center" mb="24px">
        {lotteryNodeData ? (
          <WinningNumbers
            number={lotteryNodeData?.finalNumber.toString()}
            mr={[null, null, null, '32px']}
            size={isLargerScreen ? '64px' : '32px'}
            fontSize={isLargerScreen ? '32px' : '16px'}
            isLargerScreen
          />
        ) : (
          <Skeleton
            width={['240px', null, null, '450px']}
            height={['34px', null, null, '71px']}
            mr={[null, null, null, '32px']}
          />
        )}
      </Flex>
      {userDataForRound && (
        <>
          <Box display={['none', null, null, 'flex']}>
            <Heading color="#fff" />
          </Box>
          <Flex
            flexDirection="column"
            mr={[null, null, null, '24px']}
            alignItems={['center', null, null, 'flex-start']}
          >
            <Box mt={['32px', null, null, 0]}>
              <Text color="#fff" display="inline">
                {youHadText}{' '}
              </Text>
              <Text color="#fff" display="inline" bold>
                {userDataForRound.totalTickets}
              </Text>
              <Text color="#fff" display="inline">
                {ticketsThisRoundText}
              </Text>
            </Box>
            <Button
              onClick={onPresentViewTicketsModal}
              height="auto"
              width="fit-content"
              p="0"
              variant="text"
              scale="sm"
            >
              {t('views.lottery.components.previousRoundCard.btn')}
            </Button>
            {userDataForRound.totalNfts ? (
              <Flex marginTop="5px" alignItems="center">
                <Text fontSize="20px" bold color="#EC9A44">
                  &nbsp;+&nbsp;
                </Text>
                <Text fontSize="20px" bold color="#EC9A44" marginRight="4px">
                  {userDataForRound.totalNfts}
                </Text>
                <NFTIcon />
              </Flex>
            ) : null}
          </Flex>
        </>
      )}
    </StyledCardBody>
  )
}

export default PreviousRoundCardBody
