import React, { useEffect, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { Modal, Text, Flex, HelpIcon, BalanceInput, useTooltip, Skeleton, Button, Checkbox } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'

import tokens from 'config/constants/tokens'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { BIG_ZERO, ethersToBigNumber } from 'utils/bigNumber'
import { ReactComponent as NFTIcon } from 'assets/icons/NFT.svg'
import { useAppDispatch } from 'state'
import { useLottery } from 'state/lottery/hooks'
import { fetchUserTicketsAndLotteries } from 'state/lottery'
import useTokenBalance, { FetchStatus } from 'hooks/useTokenBalance'
import useNft from 'hooks/useNft'
import useApproveConfirmTransaction from 'hooks/useApproveConfirmTransaction'
import { useCake, useLotteryV2Contract, usePltNFTContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { ToastDescriptionWithTx } from 'components/Toast'
import ApproveConfirmButtons, { ButtonArrangement } from 'components/ApproveConfirmButtons'
import NumTicketsToBuyButton from './NumTicketsToBuyButton'
import EditNumbersModal from './EditNumbersModal'
import { useTicketsReducer } from './useTicketsReducer'
import { useAuth } from 'hooks/useAuth'

const StyledModal = styled(Modal)`
  min-width: 280px;
  max-width: 320px;

  .sc-biJonm,
  .sc-gIvpjk {
    color: #fff;
    h2 {
      color: currentColor;
    }
    button {
      color: currentColor;
    }
    svg {
      fill: currentColor;
    }
  }
`

const StyledBalanceInput = styled(BalanceInput)`
  color: #a5accf;
  * {
    color: currentColor;
  }
`

const StyledButton = styled(Button)`
  border-color: #b67122 !important;
  border-width: 2px !important;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.03em;
  padding: 18px 0px;
  color: #fff !important;
  &:hover {
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`

const ShortcutButtonsWrapper = styled(Flex)<{ isVisible: boolean }>`
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 24px;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`

const StyledNumTicketsToBuyButton = styled(NumTicketsToBuyButton)`
  border-color: #11cabe !important;
  border-width: 2px !important;
  border: 2px solid !important;
  background: transparent !important;
  color: #fff !important;
`

interface BuyTicketsModalProps {
  onDismiss?: () => void
}

enum BuyingStage {
  BUY = 'Buy',
  EDIT = 'Edit',
}

const BuyTicketsModal: React.FC<BuyTicketsModalProps> = ({ onDismiss }) => {
  const { account } = useAuth()
  const { t, i18n } = useTranslation()
  const {
    maxNumberTicketsPerBuyOrClaim,
    currentLotteryId,
    currentRound: {
      priceTicketInCake,
      discountDivisor,
      endTime,
      userTickets: { tickets: userCurrentTickets },
    },
  } = useLottery()
  const [ticketsToBuy, setTicketsToBuy] = useState('1')
  const [discountValue, setDiscountValue] = useState('')
  const [totalCost, setTotalCost] = useState('')
  const [ticketCostBeforeDiscount, setTicketCostBeforeDiscount] = useState('')
  const [buyingStage, setBuyingStage] = useState<BuyingStage>(BuyingStage.BUY)
  const [maxPossibleTicketPurchase, setMaxPossibleTicketPurchase] = useState(BIG_ZERO)
  const [maxTicketPurchaseExceeded, setMaxTicketPurchaseExceeded] = useState(false)
  const [userNotEnoughCake, setUserNotEnoughCake] = useState(false)
  const [withFreeTickets, setWithFreeTickets] = useState(false)
  const { balance: nftBalance, getNftBalance } = useNft()
  const { toastError } = useToast()

  const lotteryContract = useLotteryV2Contract()
  const cakeContract = useCake()
  const nftContract = usePltNFTContract()
  const { toastSuccess } = useToast()
  const { balance: userPLT, fetchStatus } = useTokenBalance(tokens.plt.address)
  // balance from useTokenBalance causes rerenders in effects as a new BigNumber is instantiated on each render, hence memoising it using the stringified value below.
  const stringifiedUserCake = userPLT.toJSON()
  const memoisedUserCake = useMemo(() => new BigNumber(stringifiedUserCake), [stringifiedUserCake])

  const dispatch = useAppDispatch()
  const hasFetchedBalance = fetchStatus === FetchStatus.SUCCESS
  const userCakeDisplayBalance = getFullDisplayBalance(userPLT, 18, 3)

  const TooltipComponent = () => (
    <>
      <Text mb="16px">{t('views.lottery.components.buyTicketsModal.tooltip.mainText')}</Text>
      <Text>
        {i18n.t('views.lottery.components.buyTicketsModal.tooltip.tickets', {
          ticketsCount: '2',
          ticketsPersent: '0.05%',
        })}
      </Text>
      <Text>
        {i18n.t('views.lottery.components.buyTicketsModal.tooltip.tickets', {
          ticketsCount: '50',
          ticketsPersent: '2.45%',
        })}
      </Text>
      <Text>
        {i18n.t('views.lottery.components.buyTicketsModal.tooltip.tickets', {
          ticketsCount: '100',
          ticketsPersent: '4.95%',
        })}
      </Text>
    </>
  )
  const { targetRef, tooltip, tooltipVisible } = useTooltip(<TooltipComponent />, {
    placement: 'bottom-end',
    tooltipOffset: [20, 10],
  })

  const limitNumberByMaxTicketsPerBuy = useCallback(
    (number: BigNumber) => {
      return number.gt(maxNumberTicketsPerBuyOrClaim) ? maxNumberTicketsPerBuyOrClaim : number
    },
    [maxNumberTicketsPerBuyOrClaim],
  )

  const getTicketCostAfterDiscount = useCallback(
    (numberTickets: BigNumber) => {
      const totalAfterDiscount = priceTicketInCake
        .times(numberTickets)
        .times(discountDivisor.plus(1).minus(numberTickets))
        .div(discountDivisor)
      return totalAfterDiscount
    },
    [discountDivisor, priceTicketInCake],
  )

  const getMaxTicketBuyWithDiscount = useCallback(
    (numberTickets: BigNumber) => {
      const costAfterDiscount = getTicketCostAfterDiscount(numberTickets)
      const costBeforeDiscount = priceTicketInCake.times(numberTickets)
      const discountAmount = costBeforeDiscount.minus(costAfterDiscount)
      const ticketsBoughtWithDiscount = discountAmount.div(priceTicketInCake)
      const overallTicketBuy = numberTickets.plus(ticketsBoughtWithDiscount)
      return { overallTicketBuy, ticketsBoughtWithDiscount }
    },
    [getTicketCostAfterDiscount, priceTicketInCake],
  )

  const validateInput = useCallback(
    (inputNumber: BigNumber) => {
      if (!hasFetchedBalance) return

      const limitedNumberTickets = limitNumberByMaxTicketsPerBuy(inputNumber)
      const cakeCostAfterDiscount = getTicketCostAfterDiscount(limitedNumberTickets)

      if (cakeCostAfterDiscount.gt(userPLT)) {
        setUserNotEnoughCake(true)
      } else if (limitedNumberTickets.isGreaterThan(maxNumberTicketsPerBuyOrClaim)) {
        setMaxTicketPurchaseExceeded(true)
      } else {
        setUserNotEnoughCake(false)
        setMaxTicketPurchaseExceeded(false)
      }
    },
    [
      limitNumberByMaxTicketsPerBuy,
      getTicketCostAfterDiscount,
      userPLT,
      maxNumberTicketsPerBuyOrClaim,
      hasFetchedBalance,
    ],
  )

  const handleWithNftChange = useCallback(() => {
    setWithFreeTickets(!withFreeTickets)
  }, [withFreeTickets])

  useEffect(() => {
    const getMaxPossiblePurchase = () => {
      const maxBalancePurchase = memoisedUserCake.div(priceTicketInCake)
      const limitedMaxPurchase = limitNumberByMaxTicketsPerBuy(maxBalancePurchase)
      let maxPurchase

      // If the users' max PLT balance purchase is less than the contract limit - factor the discount logic into the max number of tickets they can purchase
      if (limitedMaxPurchase.lt(maxNumberTicketsPerBuyOrClaim)) {
        // Get max tickets purchasable with the users' balance, as well as using the discount to buy tickets
        const { overallTicketBuy: maxPlusDiscountTickets } = getMaxTicketBuyWithDiscount(limitedMaxPurchase)

        // Knowing how many tickets they can buy when counting the discount - plug that total in, and see how much that total will get discounted
        const { ticketsBoughtWithDiscount: secondTicketDiscountBuy } =
          getMaxTicketBuyWithDiscount(maxPlusDiscountTickets)

        // Add the additional tickets that can be bought with the discount, to the original max purchase
        maxPurchase = limitedMaxPurchase.plus(secondTicketDiscountBuy)
      } else {
        maxPurchase = limitedMaxPurchase
      }

      if (hasFetchedBalance && maxPurchase.lt(1)) {
        setUserNotEnoughCake(true)
      } else {
        setUserNotEnoughCake(false)
      }

      setMaxPossibleTicketPurchase(maxPurchase)
    }
    getMaxPossiblePurchase()
  }, [
    maxNumberTicketsPerBuyOrClaim,
    priceTicketInCake,
    memoisedUserCake,
    limitNumberByMaxTicketsPerBuy,
    getTicketCostAfterDiscount,
    getMaxTicketBuyWithDiscount,
    hasFetchedBalance,
  ])

  useEffect(() => {
    const numberOfTicketsToBuy = new BigNumber(withFreeTickets ? Number(ticketsToBuy) - nftBalance : ticketsToBuy)
    const costAfterDiscount = getTicketCostAfterDiscount(numberOfTicketsToBuy)
    const costBeforeDiscount = priceTicketInCake.times(numberOfTicketsToBuy)
    const discountBeingApplied = costBeforeDiscount.minus(costAfterDiscount)
    setTicketCostBeforeDiscount(costBeforeDiscount.gt(0) ? getFullDisplayBalance(costBeforeDiscount) : '0')
    setTotalCost(costAfterDiscount.gt(0) ? getFullDisplayBalance(costAfterDiscount) : '0')
    setDiscountValue(discountBeingApplied.gt(0) ? getFullDisplayBalance(discountBeingApplied, 18, 5) : '0')
    validateInput(numberOfTicketsToBuy)
  }, [
    ticketsToBuy,
    priceTicketInCake,
    discountDivisor,
    getTicketCostAfterDiscount,
    validateInput,
    withFreeTickets,
    nftBalance,
  ])

  const getNumTicketsByPercentage = (percentage: number): number => {
    const percentageOfMaxTickets = maxPossibleTicketPurchase.gt(0)
      ? maxPossibleTicketPurchase.div(new BigNumber(100)).times(new BigNumber(percentage))
      : BIG_ZERO
    return Math.floor(percentageOfMaxTickets.toNumber())
  }

  const tenPercentOfBalance = getNumTicketsByPercentage(10)
  const twentyFivePercentOfBalance = getNumTicketsByPercentage(25)
  const fiftyPercentOfBalance = getNumTicketsByPercentage(50)
  const oneHundredPercentOfBalance = getNumTicketsByPercentage(100)

  const handleInputChange = (input: string) => {
    // Force input to integer
    const inputAsInt = parseInt(input, 10)
    const inputAsBN = new BigNumber(inputAsInt)
    const limitedNumberTickets = limitNumberByMaxTicketsPerBuy(inputAsBN)
    setTicketsToBuy(inputAsInt ? limitedNumberTickets.toString() : '')
  }

  const handleNumberButtonClick = (number: number) => {
    setTicketsToBuy(number.toFixed())
    setUserNotEnoughCake(false)
    setMaxTicketPurchaseExceeded(false)
  }

  const [updateTicket, randomize, tickets, allComplete, getTicketsForPurchase] = useTicketsReducer(
    parseInt(ticketsToBuy, 10),
    userCurrentTickets,
  )

  const {
    isApproving,
    isApproved,
    isApprovedNft,
    isApprovingNft,
    isConfirmed,
    isConfirming,
    handleApprove,
    handleNftApprove,
    handleConfirm,
  } = useApproveConfirmTransaction({
    onRequiresApproval: async () => {
      try {
        const response = await cakeContract.allowance(account, lotteryContract.address)
        const currentAllowance = ethersToBigNumber(response)
        return currentAllowance.gt(0)
      } catch (error) {
        return false
      }
    },
    onRequiresNftApproval: async () => {
      try {
        const response = await nftContract.isApprovedForAll(account, lotteryContract.address)
        return response
      } catch (error) {
        return false
      }
    },
    onApprove: () => {
      return cakeContract.approve(lotteryContract.address, ethers.constants.MaxUint256)
    },
    onApproveSuccess: async ({ receipt }) => {
      toastSuccess(
        t('views.lottery.components.buyTicketsModal.statusTransaction.approve'),
        <ToastDescriptionWithTx txHash={receipt.transactionHash} />,
      )
    },
    onNftApprove: async () => {
      if (!isApproved) await handleApprove()
      return nftContract.setApprovalForAll(lotteryContract.address, true)
    },
    onNftApproveSuccess: async ({ receipt }) => {
      toastSuccess(
        t('views.lottery.components.buyTicketsModal.statusTransaction.approveNFT'),
        <ToastDescriptionWithTx txHash={receipt.transactionHash} />,
      )
    },
    onConfirm: () => {
      const ticketsForPurchase = getTicketsForPurchase()
      return lotteryContract.buyTickets(currentLotteryId, ticketsForPurchase, withFreeTickets, '')
    },
    onSuccess: async ({ receipt }) => {
      getNftBalance()
      onDismiss()
      dispatch(fetchUserTicketsAndLotteries({ account, currentLotteryId }))
      toastSuccess(
        t('views.lottery.components.buyTicketsModal.statusTransaction.success'),
        <ToastDescriptionWithTx id="successful-purchase" txHash={receipt.transactionHash} />,
      )
    },
  })

  const getErrorMessage = () => {
    if (userNotEnoughCake) return t('views.lottery.components.buyTicketsModal.errorMessage.userNotEnoughCake')
    return t('views.lottery.components.buyTicketsModal.errorMessage.default', {
      maxTickets: maxNumberTicketsPerBuyOrClaim.toString(),
    })
  }

  const percentageDiscount = () => {
    const percentageAsBn = new BigNumber(discountValue).div(new BigNumber(ticketCostBeforeDiscount)).times(100)
    if (percentageAsBn.isNaN() || percentageAsBn.eq(0)) {
      return 0
    }
    return percentageAsBn.toNumber().toFixed(2)
  }

  const handleBuy = () => {
    const isEnded = +new Date() / 1000 > Number(endTime)

    if (isEnded) {
      toastError(t('Error'), t('views.lottery.components.buyTicketsModal.errorMessage.lotteryOver'))
      return null
    }
    return handleConfirm()
  }

  const disableBuying =
    !isApproved ||
    isConfirmed ||
    userNotEnoughCake ||
    !ticketsToBuy ||
    new BigNumber(ticketsToBuy).lte(0) ||
    getTicketsForPurchase().length !== parseInt(ticketsToBuy, 10)

  if (buyingStage === BuyingStage.EDIT) {
    return (
      <EditNumbersModal
        totalCost={totalCost}
        updateTicket={updateTicket}
        randomize={randomize}
        tickets={tickets}
        allComplete={allComplete}
        onConfirm={handleBuy}
        isConfirming={isConfirming}
        withFreeTickets={withFreeTickets}
        onDismiss={() => setBuyingStage(BuyingStage.BUY)}
      />
    )
  }

  return (
    <StyledModal
      title={t('views.lottery.components.buyTicketsModal.title')}
      onDismiss={onDismiss}
      headerBackground="#40455C"
      style={{ background: '#292D3F', border: 'none' }}
    >
      {tooltipVisible && tooltip}
      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Text color="#fff">{t('views.lottery.components.buyTicketsModal.title')}:</Text>
      </Flex>
      <StyledBalanceInput
        isWarning={account && (userNotEnoughCake || maxTicketPurchaseExceeded)}
        placeholder="0"
        style={{ background: '#40455C', boxShadow: 'none' }}
        value={ticketsToBuy}
        onUserInput={handleInputChange}
        currencyValue={`~${
          ticketsToBuy ? getFullDisplayBalance(priceTicketInCake.times(new BigNumber(ticketsToBuy))) : '0.00'
        } PLT`}
      />
      <Flex alignItems="center" justifyContent="flex-end" mt="4px" mb="12px">
        <Flex justifyContent="flex-end" flexDirection="column">
          {account && (userNotEnoughCake || maxTicketPurchaseExceeded) && (
            <Text fontSize="12px" color="failure">
              {getErrorMessage()}
            </Text>
          )}
          {account && (
            <Flex justifyContent="flex-end">
              <Text fontSize="12px" color="#fff" mr="4px">
                PLT {t('views.lottery.components.buyTicketsModal.balance')}:
              </Text>
              {hasFetchedBalance ? (
                <Text fontSize="12px" color="#fff">
                  {userCakeDisplayBalance}
                </Text>
              ) : (
                <Skeleton width={50} height={12} />
              )}
            </Flex>
          )}
        </Flex>
      </Flex>

      {account && !hasFetchedBalance ? (
        <Skeleton width="100%" height={20} mt="8px" mb="24px" />
      ) : (
        <ShortcutButtonsWrapper isVisible={account && hasFetchedBalance && oneHundredPercentOfBalance >= 1}>
          {tenPercentOfBalance >= 1 && (
            <StyledNumTicketsToBuyButton onClick={() => handleNumberButtonClick(tenPercentOfBalance)}>
              {hasFetchedBalance ? tenPercentOfBalance : ``}
            </StyledNumTicketsToBuyButton>
          )}
          {twentyFivePercentOfBalance >= 1 && (
            <StyledNumTicketsToBuyButton onClick={() => handleNumberButtonClick(twentyFivePercentOfBalance)}>
              {hasFetchedBalance ? twentyFivePercentOfBalance : ``}
            </StyledNumTicketsToBuyButton>
          )}
          {fiftyPercentOfBalance >= 1 && (
            <StyledNumTicketsToBuyButton onClick={() => handleNumberButtonClick(fiftyPercentOfBalance)}>
              {hasFetchedBalance ? fiftyPercentOfBalance : ``}
            </StyledNumTicketsToBuyButton>
          )}
          {oneHundredPercentOfBalance >= 1 && (
            <StyledNumTicketsToBuyButton onClick={() => handleNumberButtonClick(oneHundredPercentOfBalance)}>
              MAX
            </StyledNumTicketsToBuyButton>
          )}
        </ShortcutButtonsWrapper>
      )}
      <Flex flexDirection="column">
        <Flex mb="8px" justifyContent="space-between">
          <Text color="#fff" fontSize="14px">
            {t('views.lottery.components.buyTicketsModal.cost')} (PLT)
          </Text>
          <Text color="#fff" fontSize="14px">
            {withFreeTickets
              ? priceTicketInCake &&
                getFullDisplayBalance(
                  priceTicketInCake.times(
                    Number(ticketsToBuy) - nftBalance > 0 ? Number(ticketsToBuy) - nftBalance : 0,
                  ),
                )
              : priceTicketInCake && getFullDisplayBalance(priceTicketInCake.times(ticketsToBuy || 0))}{' '}
            PLT
          </Text>
        </Flex>
        <Flex mb="8px" justifyContent="space-between">
          <Flex>
            <Text color="#fff" display="inline" bold fontSize="14px" mr="4px">
              {discountValue && totalCost ? percentageDiscount() : 0}%
            </Text>
            <Text display="inline" color="#fff" fontSize="14px">
              {t('views.lottery.components.buyTicketsModal.bulkDiscount')}
            </Text>
            <Flex alignItems="center" justifyContent="center" ref={targetRef}>
              <HelpIcon ml="4px" width="14px" height="14px" color="textSubtle" />
            </Flex>
          </Flex>
          <Text fontSize="14px" color="#fff">
            ~{discountValue} PLT
          </Text>
        </Flex>
        <Flex mb="30px" pt="30px" borderTop="1px solid #A5ACCF" justifyContent="space-between">
          <Flex alignItems="center">
            <Checkbox
              scale="sm"
              name="md"
              value="one"
              id="use-nft"
              onChange={handleWithNftChange}
              checked={withFreeTickets}
              disabled={nftBalance < 1}
            />
            <Text fontSize="14px" color={nftBalance < 1 ? '#BDC2C4' : '#fff'} marginLeft="15px">
              {t('views.lottery.components.buyTicketsModal.useNFT')}
            </Text>
          </Flex>
          <Flex alignItems="center">
            <NFTIcon />
            <Text fontSize="14px" color="#fff" marginLeft="8px">
              {nftBalance}
            </Text>
          </Flex>
        </Flex>
        <Flex borderTop="1px solid #A5ACCF" pt="8px" mb="24px" justifyContent="space-between">
          <Text color="#fff" fontSize="16px">
            {t('views.lottery.components.buyTicketsModal.youPay')}
          </Text>
          <Text fontSize="16px" bold color="#fff">
            ~{totalCost} PLT
          </Text>
        </Flex>

        {account ? (
          <>
            <ApproveConfirmButtons
              isApproveDisabled={withFreeTickets ? isApprovedNft : isApproved}
              isApproving={withFreeTickets ? isApprovingNft : isApproving}
              isConfirmDisabled={disableBuying}
              isConfirming={isConfirming}
              onApprove={withFreeTickets ? handleNftApprove : handleApprove}
              onConfirm={handleBuy}
              buttonArrangement={ButtonArrangement.SEQUENTIAL}
              confirmLabel={t('views.lottery.components.buyTicketsModal.buyInstantly')}
              confirmId="lotteryBuyInstant"
            />
            {isApproved && (
              <StyledButton
                variant="secondary"
                mt="8px"
                disabled={disableBuying || isConfirming}
                onClick={() => {
                  setBuyingStage(BuyingStage.EDIT)
                }}
              >
                {t('views.lottery.components.buyTicketsModal.viewEditNumbers')}
              </StyledButton>
            )}
          </>
        ) : (
          <ConnectWalletButton />
        )}

        <StyledButton
          variant="secondary"
          mt="8px"
          id="modal-get-plt"
          onClick={() => {
            window.open('https://app.dodoex.io/?from=BNB&to=PLT', '_blank')
          }}
        >
          {t('views.lottery.components.buyTicketsModal.getPLT')}
        </StyledButton>
        <Text mt="24px" fontSize="12px" color="#fff" id="buy-ticket">
          {t('views.lottery.components.buyTicketsModal.text')}
        </Text>
      </Flex>
    </StyledModal>
  )
}

export default BuyTicketsModal
