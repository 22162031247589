export interface SocialLink {
  key: string
  url: string
  name: string
  via?: string
}

export const SocialLinkAdresses: Array<SocialLink> = [
  {
    key: 'twitter',
    name: 'Twitter',
    url: 'https://twitter.com/intent/tweet?',
    via: 'pltfinance',
  },
  {
    key: 'facebook',
    name: 'Facebook',
    url: 'https://www.facebook.com/sharer.php?u=',
  },
]
