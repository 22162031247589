import { useMemo } from 'react'
import { getPLTContract, getTradingCompetitionContract, getLotteryV2Contract } from 'utils/contractHelpers'
import { getMulticallAddress, getPltNftAddress, getFreeNftAddress } from 'utils/addressHelpers'

// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import { ChainId, WETH } from '@pancakeswap/sdk'
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json'
import ENS_ABI from '../config/abi/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import WETH_ABI from '../config/abi/weth.json'
import multiCallAbi from '../config/abi/Multicall.json'
import pltNftAbi from '../config/abi/pltNFT.json'
import freeNftAbi from '../config/abi/freeNFT.json'
import { getContract } from '../utils'
import { useAuth } from './useAuth'

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */

export const useCake = () => {
  const { signer } = useAuth()
  return useMemo(() => getPLTContract(signer), [signer])
}

export const useLotteryV2Contract = () => {
  const { signer } = useAuth()
  return useMemo(() => getLotteryV2Contract(signer), [signer])
}

export const useTradingCompetitionContract = () => {
  const { signer } = useAuth()
  return useMemo(() => getTradingCompetitionContract(signer), [signer])
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { ethersProvider, account } = useAuth()

  return useMemo(() => {
    if (!address || !ABI || !ethersProvider) return null
    try {
      return getContract(address, ABI, ethersProvider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, ethersProvider, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useAuth()
  return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useAuth()
  let address: string | undefined
  if (chainId) {
    // eslint-disable-next-line default-case
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.TESTNET:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  return useContract(getMulticallAddress(), multiCallAbi, false)
}

export function usePltNFTContract(): Contract | null {
  return useContract(getPltNftAddress(), pltNftAbi, true)
}

export function useFreeNFTContract(): Contract | null {
  return useContract(getFreeNftAddress(), freeNftAbi, true)
}
