export const publicKeyShortenerHelper = (publicKey: string, start = 5, end = 5) => {
  if (!publicKey) {
    return publicKey
  }
  if (!publicKey.startsWith('0x')) {
    // eslint-disable-next-line no-param-reassign
    publicKey = `0x${publicKey}`
  }

  return `${publicKey.slice(0, start)} ... ${publicKey.slice(-end)}`
}
