import { useContext } from 'react'
import { NFTContext } from 'contexts'

const useNft = () => {
  const nftContext = useContext(NFTContext)

  if (nftContext === undefined) {
    throw new Error('NFT context undefined')
  }

  return nftContext
}

export default useNft
