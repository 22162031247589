import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'state'
import { useFetchLeaderboard, useGetLeaderboardAllTimeGraphData } from 'state/leaderboard/hooks'
import { fetchLeaderboardAllTime } from 'state/leaderboard'
import CircleLoader from 'components/Loader/CircleLoader'
import { LeaderboardTitle } from './Title'
import { LeaderboardTable } from './Table'

export const Leaderboard: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [countUpdate, setCountUpdate] = useState<number>(0)
  const isMobile: boolean = width <= 851

  useFetchLeaderboard()

  useEffect(() => {
    const interval = setInterval(() => {
      setCountUpdate((count: number) => count + 1)
      dispatch(fetchLeaderboardAllTime())
    }, 10000)
    return () => clearInterval(interval)
  }, [countUpdate, dispatch])

  const { leaderboardAllTimeData } = useGetLeaderboardAllTimeGraphData()

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return (
    <LeaderboardWrapper>
      <LeaderboardTitle title={t('components.leaderboard.title')} />
      {leaderboardAllTimeData ? (
        <LeaderboardTable data={leaderboardAllTimeData} isMobile={isMobile} />
      ) : (
        <CircleLoader />
      )}
    </LeaderboardWrapper>
  )
}

export const LeaderboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding-left: 16px;
  padding-right: 16px;
`
