/* eslint-disable no-console */
import React, { useRef, useState } from 'react'

import { Flex, Text, AutoRenewIcon } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'
import { LotteryTicket, LotteryTicketClaimData } from 'config/constants/types'
import { getBalanceAmount } from 'utils/formatBalance'
import { useLottery } from 'state/lottery/hooks'
import { fetchUserLotteries } from 'state/lottery'
import { useGasPrice } from 'state/user/hooks'
import { useAppDispatch } from 'state'
import { useLotteryV2Contract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import useNft from 'hooks/useNft'
import { ToastDescriptionWithTx } from 'components/Toast'
import Balance from 'components/Balance'
import { ReactComponent as FacebookIcon } from 'assets/icons/shared/Facebook.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/shared/Twitter.svg'
import { ReactComponent as NFTIcon } from 'assets/icons/NFT.svg'
import { SocialLinkAdresses, SocialLink } from 'config/constants/socialLinks'
import html2canvas from 'html2canvas'
import { usePricePLT } from '../../hooks/usePricePLT'
import { SharedButton, SharedList, SharedListItem, StyledButton } from './styled'
import { useAuth } from 'hooks/useAuth'

interface ClaimInnerProps {
  roundsToClaim: LotteryTicketClaimData[]
  onSuccess?: () => void
  freeNftReward?: number
}

const ClaimInnerContainer: React.FC<ClaimInnerProps> = ({ onSuccess, roundsToClaim, freeNftReward }) => {
  const { account } = useAuth()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { maxNumberTicketsPerBuyOrClaim, currentLotteryId } = useLottery()
  const gasPrice = useGasPrice()
  const { getNftBalance } = useNft()
  const { toastSuccess } = useToast()
  const printRef = useRef()
  const [activeClaimIndex, setActiveClaimIndex] = useState(0)
  const [pendingTx, setPendingTx] = useState(false)
  const [pendingBatchClaims, setPendingBatchClaims] = useState(
    Math.ceil(
      roundsToClaim[activeClaimIndex].ticketsWithUnclaimedRewards.length / maxNumberTicketsPerBuyOrClaim.toNumber(),
    ),
  )
  const lotteryContract = useLotteryV2Contract()
  const activeClaimData = roundsToClaim[activeClaimIndex]

  const pltPrice = usePricePLT()
  const { cakeTotal, nftTotal } = activeClaimData
  const dollarReward = cakeTotal.times(pltPrice)
  const rewardAsBalance = getBalanceAmount(cakeTotal).toNumber()
  const dollarRewardAsBalance = getBalanceAmount(dollarReward).toNumber()
  const parseUnclaimedTicketDataForClaimCall = (ticketsWithUnclaimedRewards: LotteryTicket[], lotteryId: string) => {
    const ticketIds = ticketsWithUnclaimedRewards.map((ticket) => {
      return ticket.id
    })
    const brackets = ticketsWithUnclaimedRewards.map((ticket) => {
      return ticket.rewardBracket
    })
    return { lotteryId, ticketIds, brackets }
  }

  const claimTicketsCallData = parseUnclaimedTicketDataForClaimCall(
    activeClaimData.ticketsWithUnclaimedRewards,
    activeClaimData.roundId,
  )

  const shouldBatchRequest = maxNumberTicketsPerBuyOrClaim.lt(claimTicketsCallData.ticketIds.length)

  const handleProgressToNextClaim = () => {
    if (roundsToClaim.length > activeClaimIndex + 1) {
      // If there are still rounds to claim, move onto the next claim
      setActiveClaimIndex(activeClaimIndex + 1)
      dispatch(fetchUserLotteries({ account, currentLotteryId }))
    } else {
      onSuccess()
    }
  }

  const getTicketBatches = (ticketIds: string[], brackets: number[]): { ticketIds: string[]; brackets: number[] }[] => {
    const requests = []
    const maxAsNumber = maxNumberTicketsPerBuyOrClaim.toNumber()

    for (let i = 0; i < ticketIds.length; i += maxAsNumber) {
      const ticketIdsSlice = ticketIds.slice(i, maxAsNumber + i)
      const bracketsSlice = brackets.slice(i, maxAsNumber + i)
      requests.push({ ticketIds: ticketIdsSlice, brackets: bracketsSlice })
    }

    return requests
  }

  const handleClaim = async () => {
    const { lotteryId, ticketIds, brackets } = claimTicketsCallData
    setPendingTx(true)
    try {
      const tx = await lotteryContract.claimTickets(lotteryId, ticketIds, brackets, { gasPrice })
      const receipt = await tx.wait()
      if (receipt.status) {
        toastSuccess(
          t('views.lottery.components.claimPrizesModal.toastSuccess.title'),
          <ToastDescriptionWithTx txHash={receipt.transactionHash}>
            {t('views.lottery.components.claimPrizesModal.toastSuccess.description', { lotteryId })}
          </ToastDescriptionWithTx>,
        )
        setPendingTx(false)
        handleProgressToNextClaim()
      }
    } catch (error) {
      console.error(error)
      setPendingTx(false)
    }

    await fetch(`${process.env.REACT_APP_NFT_FAUCET_API}/api/nft/reward`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ account }),
    })

    getNftBalance()
  }

  const handleBatchClaim = async () => {
    const { lotteryId, ticketIds, brackets } = claimTicketsCallData
    const ticketBatches = getTicketBatches(ticketIds, brackets)
    const transactionsToFire = ticketBatches.length
    const receipts = []
    setPendingTx(true)
    for (const ticketBatch of ticketBatches) {
      try {
        const tx = await lotteryContract.claimTickets(lotteryId, ticketBatch.ticketIds, ticketBatch.brackets)
        const receipt = await tx.wait()
        if (receipt.status) {
          // One transaction within batch has succeeded
          receipts.push(receipt)
          setPendingBatchClaims(transactionsToFire - receipts.length)

          // More transactions are to be done within the batch. Issue toast to give user feedback.
          if (receipts.length !== transactionsToFire) {
            toastSuccess(
              t('views.lottery.components.claimPrizesModal.toastSuccess.title'),
              <ToastDescriptionWithTx txHash={receipt.transactionHash}>
                {t('views.lottery.components.claimPrizesModal.toastSuccess.description2', {
                  claimNum: receipts.length,
                  claimTotal: transactionsToFire,
                  lotteryId,
                })}
              </ToastDescriptionWithTx>,
            )
          }
        }
      } catch (error) {
        console.error(error)
        setPendingTx(false)
        break
      }
    }

    // Batch is finished
    if (receipts.length === transactionsToFire) {
      setPendingTx(false)
      toastSuccess(
        t('views.lottery.components.claimPrizesModal.toastSuccess.title'),
        t('views.lottery.components.claimPrizesModal.toastSuccess.description', { lotteryId }),
      )
      handleProgressToNextClaim()
    }
  }

  async function sharefbimage(text: string) {
    // @ts-ignore
    if (window.FB as any) {
      // @ts-ignore
      window.FB.init({
        appId: `${window.location.href.includes('localhost') ? '739972980354398' : process.env.REACT_APP_FB_ID}`,
        appSecret: `${
          window.location.href.includes('localhost')
            ? '4a6c92e3fe5a5c25bc5d782e53dc7364'
            : process.env.REACT_APP_FB_SECRET
        }`,
        status: true,
        cookie: true,
        version: 'v13.0',
      })
      // @ts-ignore
      window.FB.ui({
        method: 'share',
        display: 'popup',
        appId: `${window.location.href.includes('localhost') ? '739972980354398' : process.env.REACT_APP_FB_ID}`,
        href: 'https://pltlotto.com/',
        quote: text,
      })
    }
  }

  const dataURLtoFile = (dataurl: string, filename: string): File | null => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)![1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  const shareLink = async (e, key: string): Promise<void> => {
    e.preventDefault()
    const findAddress = SocialLinkAdresses.find((item) => Object.is(item.key, key))

    const element = printRef.current
    const contentType = 'image/jpg'

    const canvas = await html2canvas(element)

    const data = canvas.toDataURL(contentType) // .replace(/^data:image\/[a-z]+;base64,/, '')
    const file = dataURLtoFile(data, 'image.png')
    let text

    if (findAddress?.via) {
      text = nftTotal.isPositive()
        ? `${t('shared.twitter.twitterText_context', {
            context: 'nft',
            amountUSD: parseFloat(dollarRewardAsBalance.toString()).toFixed(2),
            amountPLT: parseFloat(rewardAsBalance.toString()).toFixed(2),
            amountNFT: nftTotal.toNumber() + freeNftReward,
          })}`
        : `${t('shared.twitter.twitterText_context', {
            amountUSD: parseFloat(dollarRewardAsBalance.toString()).toFixed(2),
            amountPLT: parseFloat(rewardAsBalance.toString()).toFixed(2),
          })}`
      console.log(text)
      const link = `${findAddress.url}${window.location.protocol}//${window.location.host}&text=${text}`
      window.open(link, 'CNN_WindowName')
    } else {
      text = nftTotal.isPositive()
        ? `${t('shared.facebook.facebookText_context', {
            context: 'nft',
            amountUSD: parseFloat(dollarRewardAsBalance.toString()).toFixed(2),
            amountPLT: parseFloat(rewardAsBalance.toString()).toFixed(2),
            amountNFT: nftTotal.toNumber() + freeNftReward,
          })}`
        : `${t('shared.facebook.facebookText_context', {
            amountUSD: parseFloat(dollarRewardAsBalance.toString()).toFixed(2),
            amountPLT: parseFloat(rewardAsBalance.toString()).toFixed(2),
          })}`
      await sharefbimage(text)
    }
  }

  return (
    <>
      <div ref={printRef} style={{ background: '#292D3F', border: 'none' }}>
        <Flex flexDirection="column">
          <Text mb="4px" textAlign={['center', null, 'left']} color="#fff">
            {t('views.lottery.components.claimPrizesModal.youWon')}
          </Text>
          <Flex alignItems={['flex-start', null, 'center']} flexDirection={['column', null, 'row']}>
            <Balance
              textAlign={['center', null, 'left']}
              lineHeight="1.1"
              value={rewardAsBalance}
              fontSize="44px"
              bold
              color="#fff"
              unit=" PLT!"
            />
            {nftTotal.isPositive() && (
              <Flex alignItems="center">
                <Text fontSize="44px" bold color="#ffffff">
                  &nbsp;+&nbsp;
                </Text>
                <Text fontSize="44px" bold color="#EC9A44" marginRight="8px">
                  {nftTotal.toNumber() + freeNftReward}
                </Text>
                <NFTIcon height={30} width={30} />
              </Flex>
            )}
          </Flex>
          <Balance
            mt={['12px', null, '0']}
            textAlign={['center', null, 'left']}
            decimals={2}
            value={dollarRewardAsBalance}
            fontSize="12px"
            color="#fff"
            unit=" USD"
            prefix="~"
          />
        </Flex>

        <Flex alignItems="center" justifyContent="center">
          <Text mt="8px" fontSize="12px" color="#fff">
            {t('views.lottery.components.claimPrizesModal.round')} #{activeClaimData.roundId}
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="center">
          <StyledButton
            isLoading={pendingTx}
            endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
            mt="20px"
            width="100%"
            id="claim-reward"
            onClick={() => (shouldBatchRequest ? handleBatchClaim() : handleClaim())}
          >
            {pendingTx
              ? t('views.lottery.components.claimPrizesModal.claiming')
              : t('views.lottery.components.claimPrizesModal.claim')}{' '}
            {pendingBatchClaims > 1 ? `(${pendingBatchClaims})` : ''}
          </StyledButton>
        </Flex>
      </div>

      <SharedList>
        {SocialLinkAdresses.map((item: SocialLink) => (
          <SharedListItem key={item.key}>
            <SharedButton onClick={(e) => shareLink(e, item.key)} type="button">
              {item.key === 'twitter' ? <TwitterIcon /> : null}
              {item.key === 'facebook' ? <FacebookIcon /> : null}
              <span>
                {t('shared.sharedOn')} {item.name}
              </span>
            </SharedButton>
          </SharedListItem>
        ))}
      </SharedList>
    </>
  )
}

export default ClaimInnerContainer
