import { FunctionComponent } from 'react'
import { Web3Modal } from '@web3modal/react'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { bsc } from 'wagmi/chains'

export const withWagmi = <P extends Record<string, unknown> = Record<string, unknown>>(
  WrappedComponent: any,
): FunctionComponent<P> => {
  const chains = [bsc]
  const { publicClient } = configureChains(chains, [
    w3mProvider({
      projectId: 'fd3cc6cf5a42034a64403ade7f0cb802',
    }),
  ])
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({
      projectId: 'fd3cc6cf5a42034a64403ade7f0cb802',
      chains,
    }),
    publicClient,
  })
  const ethereumClient = new EthereumClient(wagmiConfig, chains)

  const WrappedWithWagmi = (props: P) => (
    <WagmiConfig config={wagmiConfig}>
      <WrappedComponent {...props} />
      <div style={{ position: 'fixed', zIndex: 100 }}>
        <Web3Modal projectId="fd3cc6cf5a42034a64403ade7f0cb802" ethereumClient={ethereumClient} />
      </div>
    </WagmiConfig>
  )

  WrappedWithWagmi.displayName = 'WrappedWithWagmi'

  return WrappedWithWagmi
}
