import React, { FC, useEffect } from 'react'
import styled from 'styled-components'

type BurgerProps = {
  isOpen: boolean
  setOpen: any
}

const StyledBurger = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  z-index: 10;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  div {
    width: 100%;
    height: 2px;
    background: #141414;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: rotate(0);
    }

    :nth-child(2) {
      opacity: 1;
      transform: translateX(0);
    }

    :nth-child(3) {
      transform: rotate(0);
    }
  }
  &[data-open='true'] {
    div {
      :first-child {
        transform: rotate(45deg);
      }
      :nth-child(2) {
        opacity: 0;
        transform: translateX(20px);
      }
      :nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
`

export const Burger: FC<BurgerProps> = ({ isOpen, setOpen }) => {
  const body = document.querySelector('body')

  useEffect(() => {
    if (isOpen) {
      body.setAttribute('data-noScroll', 'true')
    } else {
      body.setAttribute('data-noScroll', 'false')
    }
  }, [body, isOpen])

  return (
    <StyledBurger data-open={isOpen} onClick={() => setOpen(!isOpen)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}
