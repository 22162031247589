import { PublicClient, WindowProvider, useAccount, useDisconnect } from 'wagmi'
import { getPublicClient, getWalletClient } from '@wagmi/core'
import { useWeb3Modal } from '@web3modal/react'
import Web3 from 'web3'
import React, { FunctionComponent, createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { ethers, providers } from 'ethers'
import { HttpTransport } from 'viem'

type TAuthContext = {
  login: () => void
  logout: () => Promise<void>
  provider: WindowProvider
  ethersProvider: ethers.providers.Web3Provider
  web3: Web3
  account: string
  chainId: number
  signer: ethers.Signer
}

const NETWORK_CONFIG = {
  [56]: {
    name: 'BNB Smart Chain Mainnet',
    scanURL: 'https://bscscan.com',
    hexChainId: '0x38',
    rpc: 'https://bsc-dataseed.binance.org/',
  },
  [97]: {
    name: 'BNB Smart Chain Testnet',
    scanURL: 'https://testnet.bscscan.com',
    hexChainId: '0x61',
    rpc: 'https://data-seed-prebsc-1-s3.binance.org:8545/',
  },
}

class SetupNetworkError extends Error {
  constructor(message) {
    super(message)
    this.name = 'Failed to setup the network in the Wallet'
  }
}

const walletClientToSigner = async (chainId: number) => {
  const walletClient = await getWalletClient({ chainId })
  const { account, transport } = walletClient

  const provider = new providers.Web3Provider(transport as any)
  const signer = provider.getSigner(account.address)
  return signer
}

const publicClientToProvider = (publicClient: PublicClient) => {
  const { chain, transport } = publicClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  if (transport.type === 'fallback')
    return new providers.FallbackProvider(
      (transport.transports as ReturnType<HttpTransport>[]).map(
        ({ value }) => new providers.JsonRpcProvider(value?.url, network),
      ),
    )
  return new providers.JsonRpcProvider(transport.url, network)
}

export const AuthContext = createContext<TAuthContext | null>(null)

export const withAuth = <P extends Record<string, unknown> = Record<string, unknown>>(
  WrappedComponent: any,
): FunctionComponent<P> => {
  const WrappedWithAuth = ({ ...rest }: P) => {
    const [provider, setProvider] = useState(null)
    const [web3, setWeb3] = useState<Web3>()
    const [account, setAccount] = useState<string>()
    const [ethersProvider, setEthersProvider] = useState<ethers.providers.Web3Provider>()
    const [signer, setSigner] = useState<ethers.Signer>(null)
    const [chainId, setChainId] = useState<number>()
    const [pendingChainSwitch, setPendingChainSwitch] = useState<boolean>(false)
    const { address, connector } = useAccount()
    const { disconnect } = useDisconnect()
    const { open } = useWeb3Modal()

    const fetchAccountData = async (provider) => {
      const chainId = await web3.eth.getChainId()
      const requestedChainId = 56

      if (chainId !== requestedChainId && !pendingChainSwitch) {
        setPendingChainSwitch(true)
        try {
          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: NETWORK_CONFIG[requestedChainId].hexChainId }],
          })
        } catch (switchError: any) {
          if (switchError.code === 4902) {
            try {
              await provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: NETWORK_CONFIG[requestedChainId].hexChainId,
                    chainName: NETWORK_CONFIG[requestedChainId].name,
                    nativeCurrency: {
                      name: 'BNB',
                      symbol: 'bnb',
                      decimals: 18,
                    },
                    rpcUrls: 'https://bsc-dataseed.binance.org',
                    blockExplorerUrls: [`${NETWORK_CONFIG[requestedChainId].scanURL}/`],
                  },
                ],
              })
            } catch (error: any) {
              throw new SetupNetworkError(error.message)
            }
            try {
              await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: NETWORK_CONFIG[requestedChainId].hexChainId }],
              })
            } catch (error: any) {
              throw new Error(error.message)
            }
          }
          throw new Error(switchError.message)
        } finally {
          setPendingChainSwitch(false)
        }
      }

      setChainId(chainId)

      return { account: address, chainId }
    }

    const logout = useCallback(async () => {
      disconnect()
    }, [])

    const login = useCallback(open, [open])

    useEffect(() => {
      const getProvider = async () => {
        const connectorProvider = await connector.getProvider()
        const web3 = new Web3(connectorProvider)
        setWeb3(web3)
        const publicClient = getPublicClient({ chainId })
        const provider = publicClientToProvider(publicClient)
        const providerReady = await provider.ready
        setProvider(provider)
        const ethersProvider = new ethers.providers.Web3Provider(connectorProvider)
        setEthersProvider(ethersProvider)
        setSigner(await walletClientToSigner(chainId))
      }
      setAccount(address)
      if (address && connector && connector?.chains[0]) {
        getProvider()
      }
    }, [address, connector])

    useEffect(() => {
      if (provider) {
        fetchAccountData(provider)
        provider.on('accountsChanged', async () => {
          await fetchAccountData(provider)
        })
        provider.on('chainChanged', async () => {
          await fetchAccountData(provider)
        })
      }
    }, [provider])

    const ctx = useMemo<TAuthContext>(
      () => ({
        web3,
        account,
        chainId,
        login,
        logout,
        provider,
        ethersProvider,
        signer,
      }),
      [account, chainId, login, logout, provider, web3],
    )

    return (
      <AuthContext.Provider value={ctx}>
        <WrappedComponent {...(rest as P)} />
      </AuthContext.Provider>
    )
  }

  WrappedWithAuth.displayName = 'WrappedWithAuth'

  return WrappedWithAuth
}
