import { Button, Flex, Link } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const ItemContentText = styled.div``

export const AssideBlock = styled.div`
  flex-shrink: 0;
  padding-top: 40px;

  ${({ theme }) => theme.mediaQueries.xxl} {
    padding-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }
`
export const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
`
export const ItemContent = styled.div`
  display: none;
  font-size: 15px;
  line-height: 24px;

  ${({ theme }) => theme.mediaQueries.xxl} {
    ${ContentBlock} + ${AssideBlock} {
      margin-left: 10px;
    }
  }
`
export const QuestionsFlex = styled(Flex)`
  border-radius: 40px;
  background-color: #292d3f;
  padding: 40px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1152px;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.xxl} {
    margin-top: 80px;
  }
`

export const HeaderDropdownButton = styled(Button)`
  background-color: ${(props) => props.color};
  align-items: center;
  border: 0;
  border-radius: 25px;
  box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.03em;
  padding: 5px 10px;
  line-height: 24px;
  height: 34px;
  color: white;
  text-align: center;
  justify-content: center;
  width: ${(props) => props.width}px;
  min-width: 211px;
  svg {
    margin-right: 10px;
  }
  &[disabled] {
    background-color: #3f4253;
  }
  &:hover {
    text-decoration: none;
    cursor: default;
  }
`

export const BasicInlineButton = styled(Link)`
  background-image: linear-gradient(265.56deg, #922c32 -0.27%, #922c32 -0.26%, #b67122 98.59%);
  align-items: center;
  border: 0;
  border-radius: 25px;
  box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.03em;
  margin: 0 16px;
  padding: 11px 16px;
  color: white;
  text-align: center;
  justify-content: center;
  width: ${(props) => props.width}px;
  font-size: ${(props) => props.fontSize}px;
`

export const CustomBtnFlexContainer = styled(Flex)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.xxl} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 549px;
  }
`
