import React, { FC } from 'react'
import { SocialList, ListWrapper, ListItem } from 'components/SocialList'
import styled from 'styled-components'
import packageJson from '../../../package.json'

export const Footer: FC = () => {
  return (
    <FooterWrapper>
      <Audit>
        <span>Smart contract audited by </span>
        <a
          target="_blank"
          href="https://github.com/Quillhash/Audit_Reports/blob/master/Poollotto%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf"
          rel="noreferrer"
        >
          <img src="/images/logo_audit.png" alt="a" />
        </a>
      </Audit>
      <SocialList />
      <Copyright>
        <span>Copyright © {new Date().getFullYear()} Poollotto. All rights reserved.</span>
        <span>
          Date: {packageJson.time} Build №: {packageJson.version}
        </span>
      </Copyright>
    </FooterWrapper>
  )
}

const Audit = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  color: rgba(249, 249, 249, 0.5);

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: initial;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    > *:first-child {
      margin-right: 8px;
    }
  }
`

const Copyright = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);

  > *:first-child {
    margin-right: 8px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
  }
`

export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 24px;
  padding-left: 10px;
  padding-right: 10px;

  ${ListWrapper} {
    ${ListItem} {
      background-color: #fff;
      svg {
        fill: #000;
      }
      &:hover {
        svg {
          fill: #1fc7d4;
        }
      }
    }
  }
  > * + * {
    margin-top: 30px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    padding-left: 66px;
    padding-right: 66px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    > * + * {
      margin-top: initial;
    }
    * + ${Copyright} {
      margin-top: 30px;
    }
  }
`
