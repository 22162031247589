import styled from 'styled-components'

export const LangDropdown = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;
  margin-top: 24px;

  .testDiv {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 1;
  }

  span {
    color: #a5accf;
  }
  button {
    display: inline-flex;
    cursor: pointer;
    border: initial;
    padding: initial;
    background: initial;
    width: max-content;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    &:hover {
      color: #b67023;
    }
    &.is-active {
      color: #b67023;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    position: absolute;
    top: 82px;
    right: 66px;
    max-width: 300px;
    z-index: 2;
    padding: 12px 40px 12px 40px;
    background-color: #fff;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.6);
    border-radius: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    &:before {
      content: '';
      position: absolute;
      right: 18px;
      top: -3px;
      width: 24px;
      height: 24px;
      background: #fff;
      transform: rotate(45deg);
    }
  }
`

export const LangButton = styled.button<{
  isMobile?: boolean
  isOpenDropdown?: boolean
}>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: initial;
  padding: initial;
  background: initial;
  color: #141518;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  width: ${({ isMobile }) => (isMobile ? 'auto' : '48px')};

  svg {
    fill: currentColor;
    transform: ${({ isOpenDropdown }) => (isOpenDropdown ? 'rotate(0deg)' : 'rotate(180deg)')};
  }

  * + svg {
    margin-left: 10px;
  }
`

export const LangWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: initial;
    align-items: initial;
  }
`
