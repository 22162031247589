import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Heading, ModalContainer, ModalHeader, ModalTitle, ModalBody, ModalCloseButton } from '@pancakeswap/uikit'

import { useTranslation } from 'react-i18next'
import { delay } from 'lodash'
import confetti from 'canvas-confetti'
import { LotteryTicketClaimData } from 'config/constants/types'
import { useAppDispatch } from 'state'
import { useLottery } from 'state/lottery/hooks'
import { fetchUserLotteries } from 'state/lottery'
import ClaimPrizesInner from './ClaimPrizesInner'
import ClaimNftsInnerContainer from './ClaimNftsInner'
import { useAuth } from 'hooks/useAuth'

const StyledModal = styled(ModalContainer)`
  position: relative;
  overflow: visible;
  .sc-biJonm,
  .sc-gIvpjk {
    color: white;
  }
  h2 {
    color: currentColor;
  }
  button {
    color: currentColor;
  }
  svg {
    fill: currentColor;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 380px;
  }
`

const StyledModalHeader = styled(ModalHeader)`
  background: #40455c;
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
  border-bottom: none;
`

const showConfetti = () => {
  confetti({
    resize: true,
    particleCount: 200,
    startVelocity: 30,
    gravity: 0.5,
    spread: 350,
    origin: {
      x: 0.5,
      y: 0.3,
    },
  })
}

interface ClaimPrizesModalModalProps {
  roundsToClaim: LotteryTicketClaimData[]
  onDismiss?: () => void
  onlyNft?: boolean
  freeNftReward?: number
}

const ClaimPrizesModal: React.FC<ClaimPrizesModalModalProps> = ({
  onDismiss,
  roundsToClaim,
  onlyNft,
  freeNftReward,
}) => {
  const { t } = useTranslation()
  const { account } = useAuth()
  const { currentLotteryId } = useLottery()
  const dispatch = useAppDispatch()

  useEffect(() => {
    delay(showConfetti, 100)
  }, [])

  return (
    <StyledModal minWidth="280px" style={{ background: '#292D3F', border: 'none', color: '#fff' }}>
      <StyledModalHeader>
        <ModalTitle>
          <Heading>{t('views.lottery.components.claimPrizesModal.title')}</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </StyledModalHeader>
      <ModalBody p="24px">
        {onlyNft ? (
          <ClaimNftsInnerContainer
            onSuccess={() => {
              dispatch(fetchUserLotteries({ account, currentLotteryId }))
              onDismiss()
            }}
            nftTotal={freeNftReward}
            roundsToClaim={roundsToClaim}
          />
        ) : (
          <ClaimPrizesInner
            onSuccess={() => {
              dispatch(fetchUserLotteries({ account, currentLotteryId }))
              onDismiss()
            }}
            roundsToClaim={roundsToClaim}
            freeNftReward={freeNftReward}
          />
        )}
      </ModalBody>
    </StyledModal>
  )
}

export default ClaimPrizesModal
