import React from 'react'
import { event } from 'react-ga'
import { useModal, WaitIcon, ButtonProps, Flex, Text } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'
import { useLottery } from 'state/lottery/hooks'
import { LotteryStatus } from 'config/constants/types'
import { ReactComponent as NFTIcon } from 'assets/icons/NFT.svg'
import BuyTicketsModal from '../BuyTicketsModal/BuyTicketsModal'
import { Button, Column, Row, Wrapper } from './styled'

interface BuyTicketsButtonProps extends ButtonProps {
  disabled?: boolean
  hasFreeNft?: boolean
}

export const BuyTicketsButton: React.FC<BuyTicketsButtonProps> = ({ hasFreeNft = false }) => {
  const { t } = useTranslation()
  const [onPresentBuyTicketsModal] = useModal(<BuyTicketsModal />)
  const {
    currentRound: { endTime, status },
  } = useLottery()
  const isEnded = +new Date() / 1000 > Number(endTime) || status !== LotteryStatus.OPEN

  const handleClick = () => {
    onPresentBuyTicketsModal()
    event({
      category: 'Custom: Buy Tickets Opened',
      action: 'Custom: Buy Tickets Opened',
    })
  }

  const getBuyButtonText = () => {
    if (!isEnded) {
      return t('views.lottery.components.buyTicketsButton.buyButtonText_context', { context: status })
    }
    if (status === LotteryStatus.PENDING) {
      return (
        <Flex alignItems="center" justifyContent="center">
          <WaitIcon mr="4px" color="textDisabled" />{' '}
          {t('views.lottery.components.buyTicketsButton.buyButtonText_context', { context: 'pending' })}
        </Flex>
      )
    }
    return (
      <Flex alignItems="center" justifyContent="center">
        <WaitIcon mr="4px" color="textDisabled" />{' '}
        {t('views.lottery.components.buyTicketsButton.buyButtonText_context')}
      </Flex>
    )
  }

  return !hasFreeNft ? (
    <Button onClick={handleClick} disabled={isEnded}>
      {getBuyButtonText()}
    </Button>
  ) : (
    <Wrapper>
      <Text fontWeight={700} fontSize="32px" color="#FFFFFF" maxWidth="380px" textAlign="center" style={{ zIndex: 2 }}>
        <span>{t('views.lottery.components.buyTicketsButton.buyButtonPromotion_header1')}</span>{' '}
        <span>{t('views.lottery.components.buyTicketsButton.buyButtonPromotion_header2')}</span>
      </Text>

      <Text fontWeight={400} fontSize="12px" color="#11CABE" textAlign="center" marginTop="12px">
        {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_title')}
      </Text>
      <Row>
        <Column>
          <NFTIcon style={{ width: '32px', height: '32px' }} />
          <Text fontWeight={700} fontSize="18px" color="#FFFFFF" textAlign="center" marginTop="12px">
            {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_text_1_1')} <br />{' '}
            {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_text_get')}{' '}
            <span style={{ color: '#EC9A44', whiteSpace: 'nowrap' }}>
              {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_text_1_2')}
            </span>{' '}
            {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_text_1_3')}
          </Text>
        </Column>
        <Column>
          <Flex alignItems="center">
            <NFTIcon style={{ width: '32px', height: '32px', marginRight: '4px' }} />
            <NFTIcon style={{ width: '32px', height: '32px' }} />
          </Flex>
          <Text fontWeight={700} fontSize="18px" color="#FFFFFF" textAlign="center" marginTop="12px">
            {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_text_2_1')} <br />{' '}
            {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_text_get')}{' '}
            <span style={{ color: '#EC9A44', whiteSpace: 'nowrap' }}>
              {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_text_2_2')}
            </span>{' '}
            {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_text_2_3')}
          </Text>
        </Column>
        <Column>
          <Flex alignItems="center">
            <NFTIcon style={{ width: '32px', height: '32px', marginRight: '4px' }} />
            <NFTIcon style={{ width: '32px', height: '32px', marginRight: '4px' }} />
            <NFTIcon style={{ width: '32px', height: '32px' }} />
          </Flex>
          <Text fontWeight={700} fontSize="18px" color="#FFFFFF" textAlign="center" marginTop="12px">
            {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_text_3_1')} <br />{' '}
            {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_text_get')}{' '}
            <span style={{ color: '#EC9A44', whiteSpace: 'nowrap' }}>
              {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_text_3_2')}
            </span>{' '}
            {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_text_3_3')}
          </Text>
        </Column>
      </Row>
      <Button onClick={handleClick} disabled={isEnded} style={{ zIndex: 2 }}>
        {getBuyButtonText()}
      </Button>
      <Text
        marginTop={24}
        fontWeight={400}
        fontSize="12px"
        color="#FFFFFF"
        textAlign="center"
        style={{ opacity: '0.5' }}
      >
        {t('views.lottery.components.buyTicketsButton.buyButtonPromotion_note')}
      </Text>
    </Wrapper>
  )
}
