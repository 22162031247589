export default {
  lotteryV2: {
    97: '0x2A792ec092d28EA82f3AB644754BA2a682238014',
    56: '0x0b728b04cD452a0fFD0c1246338ADD09582e8DDB',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  pltNft: {
    97: '0x01429A33353e5b9D05FD54Bbf4FB56a050964393',
    56: '0x728b8500f88Fb9239e9746871CE6A6430B7d0EBe',
  },
  freeNft: {
    97: '0xDF7Ad78C4f3BDdcc169Ba93e830f40E202de2a9e',
    56: '0xE98A50552396084C3f3F11397937b74711719a05',
  },
}
