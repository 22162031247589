import React from 'react'
import styled from 'styled-components'
import { Flex, Heading, Skeleton } from '@pancakeswap/uikit'
import getTimePeriods from 'utils/getTimePeriods'
import Timer from './Timer'
import useNextEventCountdown from '../../hooks/useNextEventCountdown'

interface CountdownProps {
  nextEventTime: number
  preCountdownText?: string
  postCountdownText?: string
}

const StyledFlex = styled(Flex)`
  display: block;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
  }
`
const Countdown: React.FC<CountdownProps> = ({ nextEventTime, preCountdownText, postCountdownText }) => {
  const secondsRemaining = useNextEventCountdown(nextEventTime)
  const { days, hours, minutes, seconds } = getTimePeriods(secondsRemaining < 0 ? 0 : secondsRemaining)

  return (
    <>
      {secondsRemaining ? (
        <StyledFlex justifyContent="flex-end" alignItems="flex-end">
          {preCountdownText && (
            <Heading mr="12px" color="#ffff">
              {preCountdownText}
            </Heading>
          )}
          <Timer seconds={seconds} minutes={minutes} hours={hours} days={days} />
          {postCountdownText && <Heading color="#ffff"> {postCountdownText}</Heading>}
        </StyledFlex>
      ) : (
        <Skeleton height="41px" width="250px" />
      )}
    </>
  )
}

export default Countdown
