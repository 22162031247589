import { useEffect, useState } from 'react'

import { useAuth } from './useAuth'

const useFreeNftAmount = (lotteryId: string) => {
  const { account } = useAuth()
  const [freeNftReward, setFreeNftReward] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_NFT_FAUCET_API}/api/lottery/rewardCount`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ account }),
        })
        const freeNftAmount = await response.json()

        setFreeNftReward(freeNftAmount ?? 0)
      } catch (e: any) {
        window.console.log(e.message)
      }
    }

    // if (account) fetchData()
  }, [account, lotteryId])

  return freeNftReward
}

export default useFreeNftAmount
