import React from 'react'
import { Link, Text } from '@pancakeswap/uikit'
import { getBscScanLink } from 'utils'
import { useTranslation } from 'react-i18next'
import truncateHash from 'utils/truncateHash'
import { useAuth } from 'hooks/useAuth'

interface DescriptionWithTxProps {
  // description?: string
  txHash?: string
  id?: string
}

const DescriptionWithTx: React.FC<DescriptionWithTxProps> = ({ txHash, children, id }) => {
  const { chainId } = useAuth()
  const { t } = useTranslation()

  return (
    <div id={id}>
      {typeof children === 'string' ? <Text as="p">{children}</Text> : children}
      {txHash && (
        <Link external href={getBscScanLink(txHash, 'transaction', chainId)}>
          {t('View on BscScan')}: {truncateHash(txHash, 8, 0)}
        </Link>
      )}
    </div>
  )
}

export default DescriptionWithTx
