import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, Heading } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'

export interface TimerProps {
  seconds?: number | string
  minutes?: number | string
  hours?: number | string
  days?: number | string
}

const StyledTimerFlex = styled(Flex)<{ showTooltip?: boolean }>`
  ${({ theme, showTooltip }) => (showTooltip ? ` border-bottom: 1px dashed ${theme.colors.textSubtle};` : ``)}
  div:last-of-type {
    margin-right: 0;
  }
  justify-content: center;
  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
  }
`

const StyledTimerText = styled(Heading)`
  background: #b67122;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const StyledDiv = styled.div`
  display: contents;
`

const Wrapper: React.FC<TimerProps> = ({ minutes, hours, days, seconds }) => {
  const [width, setWidth] = useState<number>(window.innerWidth)

  const _minutes = parseInt(minutes as string, 10)
  const _hours = parseInt(hours as string, 10)
  const _days = parseInt(days as string, 10)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile: boolean = width <= 768

  const { t } = useTranslation()

  return (
    <StyledTimerFlex alignItems="flex-end">
      {Boolean(_days) && (
        <>
          <StyledTimerText mb="-4px" scale="xl" mr="4px">
            {_days}
          </StyledTimerText>
          <StyledTimerText mr="12px">{t('d')}</StyledTimerText>
        </>
      )}
      {Boolean(_hours) && (
        <>
          <StyledTimerText mb="-4px" scale="xl" mr="4px">
            {_hours}
          </StyledTimerText>
          <StyledTimerText mr="12px">{t('h')}</StyledTimerText>
        </>
      )}

      {Boolean(_minutes) && (
        <>
          {isMobile ? (
            <StyledDiv>
              <StyledTimerText mb="-4px" scale="xl" mr="4px">
                {minutes}
              </StyledTimerText>
              <StyledTimerText mr="12px">{t('m')}</StyledTimerText>
            </StyledDiv>
          ) : (
            <StyledDiv>
              <StyledTimerText mb="-4px" scale="xl" mr="4px">
                {minutes}
              </StyledTimerText>
              <StyledTimerText mr="12px">{t('m')}</StyledTimerText>
            </StyledDiv>
          )}
        </>
      )}

      {Boolean(seconds) && (
        <>
          {isMobile ? (
            <StyledDiv>
              <StyledTimerText mb="-4px" scale="xl" mr="4px">
                {seconds}
              </StyledTimerText>
              <StyledTimerText mr="12px">{t('s')}</StyledTimerText>
            </StyledDiv>
          ) : (
            <StyledDiv>
              <StyledTimerText mb="-4px" scale="xl" mr="4px">
                {seconds}
              </StyledTimerText>
              <StyledTimerText mr="12px">{t('s')}</StyledTimerText>
            </StyledDiv>
          )}
        </>
      )}
    </StyledTimerFlex>
  )
}

export default Wrapper
