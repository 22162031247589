import React from 'react'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import history from '../routerHistory'

const Button = styled.button`
  display: inline-flex;
  flex-shrink: 0;
  background-image: linear-gradient(265.56deg, #922c32 -0.27%, #922c32 -0.26%, #b67122 98.59%);
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 16px;
  box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.03em;
  padding: 5px 16px;
  color: white;
  &:hover {
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login } = useAuth()

  const onConnectClick = async () => {
    await login()
  }

  return (
    <Button
      id="connect-wallet"
      onClick={() => {
        onConnectClick()
        setTimeout(() => {
          const selector: HTMLLinkElement = document.querySelector('.sc-hKFxyN.fXdAhg')

          if (selector) {
            const presentation: HTMLDivElement =
              selector.parentElement.parentElement.parentElement.parentElement.querySelector('[role]')
            const selectorHandler = (e: Event) => {
              e.preventDefault()
              history.push('/onboarding')
              presentation.click()
            }
            selector.href = '/onboarding'

            selector.addEventListener('click', selectorHandler)
          }
        }, 100)
      }}
      {...props}
    >
      {t('shared.connectWallet')}
    </Button>
  )
}

export default ConnectWalletButton
