import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import {
  Card,
  Text,
  Skeleton,
  CardHeader,
  Box,
  IconButton,
  ArrowForwardIcon,
  ArrowBackIcon,
  Flex,
  Heading,
  Input,
} from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'state'
import { useLottery } from 'state/lottery/hooks'
import { fetchLottery } from 'state/lottery/helpers'
import { LotteryStatus } from 'config/constants/types'
import { getDrawnDate, processLotteryResponse } from '../../helpers'
import PreviousRoundCardBody from '../PreviousRoundCard/Body'
import PreviousRoundCardFooter from '../PreviousRoundCard/Footer'

const StyledCard = styled(Card)`
  width: 100%;
  background: none !important;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 756px;
  }
`

const StyledBlock = styled.div`
  text-align: center;
`

const StyledBox = styled(Box)`
  text-align: left;
  margin-bottom: 50px;
`

const StyledCardHeaderMobile = styled(CardHeader)`
  z-index: 2;
  background: #40455c;
`

const StyledCardHeader = styled(CardHeader)`
  z-index: 2;
  background: #40455c;
  padding: 24px 70px;
`

const StyledInput = styled(Input)`
  width: 80px;
  height: 100%;
  margin: 4px 26px;
  background-color: white;
  text-align: center;
  color: #292d3f;
`

const StyledInputMobile = styled(Input)`
  width: 140px;
  padding: 3px;
  height: 100%;
  margin: 4px auto;
  background-color: white;
  color: #292d3f;
  text-align: center;
  margin-top: 17px;
`

const StyledIconButton = styled(IconButton)`
  width: 32px;
  svg {
    fill: #fff;

    path {
      fill: #fff;
    }
  }
  :disabled {
    background: none;

    svg {
      fill: ${({ theme }) => theme.colors.textDisabled};

      path {
        fill: ${({ theme }) => theme.colors.textDisabled};
      }
    }
  }
`

const AllHistoryCard = () => {
  const [width, setWidth] = useState<number>(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile: boolean = width <= 768

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    currentLotteryId,
    lotteriesData,
    currentRound: { status, isLoading },
  } = useLottery()
  const [latestRoundId, setLatestRoundId] = useState(null)
  const [selectedRoundId, setSelectedRoundId] = useState('')
  const [selectedLotteryNodeData, setSelectedLotteryNodeData] = useState(null)
  const timer = useRef(null)

  const numRoundsFetched = lotteriesData?.length

  const selectedRoundIdAsInt = parseInt(selectedRoundId, 10)

  useEffect(() => {
    if (currentLotteryId) {
      const currentLotteryIdAsInt = currentLotteryId ? parseInt(currentLotteryId) : null
      const mostRecentFinishedRoundId =
        status === LotteryStatus.CLAIMABLE ? currentLotteryIdAsInt : currentLotteryIdAsInt - 1
      setLatestRoundId(mostRecentFinishedRoundId)
      setSelectedRoundId(mostRecentFinishedRoundId.toString())
    }
  }, [currentLotteryId, status])

  useEffect(() => {
    setSelectedLotteryNodeData(null)

    const fetchLotteryData = async () => {
      const lotteryData = await fetchLottery(selectedRoundId)
      const processedLotteryData = processLotteryResponse(lotteryData)
      setSelectedLotteryNodeData(processedLotteryData)
    }

    timer.current = setInterval(() => {
      if (selectedRoundId) {
        fetchLotteryData()
      }
      clearInterval(timer.current)
    }, 1000)

    return () => clearInterval(timer.current)
  }, [selectedRoundId, currentLotteryId, numRoundsFetched, dispatch])

  const handleInputChange = (event) => {
    const {
      target: { value },
    } = event
    if (value) {
      setSelectedRoundId(value)
      if (parseInt(value, 10) <= 0) {
        setSelectedRoundId('')
      }
      if (parseInt(value, 10) >= latestRoundId) {
        setSelectedRoundId(latestRoundId.toString())
      }
    } else {
      setSelectedRoundId('')
    }
  }

  const handleArrowButtonPress = (targetRound) => {
    if (targetRound) {
      setSelectedRoundId(targetRound.toString())
    } else {
      // targetRound is NaN when the input is empty, the only button press that will trigger this func is 'forward one'
      setSelectedRoundId('1')
    }
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.validity.valid) {
      handleInputChange(e)
    }
  }

  return (
    <StyledCard background="none">
      {isMobile ? (
        <div>
          <StyledCardHeaderMobile>
            <StyledBlock>
              <Heading color="white" mr="8px">
                {t('views.lottery.components.allHistoryCard.round')}
              </Heading>

              <Flex alignItems="center">
                <StyledIconButton
                  disabled={!selectedRoundIdAsInt || selectedRoundIdAsInt <= 1}
                  onClick={() => handleArrowButtonPress(selectedRoundIdAsInt - 1)}
                  variant="text"
                  scale="sm"
                  mr="4px"
                >
                  <ArrowBackIcon />
                </StyledIconButton>

                <StyledInputMobile
                  pattern="^[0-9]+$"
                  inputMode="numeric"
                  disabled={isLoading}
                  id="round-id"
                  name="round-id"
                  value={selectedRoundId}
                  scale="lg"
                  onChange={handleOnChange}
                />

                <StyledIconButton
                  disabled={selectedRoundIdAsInt >= latestRoundId}
                  onClick={() => handleArrowButtonPress(selectedRoundIdAsInt + 1)}
                  variant="text"
                  scale="sm"
                  mr="4px"
                >
                  <ArrowForwardIcon />
                </StyledIconButton>
              </Flex>
              <StyledBox>
                {selectedLotteryNodeData?.endTime ? (
                  <Text color="white" fontSize="16px" textAlign="center">
                    {t('views.lottery.components.allHistoryCard.draw')} {getDrawnDate(selectedLotteryNodeData.endTime)}
                  </Text>
                ) : (
                  <Skeleton height="21px" />
                )}
              </StyledBox>
            </StyledBlock>
          </StyledCardHeaderMobile>
          <PreviousRoundCardBody lotteryNodeData={selectedLotteryNodeData} lotteryId={selectedRoundId} />
          <PreviousRoundCardFooter lotteryNodeData={selectedLotteryNodeData} lotteryId={selectedRoundId} />
        </div>
      ) : (
        <div>
          <StyledCardHeader>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex alignItems="center">
                <Heading color="white" mr="8px">
                  {t('views.lottery.components.allHistoryCard.round')}
                </Heading>
                <StyledInput
                  pattern="^[0-9]+$"
                  inputMode="numeric"
                  disabled={isLoading}
                  id="round-id"
                  name="round-id"
                  value={selectedRoundId}
                  scale="lg"
                  onChange={handleOnChange}
                />
                <Box margin="4px 26px" mt="8px">
                  {selectedLotteryNodeData?.endTime ? (
                    <Text color="white" fontSize="14px">
                      {t('views.lottery.components.allHistoryCard.draw')}{' '}
                      {getDrawnDate(selectedLotteryNodeData.endTime)}
                    </Text>
                  ) : (
                    <Skeleton width="185px" height="21px" />
                  )}
                </Box>
              </Flex>
              <Flex alignItems="center">
                <StyledIconButton
                  disabled={!selectedRoundIdAsInt || selectedRoundIdAsInt <= 1}
                  onClick={() => handleArrowButtonPress(selectedRoundIdAsInt - 1)}
                  variant="text"
                  scale="sm"
                  mr="4px"
                >
                  <ArrowBackIcon />
                </StyledIconButton>
                <StyledIconButton
                  disabled={selectedRoundIdAsInt >= latestRoundId}
                  onClick={() => handleArrowButtonPress(selectedRoundIdAsInt + 1)}
                  variant="text"
                  scale="sm"
                  mr="4px"
                >
                  <ArrowForwardIcon />
                </StyledIconButton>
              </Flex>
            </Flex>
          </StyledCardHeader>
          <PreviousRoundCardBody lotteryNodeData={selectedLotteryNodeData} lotteryId={selectedRoundId} />
          <PreviousRoundCardFooter lotteryNodeData={selectedLotteryNodeData} lotteryId={selectedRoundId} />
        </div>
      )}
    </StyledCard>
  )
}

export default AllHistoryCard
