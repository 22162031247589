import React, { useMemo, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import { BLOCKED_ADDRESSES } from './config/constants'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import App from './App'
import Providers from './Providers'
import './languages/i18n'
import { useAuth } from 'hooks/useAuth'
import { withWagmi } from 'hocs/with-wagmi'
import { withAuth } from 'hocs/with-auth'

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

const Blocklist = withWagmi(
  withAuth(({ children }: { children: ReactNode }) => {
    const { account } = useAuth()
    const blocked: boolean = useMemo(() => Boolean(account && BLOCKED_ADDRESSES.indexOf(account) !== -1), [account])
    if (blocked) {
      return <div>Blocked address</div>
    }
    return <>{children}</>
  }),
)

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <Updaters />
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
)
