import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import InputDataDecoder from 'ethereum-input-data-decoder'

import lotteryV2Abi from 'config/abi/lotteryV2.json'
import truncateHash from 'utils/truncateHash'
import useToast from 'hooks/useToast'
import { BackgroundWithTx } from 'components/Toast'
import { getLotteryV2Contract } from 'utils/contractHelpers'
import { useAuth } from './useAuth'

const { address: lotteryContractAddress } = getLotteryV2Contract()
const TIMEOUT = 10000
const SHOWED_NOTIFICATIONS = 'poollotto-notifications-showed'

export const useNotificatios = () => {
  const { toastInfo } = useToast()
  const { t } = useTranslation()
  const decoder = new InputDataDecoder(lotteryV2Abi)
  const { account } = useAuth()

  const fetchContractData = async (userAccount: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BSCSCAN_URL}/api?module=account&action=txlist&address=${lotteryContractAddress}&startblock=0&endblock=99999999&page=1&offset=10&sort=desc&apikey=${process.env.REACT_APP_BSCSCAN_API_KEY}`,
      )
      const { result } = await response.json()
      const showedTransactins: string[] = JSON.parse(localStorage.getItem(SHOWED_NOTIFICATIONS)) || []

      result.forEach((transaction) => {
        const inputData = decoder.decodeData(transaction.input)
        if (
          !showedTransactins.includes(transaction.hash) &&
          +new Date() / 1000 - transaction.timeStamp < 60 &&
          transaction.from.toLowerCase() !== userAccount?.toLowerCase()
        ) {
          if (inputData.method === 'buyTickets') {
            localStorage.setItem(SHOWED_NOTIFICATIONS, JSON.stringify([...showedTransactins, transaction.hash]))
            toastInfo(
              'Info',
              <BackgroundWithTx
                txHash={transaction.hash}
                toastType={inputData.inputs[1]?.length > 10 ? 'green' : 'normal'}
                icon={inputData.inputs[1]?.length > 10 ? '🎉' : '🤞🏼'}
              >
                <h3>{t('toastInfo.buyTickets.title')}</h3>
                <p style={{ marginTop: 5 }}>
                  {t('toastInfo.buyTickets.text', {
                    txHash: `${truncateHash(transaction.from)}`,
                    ticketCount: inputData.inputs[1]?.length,
                  })}
                </p>
                <p>{t('toastInfo.buyTickets.desc')}</p>
              </BackgroundWithTx>,
            )
          }

          if (inputData.method === 'claimTickets') {
            localStorage.setItem(SHOWED_NOTIFICATIONS, JSON.stringify([...showedTransactins, transaction.hash]))
            toastInfo(
              'Info',
              <BackgroundWithTx txHash={transaction.hash} toastType="gold" icon="🎉">
                <h3>{t('toastInfo.claimTickets.title')}</h3>
                <p style={{ marginTop: 5 }}>
                  {t('toastInfo.claimTickets.text', { txHash: `${truncateHash(transaction.from)}` })}
                </p>
                <p>{t('toastInfo.claimTickets.desc')}</p>
              </BackgroundWithTx>,
            )
          }
        }
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  useEffect(() => {
    const timeout = setInterval(() => {
      fetchContractData(account)
    }, TIMEOUT)

    return () => clearTimeout(timeout)
  }, [account])
}
