/* eslint-disable react/no-unescaped-entities */
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from '@pancakeswap/uikit'
import { getLotteryV2Address } from 'utils/addressHelpers'
import { Block, CLink, DecorateUnderline, HowToPlaySection, SectionTitle, SubTitle } from './howToPlay.styled'

export const HowToPlay: FC = () => {
  const { t } = useTranslation()
  const lotteryAddress = useMemo(() => getLotteryV2Address(), [])

  const getContractLink = () =>
    process?.env?.REACT_APP_CHAIN_ID === '56'
      ? `https://bscscan.com/address/${lotteryAddress}`
      : `https://testnet.bscscan.com/address/${lotteryAddress}`

  return (
    <HowToPlaySection>
      <SectionTitle>{t('views.onboarding.howToPlay.title')}</SectionTitle>
      <SubTitle>{t('views.onboarding.howToPlay.text1')}</SubTitle>
      <CLink href={getContractLink()} target="_blank">
        {t('views.onboarding.howToPlay.link')}
      </CLink>
      <DecorateUnderline />
      <p>&emsp;{t('views.onboarding.howToPlay.block1.text1')}</p>
      <p>&emsp;{t('views.onboarding.howToPlay.block1.text2')}</p>
      <p>{t('views.onboarding.howToPlay.block1.text3')}</p>
      <p>&emsp;{t('views.onboarding.howToPlay.block1.text4')}</p>
      <DecorateUnderline />
      <Block>
        <h3>{t('views.onboarding.howToPlay.block2.heading')}</h3>
        <p>{t('views.onboarding.howToPlay.block2.text1')}</p>
        <p>{t('views.onboarding.howToPlay.block2.text2')}</p>
      </Block>
      <DecorateUnderline />
      <h3>{t('views.onboarding.howToPlay.block3.heading')}</h3>
      <p>&emsp;{t('views.onboarding.howToPlay.block3.text1')}</p>
      <p>&emsp;{t('views.onboarding.howToPlay.block3.text2')}</p>
      <p>&emsp;{t('views.onboarding.howToPlay.block3.text3')}</p>
      <p>&emsp;{t('views.onboarding.howToPlay.block3.text4')}</p>
      <DecorateUnderline />
      <Block>
        <h3>{t('views.onboarding.howToPlay.block4.heading')}</h3>
        <p>{t('views.onboarding.howToPlay.block4.text1')}</p>
        <Flex justifyContent="center">
          <img src="/images/lottery/WinningCriteria.png" alt="" />
        </Flex>
        <p>{t('views.onboarding.howToPlay.block4.text2')}</p>
        <p>{t('views.onboarding.howToPlay.block4.text3')}</p>
        <p>{t('views.onboarding.howToPlay.block4.text4')}</p>
        <p>{t('views.onboarding.howToPlay.block4.text5')}</p>
        <p>{t('views.onboarding.howToPlay.block4.text6')}</p>
      </Block>
      <DecorateUnderline />
      <Block>
        <h3>{t('views.onboarding.howToPlay.block5.heading')}</h3>
        <p>{t('views.onboarding.howToPlay.block5.text1')}</p>
        <p>{t('views.onboarding.howToPlay.block5.text2')}</p>
        <p>{t('views.onboarding.howToPlay.block5.text3')}</p>
      </Block>
    </HowToPlaySection>
  )
}
