import React, { useState } from 'react'

import styled from 'styled-components'
import { CardHeader, Card, CardBody, Text, CardFooter, Flex, Heading, Skeleton, Box } from '@pancakeswap/uikit'
import { useTranslation } from 'react-i18next'
import { LotteryStatus } from 'config/constants/types'
import { useGetUserLotteriesGraphData, useLottery } from 'state/lottery/hooks'
import { fetchLottery } from 'state/lottery/helpers'
import { LotteryRound } from 'state/types'
import { ReactComponent as BackIcon } from 'assets/icons/Back.svg'
import ConnectWalletButton from 'components/ConnectWalletButton'
import FinishedRoundTable from './FinishedRoundTable'
import { BuyTicketsButton } from '../BuyTicketsButton'
import PreviousRoundCardBody from '../PreviousRoundCard/Body'
import { processLotteryResponse, getDrawnDate } from '../../helpers'
import PreviousRoundCardFooter from '../PreviousRoundCard/Footer'
import { useAuth } from 'hooks/useAuth'

interface YourHistoryCardProps {
  handleShowMoreClick: () => void
  numUserRoundsRequested: number
}

const StyledCard = styled(Card)`
  width: 100%;
  background: none !important;
  > div {
    background: initial;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    width: 756px;
  }
`

const StyledCardHeader = styled(CardHeader)`
  z-index: 2;
  background: #40455c;
  padding: 24px 70px;
`

const StyledCardBody = styled(CardBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 240px;
  background: #292d3f !important;
`

const StyledCardFooter = styled(CardFooter)`
  background: #292d3f;
  border-top: none !important;
`

const StyledBackButton = styled(BackIcon)`
  margin-right: 25px;
  margin-left: -40px;
  cursor: pointer;
`

const YourHistoryCard: React.FC<YourHistoryCardProps> = ({ handleShowMoreClick, numUserRoundsRequested }) => {
  const { t } = useTranslation()
  const { account } = useAuth()
  const [shouldShowRoundDetail, setShouldShowRoundDetail] = useState(false)
  const [selectedLotteryNodeData, setSelectedLotteryNodeData] = useState<LotteryRound>(null)
  const [selectedLotteryId, setSelectedLotteryId] = useState<string>(null)

  const {
    isTransitioning,
    currentRound: { status },
  } = useLottery()
  const userLotteryData = useGetUserLotteriesGraphData()
  const ticketBuyIsDisabled = status !== LotteryStatus.OPEN || isTransitioning

  const handleHistoryRowClick = async (lotteryId: string) => {
    setShouldShowRoundDetail(true)
    setSelectedLotteryId(lotteryId)
    const lotteryData = await fetchLottery(lotteryId)
    const processedLotteryData = processLotteryResponse(lotteryData)
    setSelectedLotteryNodeData(processedLotteryData)
  }

  const clearState = () => {
    setShouldShowRoundDetail(false)
    setSelectedLotteryNodeData(null)
    setSelectedLotteryId(null)
  }

  const getHeader = () => {
    if (shouldShowRoundDetail) {
      return (
        <Flex alignItems="center">
          <StyledBackButton onClick={clearState} />
          <Flex flexDirection="column" alignItems="flex-start" justifyContent="center">
            <Heading color="#fff" scale="md" mb="4px">
              {t('views.lottery.components.yourHistoryCard.round')} {selectedLotteryId || ''}
            </Heading>
            {selectedLotteryNodeData?.endTime ? (
              <Text color="#fff" fontSize="14px">
                {t('views.lottery.components.yourHistoryCard.drawn')} {getDrawnDate(selectedLotteryNodeData.endTime)}
              </Text>
            ) : (
              <Skeleton width="185px" height="21px" />
            )}
          </Flex>
        </Flex>
      )
    }

    return (
      <Heading color="#fff" scale="md">
        {t('views.lottery.components.yourHistoryCard.rounds')}
      </Heading>
    )
  }

  const getBody = () => {
    if (shouldShowRoundDetail) {
      return <PreviousRoundCardBody lotteryNodeData={selectedLotteryNodeData} lotteryId={selectedLotteryId} />
    }

    const claimableRounds = userLotteryData?.rounds.filter(() => true)

    if (!account) {
      return (
        <StyledCardBody>
          <Text textAlign="center" color="#fff" mb="16px">
            {t('views.lottery.components.yourHistoryCard.bodyText.text1')}
          </Text>
          <ConnectWalletButton />
        </StyledCardBody>
      )
    }
    if (claimableRounds.length === 0) {
      return (
        <StyledCardBody>
          <Box maxWidth="280px">
            <Flex alignItems="center" justifyContent="center" mb="16px">
              <Text color="#fff" height="24px" mr="8px" />{' '}
              <Text color="#fff" textAlign="left">
                {t('views.lottery.components.yourHistoryCard.bodyText.text2')}
              </Text>
            </Flex>
            <Text color="#fff" textAlign="center" mb="16px">
              {t('views.lottery.components.yourHistoryCard.bodyText.text3')}
            </Text>
            <BuyTicketsButton disabled={ticketBuyIsDisabled} width="100%" />
          </Box>
        </StyledCardBody>
      )
    }
    return (
      <FinishedRoundTable
        handleHistoryRowClick={handleHistoryRowClick}
        handleShowMoreClick={handleShowMoreClick}
        numUserRoundsRequested={numUserRoundsRequested}
      />
    )
  }

  const getFooter = () => {
    if (selectedLotteryNodeData) {
      return <PreviousRoundCardFooter lotteryNodeData={selectedLotteryNodeData} lotteryId={selectedLotteryId} />
    }
    return (
      <StyledCardFooter>
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <Text fontSize="12px" color="#fff" />
        </Flex>
      </StyledCardFooter>
    )
  }

  return (
    <StyledCard>
      <StyledCardHeader>{getHeader()}</StyledCardHeader>
      {getBody()}
      {getFooter()}
    </StyledCard>
  )
}

export default YourHistoryCard
