import React from 'react'
import styled from 'styled-components'
import { Text } from '@pancakeswap/uikit'

export const BallText = styled(Text)<{ rotationTransform?: number }>`
  color: #292d3f;
`

const BallItem = styled.div.attrs(() => {})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`

const BallItemContent = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow:
    inset -1.32468px -1.32468px 5.2987px rgba(0, 0, 0, 0.4),
    inset 2.64935px 2.64935px 7.94805px rgba(255, 255, 255, 0.5);
`

interface BallWithNumberProps {
  number: string
  size?: string
  fontSize?: string
}

export const BallWithNumber: React.FC<BallWithNumberProps> = ({ number, size, fontSize }) => {
  return (
    <BallItem width={size} height={size}>
      <BallItemContent className={`balls-colorize_${number}`}>
        <BallText bold fontSize={fontSize ?? '16px'}>
          {number}
        </BallText>
      </BallItemContent>
    </BallItem>
  )
}
