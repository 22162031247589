import React from 'react'
import styled, { css } from 'styled-components'
import { Link, Text } from '@pancakeswap/uikit'
import { getBscScanLink } from 'utils'
import { useTranslation } from 'react-i18next'
import truncateHash from 'utils/truncateHash'
import { useAuth } from 'hooks/useAuth'

const getBackground = (toastType: 'normal' | 'green' | 'gold') => {
  if (toastType === 'gold') return '#B67122'
  if (toastType === 'green') return '#22400D'
  return '#292D3F'
}

const getSideBackground = (toastType: 'normal' | 'green' | 'gold') => {
  if (toastType === 'gold') return '#E3A257'
  if (toastType === 'green') return '#447821'
  return '#40455C'
}

const getBackgroundImage = (toastType: 'normal' | 'green' | 'gold') => {
  if (toastType === 'gold')
    return css`
      background-image: url('/images/notifications/flags.svg');
      background-repeat: no-repeat;
      background-position: calc(100% + 10px) -10px;
    `
  if (toastType === 'green')
    return css`
      background-image: url('/images/notifications/clover.svg');
      background-repeat: no-repeat;
      background-position: 0 -12px;
    `
  return ''
}

const StyledToast = styled.div<{ icon: string; toastType: 'normal' | 'green' | 'gold' }>`
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 420px;
  height: auto;
  background-color: ${({ toastType }) => getBackground(toastType)};
  border-radius: 16px;
  padding: 12px 60px;
  overflow: hidden;
  ${({ toastType }) => getBackgroundImage(toastType)}

  &::after {
    content: ${({ icon }) => `'${icon}'`};
    width: 48px;
    height: 100%;
    padding: 16px 8px 8px 16px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 24px;
    background: ${({ toastType }) => getSideBackground(toastType)};
  }

  p {
    color: #ffffff;
    margin: 7px 0;
  }

  h3 {
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
`

interface BackgroundWithTxProps {
  txHash?: string
  toastType: 'normal' | 'green' | 'gold'
  icon: string
}

export const BackgroundWithTx: React.FC<BackgroundWithTxProps> = ({ txHash, children, icon, toastType }) => {
  const { chainId } = useAuth()
  const { t } = useTranslation()

  return (
    <StyledToast icon={icon} toastType={toastType}>
      {typeof children === 'string' ? <Text as="p">{children}</Text> : children}
      {txHash && (
        <Link external href={getBscScanLink(txHash, 'transaction', chainId)}>
          {t('View on BscScan')}: {truncateHash(txHash, 8, 0)}
        </Link>
      )}
    </StyledToast>
  )
}
